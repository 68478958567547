import { Assignment, Edit } from '@mui/icons-material';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocationIcon from '@mui/icons-material/LocationOn';
import { Autocomplete, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import TextField from '@mui/material/TextField';
import {
	GridColDef,
	GridRenderCellParams,
	GridRowParams,
	GridToolbarContainer,
	GridTreeNodeWithRender
} from '@mui/x-data-grid';
import classNames from 'classnames';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { StyledDataGrid } from 'components/shared/DataGrid/StyledDataGrid';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useGlobalStore } from 'global-state/useStore';
import { SiteAlarmRetrieve } from 'interfaces/models/SiteAlarmRetrieve';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { useAlarms, useDeleteAlarm } from 'rq/hooks/alarmsHook';
import { useGetAllSites } from 'rq/hooks/sitesHook';
import { useStations } from 'rq/hooks/stationHook';
import { SiteModel } from 'rq/interfaces/Sites';
import { queryClient } from 'rq/queryClient';
import IconWithTooltip from '../../components/shared/Tooltip/IconWithTooltip';

const Alarms = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const locationState = location.state as
		| {
				site_id: number | undefined;
				station_id: number | undefined;
		  }
		| undefined;

	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const toggleCreateUpdateAlarmModal = useGlobalStore(
		(state) => state.toggleCreateUpdateAlarmModal
	);

	const { data: sites, isLoading: sitesLoading } = useGetAllSites();

	const { orgId } = useParams();
	const {
		data: alarms,
		isLoading: alarmsLoading,
		isFetching: alarmsFetching,
		isError: error
	} = useAlarms(orgId ?? '');

	const [selectedSite, setSelectedSite] = useState<SiteModel | null>(
		sites?.find((site) => site.site_id === Number(locationState?.site_id)) ?? null
	);

	const { data: stations, isLoading: stationsLoading } = useStations(
		Number(selectedSite?.site_id),
		{
			enabled: selectedSite !== null
			/*	onSuccess: (data) => {
					data.length === 1 && setSelectedStation(data[0]);
				}*/
		}
	);

	const { mutate: deleteAlarm } = useDeleteAlarm();

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 100,
		page: 0
	});

	const [selectedStation, setSelectedStation] = useState<any>(
		stations?.find((station) => station.station_id === Number(locationState?.station_id)) ?? null
	);

	const [showTriggeredAlarms, setShowTriggeredAlarms] = useState(false);

	const createAlarm = () => {
		toggleCreateUpdateAlarmModal({
			open: true,
			alarm: null,
			siteId: Number(selectedSite?.site_id),
			stationId: Number(locationState?.station_id ?? selectedStation?.station_id)
		});
	};

	const handleRemoveIconClick = (
		alarm: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
	) => {
		const alarmId = alarm.row.id;
		toggleConfirmationDialog({
			dialogOpen: true,
			headerTitle: '',
			message: t('ALARM_REMOVE_PROMPT'),
			confirmAction: () =>
				deleteAlarm(alarmId.toString(), {
					onSuccess: () => {
						queryClient.invalidateQueries(queryKeys.alarms);
						toast.success(t('ALARM_REMOVED'));
					}
				}),
			type: 'confirmation'
		});
	};

	const handleAlarmClick = (
		event:
			| GridRowParams<SiteAlarmRetrieve>
			| GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
	) => {
		const alarm = alarms?.find((alarm) => alarm.id === event.id);
		toggleCreateUpdateAlarmModal({
			open: true,
			alarm: alarm ?? null,
			siteId: Number(alarm?.site),
			stationId: Number(alarm?.station)
		});
	};

	useEffect(() => {
		if (stations?.length === 1) {
			setSelectedStation(stations[0]);
		} else
			setSelectedStation(
				stations?.find((station) => station.station_id === locationState?.station_id) ?? null
			);
	}, [stations, locationState?.station_id]);

	const CustomAlarmsToolbar = () => {
		return (
			<GridToolbarContainer>
				<div className={'flex flex-wrap gap-4 py-2'}>
					<FormControlLabel
						control={
							<Switch
								checked={showTriggeredAlarms}
								onChange={() => setShowTriggeredAlarms(!showTriggeredAlarms)}></Switch>
						}
						className={'ml-4'}
						label={t('SHOW_TRIGGERED_ALARMS')}
					/>
					<Autocomplete
						className={'w-[20rem] min-w-[20rem] max-w-[23rem]'}
						options={sites ?? []}
						size={'small'}
						getOptionLabel={(option) => option.name}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.site_id}>
									{option.name}
								</li>
							);
						}}
						value={selectedSite ?? null}
						onChange={(event: any, newValue: SiteModel | null) => {
							setSelectedSite(newValue);
						}}
						loading={sitesLoading}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('SELECT_SITE')}
								value={selectedSite?.name}
								InputProps={{ ...params.InputProps, startAdornment: <LocationIcon /> }}
							/>
						)}
					/>
					<div className={'flex flex-col'}>
						<Autocomplete
							className={'w-[20rem] min-w-[20rem] max-w-[23rem]'}
							options={stations ?? []}
							size={'small'}
							disabled={!selectedSite}
							getOptionLabel={(option) =>
								option.station_name && option.station_name !== ''
									? option.station_name
									: `${option.station_type.replaceAll('_', ' ')} (${option.station_id})`
							}
							renderOption={(props, option) => {
								return (
									<li {...props} key={option.station_id}>
										{option.station_name && option.station_name !== ''
											? option.station_name
											: `${option.station_type.replaceAll('_', ' ')} (${option.station_id})`}
									</li>
								);
							}}
							value={selectedStation ?? null}
							onChange={(event: any, newValue: SiteModel | null) => {
								setSelectedStation(newValue);
							}}
							loading={stationsLoading}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t('SELECT_STATION')}
									value={selectedStation?.station_name}
									InputProps={{ ...params.InputProps, startAdornment: <Assignment /> }}
								/>
							)}
						/>
						{selectedStation?.station_id && (
							<a
								className={' cursor-pointer text-lg font-bold text-secondary underline'}
								onClick={() =>
									navigate(`/${orgId}/sites/${selectedSite?.site_id}`, {
										state: {
											station_id: selectedStation?.station_id
										}
									})
								}>
								{t('VIEW_STATION')}
							</a>
						)}
					</div>
				</div>
			</GridToolbarContainer>
		);
	};

	const filterAlarmsData = () => {
		if (selectedSite?.site_id && !selectedStation?.station_id) {
			return (
				alarms?.filter(
					(alarm) =>
						(showTriggeredAlarms ? alarm.state === 'TRIGGERED' : true) &&
						alarm.site === selectedSite.site_id
				) ?? []
			);
		} else if (selectedSite?.site_id && selectedStation?.station_id) {
			return (
				alarms?.filter(
					(alarm) =>
						(showTriggeredAlarms ? alarm.state === 'TRIGGERED' : true) &&
						alarm.site === selectedSite.site_id &&
						alarm.station === selectedStation.station_id
				) ?? []
			);
		} else
			return (
				alarms?.filter((alarm) => (showTriggeredAlarms ? alarm.state === 'TRIGGERED' : true)) ?? []
			);
	};

	const columns: GridColDef[] = [
		{
			field: 'id'
		},
		{
			field: 'state',
			flex: 0.5,
			minWidth: 150,
			headerName: `${t('STATE')}`,
			filterable: false,
			renderCell: ({ value, row }) => {
				return (
					<div
						className={classNames(
							{ 'm-auto h-9 w-full max-w-[10rem] rounded-md py-2 text-center text-sm': true },
							{
								'bg-red-600 font-bold text-primary':
									value === 'TRIGGERED' && row.severity === 'HIGH'
							},
							{
								'bg-orange-500 font-bold text-primary':
									value === 'TRIGGERED' && row.severity === 'MEDIUM'
							},
							{
								'bg-yellow-500 font-bold text-primary':
									value === 'TRIGGERED' && row.severity === 'LOW'
							},
							{
								'bg-green-500 font-bold text-primary max-md:text-xs': value === 'NOT_TRIGGERED'
							}
						)}>
						{value.replace('_', ' ')}
					</div>
				);
			}
		},
		{
			field: 'severity',
			flex: 0.4,
			minWidth: 85,
			headerName: `${t('SEVERITY')}`,
			filterable: false
		},
		{
			field: 'site',
			headerName: `${t('SITE')}`,
			flex: 0.75,
			minWidth: 120,
			filterable: false,
			renderCell: (params) => {
				return <>{t(`${sites?.find((site) => site.site_id === params.row.site)?.name}`)}</>;
			}
		},
		{
			field: 'description',
			minWidth: 140,
			headerName: `${t('ALARM_DESCRIPTION')}`,
			flex: 1
		},
		{ field: 'email', headerName: `${t('EMAIL')}`, minWidth: 150, flex: 1.5 },
		{
			field: 'parameters',
			headerName: `${t('PARAMETERS')}`,
			minWidth: 150,
			flex: 1.5,
			valueGetter: (params) => {
				const variable =
					params.row.alarm_type === 'NO_MEAS_ARRIVING'
						? t('INTERVAL').toUpperCase()
						: params.row.parameters.variable || '';
				const value =
					params.row.alarm_type === 'NO_MEAS_ARRIVING'
						? params.row.parameters.interval
						: params.row.parameters.value;
				return `${variable}: ${value}`.trim();
			},
			sortComparator: (v1, v2, cellParams1, cellParams2) => {
				const parseValue = (valueString: string) => {
					const parts = valueString.split(': ');
					return {
						variable: parts[0],
						value: parts[1] ? parseFloat(parts[1]) : 0
					};
				};

				const value1 = parseValue(
					cellParams1.api.getCellValue(cellParams1.id, 'parameters').toString()
				);
				const value2 = parseValue(
					cellParams2.api.getCellValue(cellParams2.id, 'parameters').toString()
				);

				const variableCompare = value1.variable.localeCompare(value2.variable);
				if (variableCompare !== 0) {
					return variableCompare;
				}

				return value1.value - value2.value;
			},
			renderCell: (params) => params.api.getCellValue(params.id, 'parameters').toString()
		},
		{
			field: 'actions',
			headerName: `${t('ACTION')}`,
			filterable: false,
			minWidth: 150,
			disableColumnMenu: true,
			disableExport: true,
			sortable: false,
			editable: false,
			renderCell: (data) => {
				return (
					<div className={'flex flex-wrap '}>
						<PermissionView
							requiredPermissions={['manage_sites', 'manage_organization']}
							showFallbackComponent={false}>
							<>
								<div className={'mb-2 mr-2'} onClick={() => handleAlarmClick(data)}>
									<IconWithTooltip
										title={`${t('EDIT_ALARM')}`}
										icon={<Edit className={'text-secondary'} />}
									/>
								</div>
							</>
						</PermissionView>
						<PermissionView
							requiredPermissions={['manage_sites', 'manage_organization']}
							showFallbackComponent={false}>
							<>
								<div className={'mb-2 mr-2'} onClick={() => handleRemoveIconClick(data)}>
									<IconWithTooltip
										title={`${t('REMOVE_ALARM')}`}
										icon={<DeleteOutlineIcon className={'text-accent'} />}
									/>
								</div>
							</>
						</PermissionView>
					</div>
				);
			}
		}
	];

	if (error) {
		return (
			<div className="flex h-full flex-col items-center justify-center">
				<div className="max-h-[80%] max-w-[80%] overflow-y-auto">
					<div className="">{t('ERROR')}</div>
				</div>
			</div>
		);
	}

	return (
		<div className="flex w-full flex-col items-center justify-items-center ">
			<div className="flex w-full flex-row items-center justify-center px-2 py-2 text-lg md:px-4 md:py-2">
				<div className={'hidden md:flex-1'}></div>
				<div className="flex w-full flex-wrap justify-between md:text-center">
					<span>
						{`${t('ALARMS_FOR_ORGANIZATION')} ${
							alarms && !alarmsFetching && !alarmsLoading ? `(${alarms.length})` : ''
						}`}
					</span>

					<PrimaryButton onClick={createAlarm} className={'flex gap-2'}>
						<AlarmAddIcon className={'text-white'} />
						<span className={'text-white'}>{t('ADD_ALARM')}</span>
					</PrimaryButton>
				</div>
			</div>
			{alarms?.length === 0 ? (
				<>
					<div className="px-2 py-4 md:px-4">{t('NO_ALARMS_FOR_ORGANIZATION')}</div>
					<div className="flex flex-row px-2 py-4 pb-8 text-secondary md:px-4">
						<PrimaryButton onClick={createAlarm} className="flex gap-2 hover:opacity-70">
							<AlarmAddIcon className="my-1  text-primary" />
							<div className="flex h-full flex-col justify-center leading-none">
								{t('ADD_ALARM')}
							</div>
						</PrimaryButton>
					</div>
				</>
			) : (
				<div className={'flex w-full flex-col '}>
					<StyledDataGrid
						columns={columns}
						rows={filterAlarmsData()}
						rowHeight={60}
						className={'shadow-xl'}
						pagination
						paginationMode={'client'}
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						pageSizeOptions={[25, 50, 100]}
						initialState={{
							columns: {
								columnVisibilityModel: {
									id: false
								}
							},
							pagination: {
								paginationModel: paginationModel
							},
							sorting: {
								sortModel: [{ field: 'severity', sort: 'asc' }]
							}
						}}
						onRowDoubleClick={(e) => handleAlarmClick(e)}
						loading={alarmsLoading}
						disableColumnMenu
						hideFooterSelectedRowCount
						slots={{ loadingOverlay: LinearProgress, toolbar: CustomAlarmsToolbar }}
						autoHeight
					/>
				</div>
			)}
		</div>
	);
};

export default Alarms;
