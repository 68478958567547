import DeleteButton from 'components/shared/Button/DeleteButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { useGlobalStore } from 'global-state/useStore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import AuthService from 'services/AuthService/AuthService';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import DeleteAccountModal from 'components/Modal/Profile/DeleteAccountModal';
import ChangePasswordModal from 'components/Modal/Profile/ChangePasswordModal';

const ProfileAdvancedCard = ({
	applicationToken,
	setApplicationToken
}: {
	applicationToken: string | undefined;
	setApplicationToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
	const { t } = useTranslation();
	const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState<boolean>(false);
	const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);

	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);

	const deleteApplicationToken = () => {
		toggleConfirmationDialog({
			dialogOpen: true,
			message: t('APPLICATION_TOKEN_WARNING'),
			confirmAction: deleteAppTokenFnc,
			type: 'confirmation'
		});
	};

	const deleteAppTokenFnc = () => {
		AuthService.authApplicationTokenRevoke('' + applicationToken).then(() => {
			setApplicationToken(undefined);
		});
	};

	const createNewAppTokenFnc = () => {
		AuthService.authApplicationTokenCreate().then((response) => {
			setApplicationToken(response.application_token);
		});
	};

	const createApplicationToken = () => {
		if (applicationToken) {
			toggleConfirmationDialog({
				dialogOpen: true,
				message: t('APPLICATION_TOKEN_WARNING'),
				confirmAction: createNewAppTokenFnc,
				type: 'confirmation'
			});
		} else {
			AuthService.authApplicationTokenCreate().then((response) => {
				setApplicationToken(response.application_token);
			});
		}
	};

	const openDeleteAccountModal = () => {
		setDeleteAccountModalOpen(true);
	};

	const openChangePasswordModal = () => {
		setChangePasswordModalOpen(true);
	};

	return (
		<div className="mx-0 mb-8 md:mx-8">
			<div className="flex flex-col md:flex-row">
				<TextField
					className="mb-8 w-[500px] max-w-full md:mb-0 md:mr-8"
					id="first_name"
					value={applicationToken ?? ''}
					label={t('APPLICATION_TOKEN')}
				/>
				<div className="flex flex-row gap-2 self-center md:gap-4">
					<PrimaryButton className=" h-9 " text={t('GENERATE')} onClick={createApplicationToken} />
					<DeleteButton
						className="h-9"
						onClick={deleteApplicationToken}
						text={t('DELETE')}
						disabled={!applicationToken}
					/>
				</div>
			</div>
			<div className="mt-16 flex flex-row flex-wrap justify-evenly md:mt-32">
				<div
					onClick={openChangePasswordModal}
					className="mb-2 cursor-pointer rounded-md px-2 text-secondary ">
					<PrimaryButton text={t('CHANGE_PASSWORD')} className={'h-10 max-md:text-sm'}>
						<LockOpenIcon className="mr-1 self-center p-0 text-primary" />
					</PrimaryButton>
				</div>
				<DeleteButton
					onClick={openDeleteAccountModal}
					className={'mb-2 h-10 max-md:text-sm'}
					text={t('DELETE_ACCOUNT')}
				/>
			</div>
			{deleteAccountModalOpen && (
				<DeleteAccountModal
					open={deleteAccountModalOpen}
					setDeleteAccountModalOpen={setDeleteAccountModalOpen}
				/>
			)}
			{changePasswordModalOpen && (
				<ChangePasswordModal
					open={changePasswordModalOpen}
					setChangePasswordModalOpen={setChangePasswordModalOpen}
				/>
			)}
		</div>
	);
};

export default ProfileAdvancedCard;
