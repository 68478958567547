import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { useParams } from 'react-router-dom';
import React from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/AuthService/AuthService';

const ResetPasswordConfirm = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { uid, token } = useParams();
	const [resetResponse, setResetResponse] = React.useState<
		{ success: boolean; message: string } | undefined
	>();

	const validationSchema = Yup.object().shape({
		new_password1: Yup.string()
			.required(t('PASSWORD_REQUIRED'))
			.min(6, t('PASSWORD_MIN_LENGTH_WARNING'))
			.max(50, t('PASSWORD_MAX_LENGTH_WARNING')),
		new_password2: Yup.string()
			.oneOf([Yup.ref('new_password1'), null], t('PASSWORDS_DO_NOT_MATCH'))
			.required(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			new_password1: '',
			new_password2: ''
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			setResetResponse(undefined);
			AuthService.authPasswordResetConfirmCreate({
				...data,
				uid: '' + uid,
				token: '' + token
			})
				.then(() => {
					navigate('/login');
				})
				.catch((error) => {
					console.log(error);
					setResetResponse({ success: false, message: error.body.details });
				});
		}
	});

	return (
		<Grid
			className="flex w-full flex-col justify-center"
			item
			xs={12}
			sm={8}
			md={5}
			component={Paper}
			elevation={6}
			square>
			<Box
				sx={{
					my: 8,
					mx: 4,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}>
				<img
					src={'https://discharge.ch/images/discharge_logo_mobile.png'}
					className={'m-4 h-16 w-16'}
					alt={'Discharge logo'}
				/>
				<Typography component="h1" variant="h5">
					{t('CONFIRM_PASSWORD_RESET')}
				</Typography>
				<form className="mt-1 w-full" onSubmit={formik.handleSubmit} autoComplete="off">
					<TextField
						className="mb-0"
						margin="normal"
						type="password"
						disabled={resetResponse?.success}
						required
						fullWidth
						id="new_password1"
						label={t('NEW_PASSWORD')}
						name="new_password1"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						error={formik.touched.new_password1 && Boolean(formik.errors.new_password1)}
					/>
					{formik.errors.new_password1 && formik.touched.new_password1 ? (
						<div className="text-s text-red-600">{formik.errors.new_password1}</div>
					) : (
						<div className="h-6"></div>
					)}
					<TextField
						margin="normal"
						type="password"
						disabled={resetResponse?.success}
						required
						fullWidth
						onBlur={formik.handleBlur}
						id="new_password2"
						label={t('REPEAT_NEW_PASSWORD')}
						name="new_password2"
						onChange={formik.handleChange}
						error={formik.touched.new_password2 && Boolean(formik.errors.new_password2)}
					/>
					{formik.errors.new_password2 && formik.touched.new_password2 ? (
						<div className="text-s text-red-600">{formik.errors.new_password2}</div>
					) : (
						<div className="h-6"></div>
					)}

					{resetResponse?.success ? (
						<div className="h-9">{resetResponse.message}</div>
					) : (
						<>
							<div>{resetResponse?.message}</div>
							<div className="mt-4 flex flex-row-reverse">
								<PrimaryButton
									className="mr-4 h-9"
									disabled={!formik.isValid || !formik.dirty}
									text={t('RESET_PASSWORD')}
									type="submit"
								/>
							</div>
						</>
					)}
				</form>
			</Box>
		</Grid>
	);
};

export default ResetPasswordConfirm;
