import {
	Step,
	StepConnector,
	stepConnectorClasses,
	StepIconProps,
	StepLabel,
	Stepper,
	styled
} from '@mui/material';
import { useGlobalStore } from 'global-state/useStore';
import { PROCESSING_STEPS, STEPS } from 'pages/CloudProcessing/ProcessingSteps';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewMeasurementsButton from '../../SiteDetails/components/ViewMeasurementsButton';
import { handleViewMeasurementsClick } from '../../../helper/helperFunctions';
import ViewStationButton from '../../SiteDetails/components/ViewStationButton';

const CloudProcessingStepper = () => {
	const { t } = useTranslation();
	const currentStep = useGlobalStore((state) => state.currentStep);
	const displayStep = useGlobalStore((state) => state.displayStep.stepNumber); // Active step is displayStep
	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);
	const displacementProcessing = useGlobalStore((state) => state.displacementProcessing);
	const cloudProcessing = useGlobalStore((state) => state.cloudProcessing);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);

	const openMeasurementsInNewTab = () => {
		const url = `/${currentOrganization.id}/measurements/?site_id=${selectedSiteId}&station_id=${cloudProcessing.selectedStation?.station_id}`;
		window.open(url, '_blank', 'noopener,noreferrer');
	};
	const openStationInNewTab = () => {
		const url = `/${currentOrganization.id}/sites/${selectedSiteId}/?station_id=${cloudProcessing.selectedStation?.station_id}`;
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.vertical}`]: {
			marginLeft: 20
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundColor: 'rgb(220,96,46)' // Active connector color
			}
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundColor: 'rgb(6,123,94)' // Completed connector color (green)
			}
		},
		[`& .${stepConnectorClasses.line}`]: {
			width: 3,
			border: 0,
			backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
			borderRadius: 1
		}
	}));

	const ColorlibStepIconRoot = styled('div')<{
		ownerState: { completed?: boolean; active?: boolean; stepNumber?: number };
	}>(({ theme, ownerState }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 40,
		height: 40,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		// Highlight the active step (based on displayStep)
		...(ownerState.stepNumber === displayStep && {
			backgroundImage:
				'linear-gradient( 136deg, rgb(220,96,46) 0%, rgb(220,96,46) 50%, rgb(220,96,46) 100%)',

			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
		}),
		// Completed steps in green
		...(ownerState.completed && {
			backgroundImage:
				'linear-gradient( 136deg, rgb(6,123,94) 0%, rgb(6,123,94) 50%, rgb(6,123,94) 100%)'
		}),
		// Active steps to be highlighted differently if they're not completed
		...(ownerState.active && {
			backgroundImage:
				'linear-gradient( 136deg, rgb(220,96,46) 0%, rgb(220,96,46) 50%, rgb(220,96,46) 100%)',

			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
		})
	}));

	function ColorlibStepIcon(props: StepIconProps) {
		const { active, completed, className } = props;

		return (
			<ColorlibStepIconRoot
				ownerState={{ completed, active, stepNumber: Number(props.icon) }}
				className={className}>
				{String(props.icon)}
			</ColorlibStepIconRoot>
		);
	}

	return (
		<div className={'flex h-full flex-col gap-6'}>
			<Stepper
				activeStep={displayStep} // Make sure displayStep is the active step
				orientation={'vertical'}
				connector={<ColorlibConnector />}>
				{PROCESSING_STEPS.map((processingStep) => {
					if (displacementProcessing && !processingStep.displacementStep) return null;
					return (
						<Step
							key={processingStep.stepNumber}
							className={'cursor-pointer'}
							disabled={
								(cloudProcessing.configManuallyUploaded &&
									processingStep.name === STEPS.CAMERA_CALIBRATION) ||
								(cloudProcessing.configManuallyUploaded &&
									processingStep.name === STEPS.WATER_LEVEL)
							}
							onClick={
								processingStep.stepNumber <= currentStep.stepNumber
									? setDisplayStep.bind(this, processingStep)
									: undefined
							}
							completed={currentStep.stepNumber > processingStep.stepNumber}>
							<StepLabel StepIconComponent={ColorlibStepIcon}>{t(processingStep.name)}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			{cloudProcessing.selectedStation && (
				<>
					<ViewMeasurementsButton
						handleClick={() =>
							handleViewMeasurementsClick(
								openMeasurementsInNewTab,
								currentOrganization.id,
								Number(selectedSiteId),
								Number(cloudProcessing.selectedStation?.station_id)
							)
						}
					/>
					<ViewStationButton
						handleClick={() =>
							handleViewMeasurementsClick(
								openStationInNewTab,
								currentOrganization.id,
								Number(selectedSiteId),
								Number(cloudProcessing.selectedStation?.station_id)
							)
						}
					/>
				</>
			)}
		</div>
	);
};

export default CloudProcessingStepper;
