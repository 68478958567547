import { useGlobalStore } from 'global-state/useStore';
import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';
import React from 'react';
import DomainIcon from '@mui/icons-material/Domain';
import Avatar from '@mui/material/Avatar';
import CheckBox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

const ProfileOrganizationCard = ({ user }: { user: UserListRetrieve | undefined }) => {
	const { t } = useTranslation();
	const currentOrgId = useGlobalStore((state) => state.currentOrganization.id);
	const currentOrg = user?.organizations?.find(
		(organization) => organization.id === Number(currentOrgId)
	);
	const currentPermissions = currentOrg?.permissions ?? {};
	return (
		<div className="flex flex-col items-center">
			<div className="flex flex-row">
				<Avatar src={currentOrg?.logo} alt="logo">
					<DomainIcon />
				</Avatar>
				<div className="ml-4 flex flex-col justify-center">
					{currentOrg?.name + ' ' + t('PERMISSIONS').toLowerCase()}
				</div>
			</div>
			<div className="mt-4 ml-4 self-start">
				{Object.keys(currentPermissions).map((permission) => {
					if (permission === 'is_unit_member') return;
					// iz nekog razloga is unit member se ne prikazuje na organization tabu profile pagea + nema prijevoda
					type ObjectKey = keyof typeof currentPermissions;
					const myKey = `${permission}` as ObjectKey;
					return (
						<div className="mb-2 flex flex-row" key={permission}>
							<CheckBox className="mr-4 p-0 text-secondary" checked={currentPermissions[myKey]} />
							<div>{t(permission.toUpperCase())}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ProfileOrganizationCard;
