import CheckBox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import CancelButton from 'components/shared/Button/CancelButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import i18n from 'res/localization/i18n';
import { queryKeys } from 'rq/constants';
import { useAuth } from 'rq/hooks/authHook';
import { useUpdateUser } from 'rq/hooks/userHook';
import { queryClient } from 'rq/queryClient';
import * as Yup from 'yup';
import timezone_display = UserListRetrieve.timezone_display;

const ProfileInfoCard = ({ user }: { user: UserListRetrieve | undefined }) => {
	const { t } = useTranslation();

	const setUserInfo = useGlobalStore((state) => state.setUserInfo);

	const { mutate: updateUser } = useUpdateUser();
	const { data: me } = useAuth();

	const validationSchema = Yup.object().shape({
		email: Yup.string().email(t('INVALID_EMAIL')).nullable()
	});
	const formik = useFormik({
		initialValues: {
			first_name: user?.first_name ?? '',
			last_name: user?.last_name ?? '',
			email: user?.email ?? '',
			timezone_display: user?.timezone_display,
			display_language: user?.display_language,
			newsletter: user?.newsletter,
			accepted_tac: user?.accepted_tac,
			legal_age: user?.legal_age
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			updateUser(
				{
					userId: '' + user?.id,
					data: {
						...data,
						username: '' + user?.username
					}
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries([queryKeys.user, '' + user?.id]);
						queryClient.invalidateQueries(queryKeys.users);
						queryClient.invalidateQueries(queryKeys.me);
						data.display_language && localStorage.setItem('lng', data.display_language);
						i18n.changeLanguage(data.display_language);
						setUserInfo({
							timezone_display: formik.values.timezone_display ?? timezone_display.LOCAL
						});
						toast.success(t('USER_UPDATED'));
					}
				}
			);
		},
		enableReinitialize: true
	});

	const allowedToEditAndUpdate = user?.id === me?.id;

	const discardChanges = () => {
		formik.resetForm();
	};

	const checkboxTextClick = (type: string) => {
		type ObjectKey = keyof typeof formik.values;
		const myKey = `${type}` as ObjectKey;
		allowedToEditAndUpdate && formik.setFieldValue(type, !formik.values[myKey]);
	};

	return (
		<form className="md:pt-8" onSubmit={formik.handleSubmit} autoComplete="off">
			<div className="flex flex-col items-center md:px-8">
				<div className="flex max-w-full flex-col items-center md:mb-4 md:flex-row md:justify-evenly">
					<TextField
						className="mb-4 w-60 md:mr-8"
						id="first_name"
						value={formik.values.first_name}
						label={t('NAME')}
						name="first_name"
						onChange={formik.handleChange}
						error={formik.touched.first_name && Boolean(formik.errors.first_name)}
						disabled={!allowedToEditAndUpdate}
					/>
					<TextField
						className="mb-4 w-60 md:ml-8"
						id="last_name"
						value={formik.values.last_name}
						label={t('LAST_NAME')}
						variant="outlined"
						name="last_name"
						onChange={formik.handleChange}
						error={formik.touched.last_name && Boolean(formik.errors.last_name)}
						disabled={!allowedToEditAndUpdate}
					/>
				</div>
				<div className="flex max-w-full flex-col items-center md:mb-4 md:flex-row md:justify-evenly">
					<TextField
						className="mb-4 w-60 md:mr-8"
						id="email"
						label={t('EMAIL')}
						variant="outlined"
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						disabled={!allowedToEditAndUpdate}
					/>
					<FormControl className="mb-4 md:ml-8" disabled={!allowedToEditAndUpdate}>
						<InputLabel id="timezone_display-required-label">{t('TIMEZONE_DISPLAY')}</InputLabel>
						<Select
							className="w-60"
							labelId="timezone_display-required-label"
							id="timezone_display-required"
							label={t('TIMEZONE_DISPLAY')}
							name="timezone_display"
							value={formik.values.timezone_display}
							onChange={formik.handleChange}>
							<MenuItem value={'UTC'}>{t('UTC', 'UTC')}</MenuItem>
							<MenuItem value={'LOCAL'}>{t('LOCAL')}</MenuItem>
							<MenuItem value={'SITE'}>{t('SITE')}</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className="flex max-w-full flex-col items-center md:mb-4 md:flex-row md:justify-evenly">
					<FormControl className="mb-4" required disabled={!allowedToEditAndUpdate}>
						<InputLabel id="display_language-required-label">{t('DISPLAY_LANGUAGE')}</InputLabel>
						<Select
							className="w-60"
							labelId="display_language-required-label"
							id="display_language-required"
							label={t('DISPLAY_LANGUAGE')}
							name="display_language"
							value={formik.values.display_language}
							onChange={formik.handleChange}>
							<MenuItem value={UserListRetrieve.display_language.EN}>
								<div className="mr-2 inline-block">
									<img src={`https://flagcdn.com/w20/gb.png`} alt="gb-flag" />
								</div>
								{t('ENGLISH_LANGUAGE')}
							</MenuItem>
							<MenuItem value={UserListRetrieve.display_language.RU}>
								<div className="mr-2 inline-block">
									<img src={`https://flagcdn.com/w20/ru.png`} alt="ru-flag" />
								</div>
								{t('RUSSIAN_LANGUAGE')}
							</MenuItem>
							<MenuItem value={UserListRetrieve.display_language.FR}>
								<div className="mr-2 inline-block">
									<img src={`https://flagcdn.com/w20/fr.png`} alt="fr-flag" />
								</div>
								{t('FRENCH_LANGUAGE')}
							</MenuItem>
							<MenuItem value={UserListRetrieve.display_language.DE}>
								<div className="mr-2 inline-block">
									<img src={`https://flagcdn.com/w20/de.png`} alt="de-flag" />
								</div>
								{t('GERMAN_LANGUAGE')}
							</MenuItem>
							<MenuItem value={UserListRetrieve.display_language.AR}>
								<div className="mr-2 inline-block">
									<img src={`https://flagcdn.com/w20/ae.png`} alt="ae-flag" />
								</div>
								{t('ARABIC_LANGUAGE')}
							</MenuItem>
							<MenuItem value={UserListRetrieve.display_language.CH}>
								<div className="mr-2 inline-block">
									<img src={`https://flagcdn.com/w20/cn.png`} alt="cn-flag" />
								</div>
								{t('CHINESE_LANGUAGE')}
							</MenuItem>
							<MenuItem value={UserListRetrieve.display_language.ES}>
								<div className="mr-2 inline-block">
									<img src={`https://flagcdn.com/w20/es.png`} alt="es-flag" />
								</div>
								{t('SPANISH_LANGUAGE')}
							</MenuItem>{' '}
							<MenuItem value={UserListRetrieve.display_language.IT}>
								<div className="mr-2 inline-block">
									<img src={`https://flagcdn.com/w20/it.png`} alt="it-flag" />
								</div>
								{t('ITALIAN_LANGUAGE')}
							</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className="text-xs">
					<div className="mb-4 flex flex-row justify-start self-start">
						<div>
							<CheckBox
								className="mr-4 p-0 text-secondary"
								name="newsletter"
								checked={formik.values.newsletter}
								onChange={formik.handleChange}
								disabled={!allowedToEditAndUpdate}
							/>
						</div>
						<div
							className="cursor-pointer self-center"
							onClick={checkboxTextClick.bind(this, 'newsletter')}
							dangerouslySetInnerHTML={{ __html: t('NEWSLETTER_AGREEMENT') }}></div>
					</div>
					<div className="mb-4 flex flex-row justify-start self-start">
						<div>
							<CheckBox
								className="mr-4 p-0 text-secondary"
								name="accepted_tac"
								checked={formik.values.accepted_tac}
								onChange={formik.handleChange}
								disabled={!allowedToEditAndUpdate}
							/>
						</div>
						<div
							className="cursor-pointer self-center"
							onClick={checkboxTextClick.bind(this, 'accepted_tac')}
							dangerouslySetInnerHTML={{ __html: t('ALLOW_STORE_AND_PROCESS') }}></div>
					</div>
					<div className="mb-4 flex flex-row justify-start self-start">
						<div>
							<CheckBox
								className="mr-4 p-0 text-secondary"
								name="legal_age"
								checked={formik.values.legal_age}
								onChange={formik.handleChange}
								disabled={!allowedToEditAndUpdate}
							/>
						</div>
						<div
							className="cursor-pointer self-center"
							onClick={checkboxTextClick.bind(this, 'legal_age')}>
							<strong>{t('AGE_CONFIRMATION', { value: 16 })}</strong>
						</div>
					</div>
				</div>
			</div>
			<div className="my-4 flex h-9 flex-row justify-center gap-4">
				{formik.dirty && allowedToEditAndUpdate && (
					<>
						<CancelButton className="h-9" onClick={discardChanges} text={t('CANCEL')} />

						<PrimaryButton
							className="h-9"
							disabled={!formik.dirty}
							text={t('SAVE')}
							type="submit"
						/>
					</>
				)}
			</div>
		</form>
	);
};

export default ProfileInfoCard;
