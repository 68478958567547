import { GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid';
import React from 'react';

export const CustomColumnMenu = (props: GridColumnMenuProps) => {
	return (
		<GridColumnMenu
			{...props}
			slots={{
				// Hide `columnMenuColumnsItem`
				columnMenuSortItem: null,
				columnMenuFilterItem: null
			}}
		/>
	);
};
