import {
	AddCircleOutline,
	Assessment,
	CalendarMonth,
	Download,
	FilterList,
	Timelapse
} from '@mui/icons-material';
import LocationIcon from '@mui/icons-material/LocationOn';
import { Autocomplete, ClickAwayListener, LinearProgress } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { GridState, useGridApiRef } from '@mui/x-data-grid';
import classNames from 'classnames';
import CustomStationMeasurementsChart from 'components/Measurements/MeasurementCharts/CustomStationMeasurementsChart';
import RatingCurve from 'components/Measurements/RatingCurve/RatingCurve';
import FilterChips from 'components/Measurements/MeasurementsFilter/FilterChips';
import DateRangeModal from 'components/Modal/DateRangeModal/DateRangeModal';
import ExportMeasurementsModal from 'components/Modal/ExportMeasurements/ExportMeasurementsModal';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useGlobalStore } from 'global-state/useStore';
import { endOfDay, format, startOfDay, sub } from 'date-fns';
import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import { StationMeasurementsModel } from 'interfaces/models/StationMeasurementsModel';
import { getAvgMeasurementsGridColumns } from 'pages/MeasurementsPage/components/AvgMeasurementGridColumns';
import { getCustomStationGridColumns } from 'pages/MeasurementsPage/components/CustomStationColumns';
import { CustomToolbar } from 'pages/MeasurementsPage/components/CustomToolbar';
import { getGridColumns } from 'pages/MeasurementsPage/components/MeasurementsGridColumns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMeasurements, useMeasurementsPaginated } from 'rq/hooks/measurementsHook';
import { useGetAllSites } from 'rq/hooks/sitesHook';
import { useStations } from 'rq/hooks/stationHook';
import { SiteModel } from 'rq/interfaces/Sites';
import { generateMeasurementFilterQueryParams } from 'utils/functions';
import { chartTypes, measurementAggregationTypes } from 'utils/helperData';
import { StyledMeasurementGrid } from './components/StyledMeasurementsGrid';
import { CustomColumnMenu } from './components/CustomColumnMenu';
import { scrollToSelectedMeasurement } from './helper';
import MeasurementsChart from '../../components/Measurements/MeasurementCharts/MeasurementsChart';

const MeasurementsPage = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const toggleMeasurementsFilterModal = useGlobalStore(
		(state) => state.toggleMeasurementsFilterModal
	);
	const parsedFilters = useGlobalStore((state) => state.parsedFilters);
	const selectedMeasurementId = useGlobalStore((state) => state.selectedMeasurementId);
	const setSelectedMeasurementId = useGlobalStore((state) => state.setSelectedMeasurementId);
	const toggleMeasurementImageVideoModal = useGlobalStore(
		(state) => state.toggleMeasurementImageVideoModal
	);
	const exportMeasurementsModal = useGlobalStore((state) => state.exportMeasurementsModal);
	const toggleExportMeasurementsModal = useGlobalStore(
		(state) => state.toggleExportMeasurementsModal
	);
	const measurementsCalendar = useGlobalStore((state) => state.measurementsCalendar);
	const toggleMeasurementsCalendar = useGlobalStore((state) => state.toggleMeasurementsCalendar);
	const SIUnitsToggled = useGlobalStore((state) => state.SIUnitsToggled);
	const setRatingCurveData = useGlobalStore((state) => state.setRatingCurveData);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const toggleReprocessingMeasurement = useGlobalStore(
		(state) => state.toggleReprocessingMeasurement
	);

	const { date_from, date_to } = useGlobalStore((state) => state.measurementsCalendar);
	const { timezone_display } = useGlobalStore((state) => state.userInfo);

	const apiRef = useGridApiRef();
	const dailyAvgApiRef = useGridApiRef();
	const hourlyAvgApiRef = useGridApiRef();
	const gridRef = useRef<HTMLDivElement | null>(null);
	const dailyAggRef = useRef<HTMLDivElement | null>(null);
	const hourlyAggRef = useRef<HTMLDivElement | null>(null);

	const locationState = location.state as
		| {
				site_id: number;
				station_id: number;
		  }
		| undefined;

	const queryParams = new URLSearchParams(location.search);
	const site_id = locationState?.site_id ?? Number(queryParams.get('site_id'));
	const station_id = locationState?.station_id ?? Number(queryParams.get('station_id'));

	const { data: sites, isLoading: sitesLoading, isFetched: sitesFetched } = useGetAllSites();

	const [selectedSite, setSelectedSite] = useState<SiteModel | null>(
		sites?.find((site) => site.site_id === Number(site_id)) ?? null
	);

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0
	});

	const [aggregationPaginationModel, setAggregationPaginationModel] = useState({
		pageSize: 10,
		page: 0
	});

	const [aggregationType, setAggregationType] = useState(measurementAggregationTypes[0]);
	const [chartType, setChartType] = useState(chartTypes[0]);

	const handlePaginationChange = (e: any) => {
		setSelectedMeasurementId(undefined);
		setPaginationModel(e);
	};
	const handleAggPaginationChange = (e: any) => {
		setSelectedMeasurementId(undefined);
		setAggregationPaginationModel(e);
	};

	const reprocessMeasurementHandler = (meas_id: number) => {
		toggleReprocessingMeasurement({
			reprocessing: true,
			originalReprocessingData: null
		});
		navigate(
			`/${currentOrganization.id}/cloudprocessing/${selectedSite?.site_id}/${selectedStation?.station_id}/${meas_id}/`
		);
	};

	const resetPaginations = () => {
		setPaginationModel({
			pageSize: 10,
			page: 0
		});
		setAggregationPaginationModel({
			pageSize: 10,
			page: 0
		});
	};

	const calendarClickAway = () => {
		toggleMeasurementsCalendar({
			open: false,
			date_from: date_from,
			date_to: date_to,
			key: ''
		});
	};
	const lastScrolledMeasurementRef = useRef<string | undefined>(undefined);

	const {
		data: stations,
		isLoading: stationsLoading,
		isFetched: stationsFetched
	} = useStations(Number(selectedSite?.site_id), {
		enabled: !!selectedSite?.site_id
		/*	onSuccess: (data) => {
					data.length === 1 && setSelectedStation(data[0]);
				}*/
	});

	const [selectedStation, setSelectedStation] = useState<
		DischargeStationDetails | DischargeKeeperStationDetails | null
	>(stations?.find((station) => station.station_id === locationState?.station_id) ?? null);

	useEffect(() => {
		if (stations?.length === 1) {
			setSelectedStation(stations[0]);
		} else
			setSelectedStation(
				stations?.find((station) => station.station_id === locationState?.station_id) ?? null
			);
		resetPaginations();
		toggleMeasurementsCalendar({
			open: false,
			date_from: startOfDay(sub(new Date(), { days: 2 })),
			date_to: endOfDay(new Date()),
			key: ''
		});
	}, [stations, station_id, stationsFetched]);

	useEffect(() => {
		if (sitesFetched) setSelectedSite(sites?.find((site) => site.site_id === site_id) ?? null);
		resetPaginations();
		toggleMeasurementsCalendar({
			open: false,
			date_from: startOfDay(sub(new Date(), { days: 2 })),
			date_to: endOfDay(new Date()),
			key: ''
		});
	}, [sites, site_id, sitesFetched]);

	useEffect(() => {
		resetPaginations();
		toggleMeasurementsCalendar({
			open: false,
			date_from: startOfDay(sub(new Date(), { days: 2 })),
			date_to: endOfDay(new Date()),
			key: ''
		});
	}, []);

	useEffect(() => () => setSelectedMeasurementId(undefined), []);

	const {
		data: measurements,
		isLoading: chartDataLoading,
		isFetching: chartDataFetching,
		refetch: refetchMeasurements
	} = useMeasurements(
		{
			station_id: Number(selectedStation?.station_id),
			site_id: Number(selectedSite?.site_id),

			queryParams: {
				group: aggregationType === 'all_measurements' ? false : undefined,
				ordering: 'date_time',
				return_all: true,
				date_time__le: date_to?.toISOString(),
				date_time__ge: date_from?.toISOString(),
				averaging: aggregationType !== 'all_measurements' ? aggregationType : undefined,
				//date time query params are generated here because of calendarState, rest of params are extracted to this method
				...generateMeasurementFilterQueryParams(parsedFilters)
			}
		},
		{ enabled: !!selectedSite?.site_id && !!selectedStation?.station_id }
	);

	const {
		data: paginatedMeasurements,
		isFetching: paginatedMeasurementsFetching,
		refetch: refetchPaginatedMeasurements
	} = useMeasurementsPaginated(
		{
			station_id: Number(selectedStation?.station_id),
			site_id: Number(selectedSite?.site_id),
			queryParams: {
				date_time__le: date_to?.toISOString(),
				date_time__ge: date_from?.toISOString(),
				page: paginationModel.page + 1,
				page_size: paginationModel.pageSize,
				page_for_id: aggregationType === 'all_measurements' ? selectedMeasurementId : undefined,
				ordering: '-date_time',
				//date time query params are generated here because of calendarState, rest of params are extracted to this method
				...generateMeasurementFilterQueryParams(parsedFilters)
			}
		},
		{
			enabled: !!selectedSite?.site_id && !!selectedStation?.station_id,
			keepPreviousData: !!selectedSite?.site_id && !!selectedStation?.station_id
		}
	);

	const [aggregationData, setAggregationData] = useState(
		aggregationType !== 'all_measurements' &&
			measurements?.map((meas, index) => {
				return { id: index, ...meas };
			})
	);

	const getSelectedMeasurementPage = () => {
		const urlNext = paginatedMeasurements?.next && new URL(paginatedMeasurements?.next);
		const urlPrevious = paginatedMeasurements?.previous && new URL(paginatedMeasurements?.previous);

		// Use URLSearchParams to get the 'page' parameter
		const nextPageValue = urlNext && urlNext.searchParams.get('page');
		const previousPageValue = urlPrevious && urlPrevious.searchParams.get('page');
		selectedMeasurementId && apiRef.current.selectRow(selectedMeasurementId, true, true);

		selectedMeasurementId &&
			setPaginationModel({
				pageSize: paginationModel.pageSize,
				page: nextPageValue ? Number(nextPageValue) - 2 : Number(previousPageValue) //next page value -2 is there because in paginated query we add +1 to page as 0 is default in MUI pagination model
			});
	};

	const findSelectedSiteIdIndex = () => {
		const ref = aggregationType === 'daily' ? dailyAvgApiRef.current : hourlyAvgApiRef.current;

		if (ref) {
			const measIndex = ref
				?.getSortedRowIds()
				.findIndex((value) => value === selectedMeasurementId);
			if (measIndex !== -1) {
				ref.setPage(
					measIndex > 0
						? (measIndex / paginationModel.pageSize) % 1 < 0.5
							? Math.round(measIndex / paginationModel.pageSize)
							: Math.floor(measIndex / paginationModel.pageSize)
						: measIndex
				);

				selectedMeasurementId && ref.selectRow(selectedMeasurementId, true, true);
				setTimeout(() => {
					scrollToSelectedMeasurement(selectedMeasurementId, lastScrolledMeasurementRef);
				}, 100);
			}
		}
	};

	useEffect(() => {
		aggregationType === 'all_measurements' && getSelectedMeasurementPage();
		(aggregationType === 'hourly' || aggregationType === 'daily') &&
			!!selectedMeasurementId &&
			findSelectedSiteIdIndex();
	}, [paginatedMeasurements, selectedMeasurementId]);

	useEffect(() => {
		aggregationType !== 'all_measurements' &&
			setAggregationData(
				measurements?.map((meas, index) => {
					return { id: index, ...meas };
				})
			);

		(aggregationType === 'hourly' || aggregationType === 'daily') &&
			!!selectedMeasurementId &&
			findSelectedSiteIdIndex();
	}, [measurements]);

	const measCount = useMemo(() => paginatedMeasurements?.count, [paginatedMeasurements?.count]);

	const handleMeasurementsRefresh = () => {
		refetchMeasurements();
		refetchPaginatedMeasurements();
	};

	return (
		<div className={' flex flex-col flex-wrap gap-4'}>
			<div className={'flex max-w-full justify-between gap-4 max-md:flex-col'}>
				<div className={'flex flex-1 flex-wrap justify-start gap-4 max-md:flex-col'}>
					<div className={'flex flex-wrap gap-4'}>
						<Autocomplete
							className={'w-[20rem] min-w-[20rem] max-w-[23rem]'}
							options={sites ?? []}
							getOptionLabel={(option) => `${option.name} (${option.site_id})`}
							renderOption={(props, option) => {
								return (
									<li {...props} key={option.site_id}>
										{option.name} ({option.site_id})
									</li>
								);
							}}
							value={selectedSite ?? null}
							onChange={(event: any, newValue: SiteModel | null) => {
								setSelectedMeasurementId(undefined);
								setSelectedStation(null);
								setSelectedSite(newValue);
							}}
							loading={sitesLoading}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t('SELECT_SITE')}
									value={selectedSite?.name}
									InputProps={{ ...params.InputProps, startAdornment: <LocationIcon /> }}
								/>
							)}
						/>
						<div className={'flex flex-col'}>
							<Autocomplete
								className={'w-[20rem] max-w-[23rem]'}
								options={stations ?? []}
								getOptionLabel={(option) =>
									`${option.station_type.replaceAll('_', ' ')} (${option.station_id})`
								}
								value={selectedStation ?? null}
								onChange={(
									event: any,
									newValue: DischargeStationDetails | DischargeKeeperStationDetails | null
								) => {
									setSelectedMeasurementId(undefined);
									setSelectedStation(newValue);
									newValue?.station_type !== 'DISCHARGE' &&
										newValue?.station_type !== 'DISCHARGE_KEEPER' &&
										setChartType('measurements');
									newValue?.station_type === 'CUSTOM_STATION' &&
										setAggregationType(measurementAggregationTypes[0]);
								}}
								loading={stationsLoading}
								renderInput={(params) => (
									<TextField
										{...params}
										label={t('SELECT_STATION')}
										value={selectedStation?.station_name}
										InputProps={{ ...params.InputProps, startAdornment: <Assessment /> }}
									/>
								)}
							/>
							{selectedStation?.station_id && (
								<a
									className={' cursor-pointer text-lg font-bold text-secondary underline'}
									onClick={() =>
										navigate(`/${currentOrganization.id}/sites/${selectedSite?.site_id}`, {
											state: {
												station_id: selectedStation?.station_id
											}
										})
									}>
									{t('VIEW_STATION')}
								</a>
							)}
						</div>
					</div>
					{(selectedStation?.station_type === 'DISCHARGE' ||
						selectedStation?.station_type === 'DISCHARGE_KEEPER') && (
						<FormControl required>
							<InputLabel id="measurement-chart-required-label">{t('VIEW')}</InputLabel>
							<Select
								className={'w-60'}
								label={t('VIEW')}
								value={chartType}
								onChange={(e) => {
									setSelectedMeasurementId(undefined);
									resetPaginations();
									setChartType(e.target.value);
									selectedSite?.site_id &&
										selectedStation?.station_id &&
										setRatingCurveData({
											site_id: selectedSite.site_id,
											station_id: selectedStation.station_id,
											ratingCurves: []
										});
								}}>
								{chartTypes.map((type) => {
									return (
										<MenuItem key={`chart-type-${type}`} value={type.toLowerCase()}>
											<div className={'capitalize'}>{t(type).replaceAll('_', ' ')}</div>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					)}
				</div>

				<div className={'flex flex-wrap gap-4'}>
					{chartType !== 'rating_curve' && (
						<>
							<FormControl required>
								<InputLabel id="aggregate-data-label">{t('AGGREGATE_DATA')}</InputLabel>
								<Select
									className={'w-60'}
									label={t('AGGREGATE_DATA')}
									value={aggregationType}
									disabled={selectedStation?.station_type === 'CUSTOM_STATION'}
									onChange={(e) => {
										setSelectedMeasurementId(undefined);
										resetPaginations();
										setAggregationType(e.target.value);
									}}>
									{measurementAggregationTypes.map((type) => {
										return (
											<MenuItem key={`measurement-aggregation-${type}`} value={type.toLowerCase()}>
												<div className={'capitalize'}>{t(type).replaceAll('_', ' ')}</div>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<ClickAwayListener onClickAway={calendarClickAway}>
								<div>
									<TextField
										onClick={() =>
											toggleMeasurementsCalendar({
												open: true,
												date_from: date_from,
												date_to: date_to,
												key: ''
											})
										}
										autoComplete={'off'}
										className={'w-60 max-w-[23rem] '}
										disabled={!selectedStation || !selectedSite}
										label={t('TIME_PERIOD')}
										InputProps={{ startAdornment: <CalendarMonth className={'mr-1'} /> }}
										value={`${format(date_from ?? new Date(), 'yyyy-MM-dd')} - ${format(
											date_to ?? new Date(),
											'yyyy-MM-dd'
										)}`}></TextField>
									{measurementsCalendar.open && <DateRangeModal></DateRangeModal>}
								</div>
							</ClickAwayListener>
						</>
					)}
				</div>
			</div>

			<div className={'flex flex-col gap-4'}>
				{selectedStation?.station_id && selectedSite?.site_id && chartType === 'measurements' && (
					<>
						<div className={'flex gap-2'}>
							<FilterList /> {t('FILTERS')}
							<AddCircleOutline
								className={classNames(
									{ 'cursor-pointer': true },
									{ 'disabled cursor-default text-gray-500': !selectedStation || !selectedSite }
								)}
								onClick={() => selectedStation && selectedSite && toggleMeasurementsFilterModal()}
							/>
						</div>
						<div>
							<FilterChips />
						</div>
					</>
				)}
			</div>
			{selectedStation?.station_id && selectedSite?.site_id && chartType === 'rating_curve' && (
				<div className={'flex w-full flex-col flex-wrap gap-16'}>
					<div className={' h-96 min-h-96 w-full'}>
						<RatingCurve
							measurements={(measurements as StationMeasurementsModel[]) ?? []}
							siteId={selectedSite.site_id}
							stationId={selectedStation.station_id}
						/>
					</div>
				</div>
			)}
			{selectedStation?.station_id && selectedSite?.site_id && chartType === 'measurements' && (
				<>
					<div className={'flex w-full flex-col flex-wrap gap-16'}>
						<div className={' h-96 min-h-96 w-full'}>
							{chartDataLoading ? (
								<div
									className={'flex h-full flex-col items-center justify-center gap-2 align-middle'}>
									<CircularProgress />
									<div>{t('LOADING')}</div>
								</div>
							) : measurements?.length === 0 || !measurements ? (
								<div className={'flex h-full flex-col items-center justify-center align-middle'}>
									<div>{t('NO_MEASUREMENTS_FOR_FILTERS')}</div>
								</div>
							) : selectedStation.station_type === 'CUSTOM_STATION' ? (
								<CustomStationMeasurementsChart
									data={measurements}
									aggregationType={aggregationType}
									handleMeasurementsRefresh={handleMeasurementsRefresh}
								/>
							) : (
								<MeasurementsChart
									data={measurements}
									aggregationType={aggregationType}
									anyMeasData={[]}
									anyMeasToggled={false}
									handleMeasurementsRefresh={handleMeasurementsRefresh}
								/>
							)}
						</div>
						{measurements && measurements.length > 0 && (
							<div className={'w-full'}>
								<div className="w-full border border-solid border-[#bfbfbf] bg-white">
									{aggregationType === 'all_measurements' && paginatedMeasurements?.count !== 0 && (
										<>
											<div className={'w-full overflow-x-auto'}>
												<PermissionView
													requiredPermissions={'export_data'}
													showFallbackComponent={false}>
													<div
														className={
															'flex flex-row items-center gap-2 p-1 align-middle md:justify-end'
														}>
														<div>
															<PrimaryButton
																onClick={() =>
																	toggleExportMeasurementsModal({
																		open: true,
																		export_timelapse: true
																	})
																}
																className={'flex gap-2 bg-secondary normal-case'}
																disabled={selectedStation.station_type === 'CUSTOM_STATION'}>
																<Timelapse className={'text-white'} />
																<span className={'text-white'}>
																	{t('EXPORT') + ' ' + t('TIMELAPSE').toLowerCase()}
																</span>
															</PrimaryButton>
														</div>
														<div>
															<PrimaryButton
																onClick={() => toggleExportMeasurementsModal({ open: true })}
																className={'flex gap-2 bg-secondary normal-case'}
																disabled={selectedStation.station_type === 'CUSTOM_STATION'}>
																<Download className={'text-white'} />
																<span className={'text-white'}>{t('EXPORT_MEASUREMENTS')}</span>
															</PrimaryButton>
														</div>
													</div>
												</PermissionView>
												<StyledMeasurementGrid
													columns={
														selectedStation.station_type === 'CUSTOM_STATION'
															? getCustomStationGridColumns(
																	t,
																	timezone_display,
																	toggleMeasurementImageVideoModal
															  )
															: getGridColumns(
																	t,
																	SIUnitsToggled,
																	timezone_display,
																	toggleMeasurementImageVideoModal,
																	reprocessMeasurementHandler
															  )
													}
													apiRef={apiRef}
													ref={gridRef}
													getRowId={(row) => row.measurement_id}
													rows={paginatedMeasurements?.results ?? []}
													rowHeight={60}
													className={' shadow-xl'}
													rowCount={measCount ?? 0}
													getRowClassName={(params) => {
														return params.row.validation_status !== 'APPROVED' &&
															aggregationType === 'all_measurements'
															? 'text-red-500 '
															: '';
													}}
													onStateChange={(state: GridState) =>
														state.rowSelection.length > 0 &&
														scrollToSelectedMeasurement(
															selectedMeasurementId,
															lastScrolledMeasurementRef
														)
													}
													pagination
													paginationMode={'server'}
													paginationModel={paginationModel}
													onPaginationModelChange={handlePaginationChange}
													pageSizeOptions={[10, 25, 50, 100]}
													initialState={{
														columns: {
															columnVisibilityModel: {
																measurement_id: false,
																discharge_q1: false,
																discharge_q2: false,
																discharge_q3: false,
																camera_ok: false,
																displacement: false,
																username: false
															}
														},
														pagination: {
															paginationModel: paginationModel
														}
													}}
													loading={paginatedMeasurementsFetching}
													hideFooterSelectedRowCount
													slots={{
														loadingOverlay: LinearProgress,
														columnMenu: CustomColumnMenu,
														toolbar: CustomToolbar
													}}
													autoHeight
												/>
											</div>
										</>
									)}{' '}
									{aggregationType === 'daily' && aggregationData && (
										<StyledMeasurementGrid
											columns={getAvgMeasurementsGridColumns(
												t,
												aggregationType,
												timezone_display,
												SIUnitsToggled
											)}
											apiRef={dailyAvgApiRef}
											ref={dailyAggRef}
											rows={aggregationData ? aggregationData : []}
											rowHeight={60}
											className={' shadow-xl'}
											rowCount={measurements?.length}
											rowSelection={true}
											pagination
											paginationMode={'client'}
											paginationModel={aggregationPaginationModel}
											onPaginationModelChange={handleAggPaginationChange}
											onStateChange={(state: GridState) => {
												state.rowSelection.length > 0 &&
													scrollToSelectedMeasurement(
														selectedMeasurementId,
														lastScrolledMeasurementRef
													);
											}}
											pageSizeOptions={[10, 25, 50, 100]}
											initialState={{
												pagination: {
													paginationModel: aggregationPaginationModel
												},
												columns: {
													columnVisibilityModel: {
														measurement_id: false
													}
												}
											}}
											loading={chartDataFetching}
											disableColumnMenu
											hideFooterSelectedRowCount
											slots={{
												loadingOverlay: LinearProgress,
												columnMenu: CustomColumnMenu,
												toolbar: CustomToolbar
											}}
											autoHeight
										/>
									)}{' '}
									{aggregationType === 'hourly' && aggregationData && (
										<StyledMeasurementGrid
											columns={getAvgMeasurementsGridColumns(
												t,
												aggregationType,
												timezone_display,
												SIUnitsToggled
											)}
											apiRef={hourlyAvgApiRef}
											ref={hourlyAggRef}
											rows={aggregationData ? aggregationData : []}
											rowHeight={60}
											className={'shadow-xl'}
											rowCount={measurements?.length}
											rowSelection={true}
											pagination
											paginationMode={'client'}
											paginationModel={aggregationPaginationModel}
											onPaginationModelChange={handleAggPaginationChange}
											onStateChange={(state: GridState) => {
												state.rowSelection.length > 0 &&
													scrollToSelectedMeasurement(
														selectedMeasurementId,
														lastScrolledMeasurementRef
													);
											}}
											pageSizeOptions={[10, 25, 50, 100]}
											initialState={{
												pagination: {
													paginationModel: aggregationPaginationModel
												},
												columns: {
													columnVisibilityModel: {
														measurement_id: false
													}
												}
											}}
											loading={chartDataFetching}
											disableColumnMenu
											hideFooterSelectedRowCount
											slots={{
												loadingOverlay: LinearProgress,
												columnMenu: CustomColumnMenu,
												toolbar: CustomToolbar
											}}
											autoHeight
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</>
			)}
			{exportMeasurementsModal.open && date_from && date_to && (
				<ExportMeasurementsModal
					date_from={`${format(new Date(date_from), 'yyyy-MM-dd HH:mm')}`}
					date_to={`${format(new Date(date_to), 'yyyy-MM-dd HH:mm')}`}
					site_id={Number(selectedSite?.site_id)}
					station_id={Number(selectedStation?.station_id)}
				/>
			)}
		</div>
	);
};
export default MeasurementsPage;
