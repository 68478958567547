/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DischargeStationCurve = {
	id?: number;
	model: string;
	type: DischargeStationCurve.type;
	active?: boolean;
	parameters: Array<number>;
	isVisible?: boolean;
};

export namespace DischargeStationCurve {
	export enum type {
		QH_PAIRS = 'qh_pairs',
		QUADRATIC = 'quadratic',
		CUBIC = 'cubic',
		EXPONENTIAL = 'exponential'
	}
}
