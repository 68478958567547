import { useGlobalStore } from 'global-state/useStore';
import DischargeKeeperStationForm from 'pages/SiteDetails/components/StationTabs/components/DischargeKeeperStationForm';
import DischargeStationForm from 'pages/SiteDetails/components/StationTabs/components/DischargeStationForm';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ConfigFineTune = ({ showOriginalConfigData }: { showOriginalConfigData?: boolean }) => {
	const { t } = useTranslation();
	const selectedStation = useGlobalStore((state) => state.cloudProcessing.selectedStation);
	return (
		<div className={'flex h-max w-full flex-col gap-4 align-bottom text-black  '}>
			<div>
				<div className={'font-bold'}>
					{showOriginalConfigData ? t('CURRENT_CONFIGURATION') : t('STATION_CONFIG_FINE_TUNE')}
				</div>
				<div className="h-4 text-sm text-gray-500">
					{!showOriginalConfigData && t('EDITING_FINE_TUNE_OPTIONAL')}
				</div>
			</div>
			{selectedStation && selectedStation.station_type === 'DISCHARGE' && (
				<DischargeStationForm
					station_id={selectedStation.station_id}
					CPFastFlowForm={true}
					showOriginalConfigData={showOriginalConfigData}
				/>
			)}
			{selectedStation && selectedStation.station_type === 'DISCHARGE_KEEPER' && (
				<DischargeKeeperStationForm
					station_id={selectedStation.station_id}
					CPFastFlowForm={true}
					showOriginalConfigData={showOriginalConfigData}
				/>
			)}
		</div>
	);
};
export default ConfigFineTune;
