import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

export const StyledMeasurementGrid = styled(DataGrid)(() => ({
	'& .MuiDataGrid-cell:focus': {
		outline: 'none'
	},
	'& .MuiDataGrid-cell:hover': {
		cursor: 'pointer'
	},
	'&.MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
		outline: 'none'
	},
	'& .MuiDataGrid-columnHeader': {
		background: 'lightgray'
	},
	'& .MuiDataGrid-columnHeaders': {
		background: 'lightgray',
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'bold'
	}
}));
