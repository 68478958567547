import { format } from 'date-fns';
import { convertToUsersTimezone } from 'helper/helperFunctions';
import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { timezone_display } from 'rq/interfaces/User';

export const getAvgMeasurementsGridColumns = (
	t: (param: string) => string,
	aggregationType: string,
	timezone_display: string,
	SIUnitsToggled: boolean
): GridColDef[] => {
	return [
		{
			field: aggregationType === 'hourly' ? 'hour' : 'date',
			renderCell: ({ value }) => {
				return (
					<div>
						{value &&
							format(
								convertToUsersTimezone(new Date(value), {}, timezone_display as timezone_display),
								'yyyy-MM-dd HH:mm'
							)}
					</div>
				);
			},
			headerName: `${t('TIME')}`,
			flex: 1,
			minWidth: 150
		},
		{
			field: 'discharge_avg',
			headerName: `${t('DISCHARGE_AVG')} ${SIUnitsToggled ? '(m3/s)' : '(L/s)'}`,
			minWidth: 150,
			flex: 1.5,
			renderCell: ({ value }) => {
				if (value) return SIUnitsToggled && value ? value?.toFixed(2) : (value * 1000).toFixed(2);
				else return '';
			}
		},
		{
			field: 'level_avg',
			headerName: `${t('LEVEL_AVG')} ${SIUnitsToggled ? '(m)' : '(cm)'}`,
			minWidth: 150,
			flex: 1.5,
			renderCell: ({ value }) => {
				if (value) return SIUnitsToggled ? value.toFixed(2) : (value * 100).toFixed(2);
				else return '';
			}
		},
		{
			field: 'velocity_avg',
			headerName: `${t('VELOCITY_AVG')} ${SIUnitsToggled ? '(m/s)' : '(cm/s)'}`,
			minWidth: 150,
			flex: 1,
			renderCell: ({ value }) => {
				if (value) return SIUnitsToggled ? value.toFixed(2) : (value * 100).toFixed(2);
				else return '';
			}
		}
	];
};
