import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import CloudProcessingService from '../../services/CloudProcessingService/CloudProcessingService';
import {
	BaseCloudProcessingConfig,
	CalibrationData,
	ConfirmAndSaveResultsData,
	GetIsProcessingDoneData,
	GetIsProcessingDoneResponse,
	GetProcessingResultsData,
	GetWaterLineData,
	GetWaterLineFromWaterCol,
	ProcessingResultsData,
	ProcessVideoData,
	RotateImageData,
	StartDisplacementProcessingData,
	UploadVideoParams,
	WaterLineData,
	WaterLineDataFromWaterCol
} from '../../services/CloudProcessingService/types';

export const useUploadVideo = () => {
	return useMutation((data: UploadVideoParams) => CloudProcessingService.uploadVideo(data));
};

export const useRotateImg = () => {
	return useMutation((data: RotateImageData) => CloudProcessingService.rotateImage(data));
};

export const useCalibrate = () => {
	return useMutation((data: CalibrationData) => CloudProcessingService.calibrate(data));
};

export const useProcessVideo = () => {
	return useMutation((data: ProcessVideoData) => CloudProcessingService.processVideo(data));
};

export const useStartDisplacementProcessing = () => {
	return useMutation((data: StartDisplacementProcessingData) =>
		CloudProcessingService.startDisplacementProcessing(data)
	);
};

export const useConfirmAndSaveMeasurement = () => {
	return useMutation((data: ConfirmAndSaveResultsData) =>
		CloudProcessingService.confirmAndSaveResults(data)
	);
};

export const useBaseCloudProcessingConfig = (
	options?: Omit<
		UseQueryOptions<BaseCloudProcessingConfig, unknown, BaseCloudProcessingConfig, string[]>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(['base-cloud-processing-config'], () => CloudProcessingService.getBaseConfig(), {
		...options
	});
};

export const useWaterLineFromCoords = (
	data: GetWaterLineData,
	options?: Omit<
		UseQueryOptions<WaterLineData, unknown, WaterLineData, (string | number)[]>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		['waterline', data.processing_id, data.i_click, data.j_click],
		() => CloudProcessingService.getWaterLine(data),
		{
			keepPreviousData: false,
			...options
		}
	);
};

export const useWaterLineFromWCol = (
	data: GetWaterLineFromWaterCol,
	options?: Omit<
		UseQueryOptions<
			WaterLineDataFromWaterCol,
			unknown,
			WaterLineDataFromWaterCol,
			(string | number)[]
		>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		['waterline', data.processing_id, data.w_col],
		() => CloudProcessingService.getWaterLineFromWaterCol(data),
		{
			keepPreviousData: false,
			...options
		}
	);
};

export const useIsProcessingDone = (
	data: GetIsProcessingDoneData,
	options?: Omit<
		UseQueryOptions<GetIsProcessingDoneResponse, unknown, GetIsProcessingDoneResponse, string[]>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		['processing-done', data.processingId],
		() => CloudProcessingService.getIsProcessingDone(data),
		{
			...options
		}
	);
};

export const useCloudProcessingResult = (
	data: GetProcessingResultsData,
	options?: Omit<
		UseQueryOptions<ProcessingResultsData, unknown, ProcessingResultsData, string[]>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		['processing-result', data.processing_id],
		() => CloudProcessingService.getResults(data),
		{
			...options
		}
	);
};
