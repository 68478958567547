import { Handyman, InsertPhoto, OndemandVideo } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import classNames from 'classnames';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { format } from 'date-fns';
import { convertToUsersTimezone } from 'helper/helperFunctions';
import { StationMeasurementsModel } from 'interfaces/models/StationMeasurementsModel';
import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { timezone_display } from 'rq/interfaces/User';

export const getCustomStationGridColumns = (
	t: (param: string) => string,
	timezone_display: string,
	toggleMeasurementImageVideoModal: ({
		modalType,
		imageSrc,
		videoSrc,
		measurement
	}: {
		modalType: '' | 'video' | 'image';
		imageSrc: string;
		videoSrc: string;
		measurement?: StationMeasurementsModel | undefined;
	}) => void
): GridColDef[] => {
	return [
		{
			field: 'measurement_id',
			headerName: t('MEASUREMENT_ID')
		},
		{
			field: 'date_time',
			headerName: `${t('TIME')}`,
			flex: 1,
			minWidth: 150,
			renderCell: ({ value }) => {
				return (
					<div>
						{format(
							convertToUsersTimezone(
								value,
								{ timezone_offset: value.utc_offset },
								timezone_display as timezone_display
							),
							'yyyy-MM-dd HH:mm'
						)}
					</div>
				);
			}
		},
		{
			field: 'value_number',
			headerName: t('VALUE'),
			minWidth: 150,
			flex: 1
		},
		{
			field: 'value_text',
			headerName: t('TEXT'),
			minWidth: 150,
			flex: 1
		},
		{
			field: 'comment',
			headerName: t('COMMENT'),
			minWidth: 150,
			flex: 1
		},
		{
			hideable: true,
			field: 'username',
			headerName: `${t('CREATED_BY')}`,
			flex: 1,
			minWidth: 150
		},
		{
			field: 'actions',
			headerName: `${t('ACTION')}`,
			filterable: false,
			minWidth: 150,
			disableColumnMenu: true,
			disableExport: true,
			sortable: false,
			hideable: false,
			editable: false,
			renderCell: (data) => {
				return (
					<div className={'flex flex-wrap gap-2'}>
						<div
							onClick={() =>
								data.row.image !== '' &&
								toggleMeasurementImageVideoModal({
									modalType: 'image',
									imageSrc: data.row.image,
									videoSrc: '',
									measurement: data.row
								})
							}>
							<IconWithTooltip
								title={`${t('MEASUREMENT_PHOTO')}`}
								icon={
									<InsertPhoto
										className={classNames(
											{ 'text-secondary': data.row.image !== '' },
											{
												'cursor-auto text-gray-300 hover:opacity-100': data.row.image === ''
											}
										)}
									/>
								}
							/>
						</div>
						<div
							onClick={() =>
								data.row.video_url !== '' &&
								toggleMeasurementImageVideoModal({
									modalType: 'video',
									imageSrc: '',
									videoSrc: data.row.video_url
								})
							}>
							<IconWithTooltip
								title={`${t('MEASUREMENT_VIDEO')}`}
								icon={
									<OndemandVideo
										className={classNames(
											{ 'text-secondary': data.row.video_url !== '' },
											{
												'cursor-auto text-gray-300 hover:opacity-100': data.row.video_url === ''
											}
										)}
									/>
								}
							/>
						</div>
						<PermissionView
							requiredPermissions={'validate_measurements'}
							showFallbackComponent={false}>
							<>
								{data.row.validation_status !== 'APPROVED' && (
									<IconWithTooltip
										title={`${t('VALIDATE_MEASUREMENT')}`}
										icon={<AssignmentIcon className={'text-secondary'} />}
									/>
								)}
							</>
						</PermissionView>
						<>
							<IconWithTooltip
								title={`${t('DEBUG_DATA')}`}
								icon={
									<a href={data.row.debug_data_url} target={'_blank'} rel="noreferrer noopener">
										<Handyman
											className={classNames(
												{ 'text-secondary': data.row.debug_data_url !== '' },
												{
													'cursor-auto text-gray-300 hover:opacity-100':
														data.row.debug_data_url === ''
												}
											)}
										/>
									</a>
								}
							/>
						</>
					</div>
				);
			}
		}
	];
};
