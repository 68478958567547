import { MembershipPermissionCreateUpdate } from 'interfaces/models/MembershipPermissionCreateUpdate';

export const permissions: MembershipPermissionCreateUpdate = {
	create_measurements: true,
	view_users: false,
	manage_users: false,
	manage_organization: false,
	export_data: false,
	manage_sites: false,
	is_unit_member: true,
	validate_measurements: false
};
