import { useGlobalStore } from 'global-state/useStore';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IMapTooltipProps } from './types';

const MapTooltip: FunctionComponent<IMapTooltipProps> = ({ name, siteId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	return (
		<>
			<p className={' text-2xl font-bold'}>{name}</p>
			<div className={'flex flex-col gap-2'}>
				<a
					className={'cursor-pointer text-lg underline'}
					onClick={() => navigate(`/${currentOrganization.id}/sites/${siteId}`)}>
					{t('VIEW')} {t('SITE').toLowerCase()}
				</a>
				<a
					className={'cursor-pointer text-lg underline'}
					onClick={() =>
						navigate(`/${currentOrganization.id}/measurements/`, {
							state: { site_id: siteId }
						})
					}>
					{t('VIEW')} {t('MEASUREMENTS').toLowerCase()}
				</a>
				<a
					className={'cursor-pointer text-lg underline'}
					onClick={() =>
						navigate(`/${currentOrganization.id}/alarms/`, {
							state: { site_id: siteId }
						})
					}>
					{t('VIEW')} {t('ALARMS').toLowerCase()}
				</a>
			</div>
		</>
	);
};
export default MapTooltip;
