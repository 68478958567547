import CancelButton from 'components/shared/Button/CancelButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/AuthService/AuthService';

const ResetPassword = () => {
	const [resetResponse, setResetResponse] = React.useState<
		{ success: boolean; message: string | undefined } | undefined
	>();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isSeba } = useGlobalStore((state) => state.hostname);

	const validationSchema = Yup.object().shape({
		email: Yup.string().email(t('INVALID_EMAIL')).required(t('EMAIL_REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			email: ''
		},
		validationSchema: validationSchema,
		onSubmit: () => {
			setResetResponse(undefined);
			AuthService.authPasswordResetCreate({ email: formik.values.email })
				.then(() => {
					setResetResponse({ success: true, message: t('PASSWORD_RESET_LINK_SENT') });
				})
				.catch((error) => {
					console.log(error);
					setResetResponse({ success: false, message: error.body.details });
				});
		}
	});

	return (
		<Grid
			className="flex w-full flex-col justify-center"
			item
			xs={12}
			sm={8}
			md={5}
			component={Paper}
			elevation={6}
			square>
			<Box
				sx={{
					my: 8,
					mx: 4,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}>
				<img
					src={
						isSeba
							? '/assets/seba-icon.png'
							: 'https://discharge.ch/images/discharge_logo_mobile.png'
					}
					className={isSeba ? 'm-4 h-16 w-12' : 'm-4 h-16 w-16'}
					alt={isSeba ? 'Seba logo' : 'Discharge logo'}
				/>
				<Typography component="h1" variant="h5">
					{t('RESET_YOUR_PASSWORD')}
				</Typography>
				<form className="mt-1 w-full" onSubmit={formik.handleSubmit} autoComplete="off">
					<TextField
						margin="normal"
						disabled={resetResponse?.success}
						required
						fullWidth
						id="email"
						label={t('ENTER_EMAIL_FOR_PASSWORD_RESET_LINK')}
						name="email"
						autoComplete="email"
						autoFocus
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
					/>

					{resetResponse?.success ? (
						<div className="h-9">{resetResponse.message}</div>
					) : (
						<>
							<div>{resetResponse?.message}</div>
							<div className="mt-4 flex flex-row justify-around gap-4 text-secondary">
								<CancelButton className="h-9" text={t('CANCEL')} onClick={() => navigate('/')} />
								<PrimaryButton
									className="h-9"
									disabled={!formik.isValid || !formik.dirty}
									text={t('RESET_PASSWORD')}
									type="submit"
								/>
							</div>
						</>
					)}
				</form>
			</Box>
		</Grid>
	);
};

export default ResetPassword;
