/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { OrganizationListRetrieve } from './OrganizationListRetrieve';

export type UserListRetrieve = {
	readonly id?: number;
	/**
	 * Required. 30 characters or fewer. Letters, digits and ._- only.
	 */
	readonly username?: string;
	readonly email?: string;
	readonly first_name?: string;
	readonly last_name?: string;
	readonly super_admin?: boolean;
	readonly image?: string;
	readonly date_joined?: string;
	readonly timezone_display?: UserListRetrieve.timezone_display | null;
	readonly organizations?: Array<OrganizationListRetrieve>;
	readonly display_language?: UserListRetrieve.display_language;
	/**
	 * Designates whether this user has accepted to receive a newsletter.
	 */
	readonly newsletter?: boolean;
	/**
	 * Designates whether this user has accepted Discharge terms and conditions.
	 */
	readonly accepted_tac?: boolean;
	/**
	 * Designates whether this user has confirmed to have a legal age for using Discharge platform.
	 */
	readonly legal_age?: boolean;
};

export namespace UserListRetrieve {
	export enum timezone_display {
		SITE = 'SITE',
		LOCAL = 'LOCAL',
		UTC = 'UTC'
	}

	export enum display_language {
		EN = 'en',
		RU = 'ru',
		ES = 'es',
		FR = 'fr',
		DE = 'de',
		AR = 'ar',
		CH = 'ch',
		IT = 'it'
	}
}
