import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OrganizationAvatarModal from 'components/Modal/Organization/OrganizationAvatarModal';
import CancelButton from 'components/shared/Button/CancelButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { checkPermissions } from 'helper/helperFunctions';
import useCountries from 'hooks/useCountries';
import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';

import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import DomainIcon from '@mui/icons-material/Domain';
import CheckBox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import { queryKeys } from 'rq/constants';
import {
	useOrganizationDetails,
	useOrganizationPlan,
	useUpdateOrganization
} from 'rq/hooks/organizationsHook';
import { useGetUserDetails } from 'rq/hooks/userHook';
import { queryClient } from 'rq/queryClient';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const OrganizationPage = () => {
	const { t } = useTranslation();
	const countries = useCountries();
	const { orgId } = useParams();
	const organizationId = orgId ?? '';

	const { mutate: updateOrganization } = useUpdateOrganization();
	const {
		data: organization,
		isLoading: loading,
		isError: error
	} = useOrganizationDetails(localStorage.getItem('currentOrganizationId') as string);

	const { data: plan } = useOrganizationPlan(
		localStorage.getItem('currentOrganizationId') as string
	);

	const { data: owner, isLoading: ownerLoading } = useGetUserDetails(
		organization?.owner ? organization?.owner.toString() : '',
		{ enabled: !!organization?.owner }
	);

	const manageOrgPermission = useMemo(() => {
		return checkPermissions(['manage_organization']) ?? false;
	}, [organizationId]);

	const [avatarHover, setAvatarHover] = useState<boolean>(false);
	const [organizationAvatarModalOpen, setOrganizationAvatarModalOpen] = useState<boolean>(false);
	const openOrganizationAvatarModal = () => {
		manageOrgPermission && setOrganizationAvatarModalOpen(true);
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
		email: Yup.string().email('Invalid email').required('Required')
	});
	const formik = useFormik({
		initialValues: {
			name: organization?.name ?? '',
			email: organization?.email ?? '',
			phone: organization?.phone ?? '',
			country: organization?.country ?? '',
			postal_code: organization?.postal_code ?? '',
			city: organization?.city ?? '',
			address: organization?.address ?? '',
			sponsorship: organization?.sponsorship ?? false,
			is_public: organization?.is_public ?? false,
			validation: organization?.validation ?? false
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			updateOrganization(
				{ organizationId: organizationId, data: data },
				{
					onSuccess: () => {
						queryClient.invalidateQueries(queryKeys.organization);
						toast.success('ORGANIZATION_UPDATED');
						formik.resetForm();
					}
				}
			);
		},
		enableReinitialize: true
	});

	const discardChanges = () => {
		formik.resetForm();
	};

	const checkboxTextClick = (type: string) => {
		type ObjectKey = keyof typeof formik.values;
		const myKey = `${type}` as ObjectKey;
		manageOrgPermission && formik.setFieldValue(type, !formik.values[myKey]);
	};

	if (loading || ownerLoading) {
		return (
			<div className={'flex h-full w-full flex-col items-center justify-center text-secondary'}>
				<CircularProgress size={75} thickness={5} />
			</div>
		);
	}

	if (error)
		return (
			<div>
				Organization with {orgId} does not exist. Please check if you have proper permissions or the
				requested item has been deleted.
			</div>
		);

	return (
		<div className="flex  w-max flex-col  ">
			<div className="my-4 flex flex-col items-center justify-items-center  rounded-xl border border-black ">
				<div className="mb-4 flex w-full flex-col  md:flex-row md:items-center md:justify-around">
					<div className="flex w-full flex-row items-center justify-center gap-2 p-2 md:w-auto md:justify-evenly">
						<div
							onClick={openOrganizationAvatarModal}
							onMouseEnter={() => {
								setAvatarHover(true);
							}}
							onMouseLeave={() => {
								setAvatarHover(false);
							}}
							className="relative h-16 w-16  overflow-hidden rounded-full md:h-32 md:w-32">
							<Avatar
								className={
									(avatarHover && manageOrgPermission ? ' cursor-pointer opacity-40 ' : '') +
									'h-16 w-16 md:h-32 md:w-32'
								}
								alt="user"
								src={organization?.logo}>
								<DomainIcon className="h-10 w-10 md:h-20 md:w-20" />
							</Avatar>

							{avatarHover && manageOrgPermission && (
								<div
									className={
										'absolute left-1/2 top-3/4 h-[40%] w-full -translate-x-1/2 transform cursor-pointer bg-gray-200 text-center text-secondary'
									}>
									{t('CHANGE')}
								</div>
							)}
						</div>

						<div className="flex h-full flex-col items-center justify-evenly md:ml-8">
							<div>{organization?.name}</div>
							<div className="flex flex-row text-gray-400">
								<DomainIcon />
								<div>{t('ORGANIZATION')}</div>
							</div>
							<div className="text-sm font-bold">{plan?.plan?.code.toLowerCase()}</div>
						</div>
					</div>
				</div>
				<form className="w-full" onSubmit={formik.handleSubmit} autoComplete="off">
					<div className="flex w-full flex-col items-center gap-4 px-1 md:px-8">
						<div className="flex w-full flex-col gap-4 max-md:px-20 max-sm:px-4 md:flex-row md:items-center md:justify-evenly md:gap-6">
							<TextField
								className=" md:w-60"
								id="name"
								label={t('NAME')}
								value={formik.values.name}
								name="name"
								onChange={formik.handleChange}
								error={formik.touched.email && Boolean(formik.errors.email)}
								disabled={!manageOrgPermission}
								// helperText={formik.touched.email && formik.errors.email}
							/>
							<TextField
								className=" md:w-60"
								id="email"
								label={t('EMAIL')}
								variant="outlined"
								name="email"
								value={formik.values.email}
								onChange={formik.handleChange}
								error={formik.touched.email && Boolean(formik.errors.email)}
								disabled={!manageOrgPermission}
							/>
						</div>
						<div className="flex w-full flex-col gap-4 max-md:px-20 max-sm:px-4 md:flex-row md:items-center md:justify-evenly md:gap-6">
							<TextField
								className=" md:w-60"
								id="address"
								label={t('ADDRESS')}
								variant="outlined"
								name="address"
								value={formik.values.address}
								onChange={formik.handleChange}
								error={formik.touched.address && Boolean(formik.errors.address)}
								disabled={!manageOrgPermission}
							/>
							<FormControl className="md:w-60">
								<InputLabel>{t('COUNTRY')}</InputLabel>
								<Select
									className="md:w-60"
									value={formik.values.country ?? ''}
									name="country"
									label={t('COUNTRY')}
									onChange={formik.handleChange}>
									<MenuItem className="hidden" value={''}></MenuItem>
									{countries?.map((country) => {
										return (
											<MenuItem key={country.name} value={country.code}>
												<div className="inline-block pr-2">
													<img src={country.flag} alt={'flag'} />
												</div>
												{t(country.name)}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div className="flex w-full flex-col gap-4 max-md:px-20 max-sm:px-4 md:flex-row md:items-center md:justify-evenly md:gap-6">
							<TextField
								className="md:w-60"
								id="postal_code"
								label={t('POSTAL_CODE')}
								variant="outlined"
								name="postal_code"
								value={formik.values.postal_code}
								onChange={formik.handleChange}
								error={formik.touched.postal_code && Boolean(formik.errors.postal_code)}
								disabled={!manageOrgPermission}
							/>
							<TextField
								className="md:w-60"
								id="city"
								label={t('CITY')}
								variant="outlined"
								name="city"
								value={formik.values.city}
								onChange={formik.handleChange}
								error={formik.touched.city && Boolean(formik.errors.city)}
								disabled={!manageOrgPermission}
							/>
							<TextField
								className=" md:hidden"
								id="phone"
								label={t('PHONE')}
								variant="outlined"
								name="phone"
								value={formik.values.phone}
								onChange={formik.handleChange}
								error={formik.touched.phone && Boolean(formik.errors.phone)}
								disabled={!manageOrgPermission}
							/>
						</div>
						<TextField
							className="mb-4 max-md:hidden md:w-60"
							id="phone"
							label={t('PHONE')}
							variant="outlined"
							name="phone"
							value={formik.values.phone}
							onChange={formik.handleChange}
							error={formik.touched.phone && Boolean(formik.errors.phone)}
							disabled={!manageOrgPermission}
						/>

						{owner && (
							<div className={' flex w-full flex-col items-center justify-center gap-2 '}>
								<div className={'text-gray-400'}>{t('OWNER')}</div>
								<div className={'flex flex-row items-center gap-2'}>
									<Avatar src={owner?.image} className={'h-16 w-16'} />
									{owner?.first_name} {owner?.last_name}
								</div>
							</div>
						)}
						<div className={'w-full justify-self-start'}>
							<div className="flex flex-row justify-start self-start">
								<div>
									<CheckBox
										className="text-secondary"
										name="sponsorship"
										checked={formik.values.sponsorship}
										onChange={formik.handleChange}
										disabled={!manageOrgPermission}
									/>
								</div>
								<div
									className="cursor-pointer self-center"
									onClick={checkboxTextClick.bind(this, 'sponsorship')}>
									{t('ACCEPT_SPONSORSHIP')}
								</div>
							</div>
							<div className="flex flex-row justify-start self-start">
								<div>
									<CheckBox
										className="text-secondary"
										name="is_public"
										checked={formik.values.is_public}
										onChange={formik.handleChange}
										disabled={!manageOrgPermission}
									/>
								</div>
								<div
									className="cursor-pointer self-center"
									onClick={checkboxTextClick.bind(this, 'is_public')}>
									{t('PUBLIC')}
								</div>
							</div>
							<div className="flex flex-row justify-start self-start">
								<div>
									<CheckBox
										className="text-secondary"
										name="validation"
										checked={formik.values.validation}
										onChange={formik.handleChange}
										disabled={!manageOrgPermission}
									/>
								</div>
								<div
									className="cursor-pointer self-center"
									onClick={checkboxTextClick.bind(this, 'validation')}>
									{t('MANUALLY_VALIDATE_MEASUREMENTS')}
								</div>
							</div>
						</div>
					</div>
					<div className="my-4 flex h-9 flex-row justify-center gap-4">
						{formik.dirty && (
							<>
								<CancelButton onClick={discardChanges} text={t('CANCEL')} />
								<PrimaryButton
									className="h-9 bg-secondary text-primary"
									disabled={!formik.dirty || !manageOrgPermission}
									type="submit"
									text={t('SAVE')}
								/>
							</>
						)}
					</div>
				</form>
			</div>
			{organizationAvatarModalOpen && (
				<OrganizationAvatarModal
					open={organizationAvatarModalOpen}
					setOrganizationAvatarModalOpen={setOrganizationAvatarModalOpen}
				/>
			)}
		</div>
	);
};

export default OrganizationPage;
