import { Button } from '@mui/material';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PROCESSING_STEPS } from 'pages/CloudProcessing/ProcessingSteps';
import {
	useCloudProcessingResult,
	useConfirmAndSaveMeasurement
} from 'rq/hooks/cloudProcessingHook';
import { handleCanvasDrawing } from 'utils/functions';
import classNames from 'classnames';

const DisplacementResults = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const imageDivContainerRef = useRef<HTMLDivElement>(null);
	const imgRef = useRef<HTMLImageElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const displayStep = useGlobalStore((state) => state.displayStep);
	const currentStep = useGlobalStore((state) => state.currentStep);
	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);
	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const setCloudProcessingRotatedImage = useGlobalStore(
		(state) => state.setCloudProcessingRotatedImage
	);
	const cloudProcessing = useGlobalStore((state) => state.cloudProcessing);

	const { data: processingResults } = useCloudProcessingResult(
		{
			processing_id: `${cloudProcessing.video?.processing_id}`
		},
		{
			staleTime: 0,
			cacheTime: 0
		}
	);

	const { mutate: confirmAndSaveMeasurementRequest } = useConfirmAndSaveMeasurement();

	useEffect(() => {
		if (!processingResults || !canvasRef.current || !imgRef.current) {
			return;
		}
		handleCanvasDrawing(
			canvasRef,
			imgRef,
			processingResults,
			`${cloudProcessing.rotatedImage?.imgSrc}`
		);
	}, [processingResults]);

	const confirmAndSaveMeasurement = () => {
		confirmAndSaveMeasurementRequest(
			{
				processing_id: `${cloudProcessing.video?.processing_id}`
			},
			{
				onSuccess: () => {
					toast.success(t('MEASUREMENT_SAVED'));
					navigate('/');
				}
			}
		);
	};

	const discardMeasurement = () => {
		const UploadStep = PROCESSING_STEPS.find((step) => step.name === 'UPLOAD');
		setCurrentStep(UploadStep || currentStep);
		setDisplayStep(UploadStep || displayStep);
		setCloudProcessingRotatedImage({ imgSrc: '' });
	};

	return (
		<div className="mt-6 ml-6 text-lg text-secondary">
			<div className="flex flex-col">
				<div className="mb-4 ml-2">{t('MEASUREMENT_RESULTS')}</div>
				<div className="w-full">
					<div className="flex w-full max-w-[100%] flex-row flex-wrap overflow-x-auto overflow-y-hidden">
						<div
							className="relative inline-block w-auto overflow-x-auto"
							ref={imageDivContainerRef}>
							<img
								ref={imgRef}
								draggable={false}
								alt={t('VIDEO_FIRST_FRAME')}
								className={classNames(
									{ 'video_first_frame w-[40rem] max-w-[none] cursor-pointer select-none': true },
									{
										'w-[40rem]': cloudProcessing.rotatedImage?.imgOrientation === 'vertical'
									},
									{
										'w-[60rem] max-xl:w-[45rem]':
											cloudProcessing.rotatedImage?.imgOrientation === 'horizontal'
									}
								)} //max-h-[60rem] max-w-[70%]
								src={cloudProcessing.rotatedImage?.imgSrc}
							/>
							<canvas
								width={imgRef.current?.width}
								height={imgRef.current?.height}
								className="absolute top-0 left-0"
								ref={canvasRef}
							/>
						</div>
						{processingResults && (
							<div className="ml-4 flex flex-col text-xl text-black">
								{processingResults?.displacement && (
									<div className="mb-3 font-light">
										{t('DISPLACEMENT')}:{' '}
										<span className="font-bold">
											{processingResults.displacement !== null
												? processingResults.displacement.toFixed(3)
												: '-'}{' '}
											pix/s
										</span>
									</div>
								)}
								<Button
									onClick={confirmAndSaveMeasurement}
									className="normal-case"
									variant="contained"
									color="success"
									size="medium">
									{t('SAVE_MEASUREMENT')}
								</Button>
								<Button
									onClick={discardMeasurement}
									className="mt-2 normal-case"
									variant="contained"
									color="error"
									size="medium">
									{t('DISCARD')}
								</Button>{' '}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DisplacementResults;
