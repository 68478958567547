import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { MembershipListRetrieve } from 'interfaces/models/MembershipListRetrieve';
import { MembershipPermissionCreateUpdate } from 'interfaces/models/MembershipPermissionCreateUpdate';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';
import React, { useState, FunctionComponent } from 'react';

import Avatar from '@mui/material/Avatar';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SearchIcon from '@mui/icons-material/Search';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DomainIcon from '@mui/icons-material/Domain';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'rq/hooks/authHook';
import UsersService from '../../services/UsersService/UsersService';
import { GridColDef } from '@mui/x-data-grid';
import { LinearProgress, TextField } from '@mui/material';
import { checkPermissions } from 'helper/helperFunctions';
import InviteUsersModal from '../../components/Modal/InviteUsersModal';
import { useTranslation } from 'react-i18next';
import { permissions } from 'helper/helperData';
import IconWithTooltip from '../../components/shared/Tooltip/IconWithTooltip';
import PermissionView from '../../components/shared/PermissionsView/PermissionView';
import {
	useGetUsers,
	useRemoveUserFromOrganization,
	useUpdateUserMemberships
} from 'rq/hooks/userHook';
import useDebounce from '../../rq/hooks/useDebounce';
import { queryClient } from 'rq/queryClient';
import { queryKeys } from 'rq/constants';
import { useGlobalStore } from 'global-state/useStore';
import { StyledDataGrid } from 'components/shared/DataGrid/StyledDataGrid';

const UsersPage: FunctionComponent = () => {
	const { t } = useTranslation();

	const toggleInviteUsersModal = useGlobalStore((state) => state.toggleInviteUsersModal);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const { inviteUsersModalOpen } = useGlobalStore((state) => state.inviteUsersModal);

	const { data: user } = useAuth();
	const navigate = useNavigate();

	const currentOrganizationId = parseInt(
		window.localStorage.getItem('currentOrganizationId') ?? '0'
	);

	const currentUserId = user?.id;

	const [filter, setFilter] = useState('');

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0
	});

	const debouncedSearchQuery = useDebounce(filter);
	const {
		data: users,
		isLoading: usersLoading,
		isFetching: usersFetching
	} = useGetUsers({
		orgId: currentOrganizationId,
		search: debouncedSearchQuery,
		page: paginationModel.page + 1,
		pageSize: paginationModel.pageSize
	});
	const { mutate: removeUserFromOrganization } = useRemoveUserFromOrganization();
	const { mutate: updateUserPermissions } = useUpdateUserMemberships();

	const [manageUsersPermission] = useState(checkPermissions(['manage_users', 'view_users']));

	const filterHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(event.target.value);
		setPaginationModel({ pageSize: paginationModel.pageSize, page: 0 });
	};

	const toggleInviteUsersModalHandler = () => {
		if (!inviteUsersModalOpen) {
			toggleInviteUsersModal({
				inviteUsersModalOpen: true,
				selectedUser: null,
				userPermissions: permissions
			});
		} else if (inviteUsersModalOpen) {
			toggleInviteUsersModal({
				inviteUsersModalOpen: false,
				selectedUser: null,
				userPermissions: permissions
			});
		} else throw new Error('Not enough permissions');
	};

	//Check what to do with is_unit_member permission
	const handleUserMemberships = async (
		userId: string,
		action: 'delete' | 'update',
		dataForUpdate?: { organization: number; permissions: MembershipPermissionCreateUpdate }
	) => {
		return await UsersService.getUsersMembershipList(userId)
			.then((response) => {
				let membership: MembershipListRetrieve | undefined;
				if (localStorage.getItem('currentOrganizationId')) {
					membership = response.find((m) => {
						return m.organization === Number(localStorage.getItem('currentOrganizationId'));
					});
				}
				if (membership) {
					if (action === 'delete') {
						toggleConfirmationDialog({
							dialogOpen: true,
							headerTitle: '',
							message: t('REMOVE_USER_FROM_ORG_PROMPT'),
							confirmAction: () =>
								removeUserFromOrganization(
									{
										membershipId: String(membership?.affiliation_id),
										userId: userId
									},
									{
										onSuccess: () => queryClient.invalidateQueries(queryKeys.users)
									}
								),
							type: 'confirmation'
						});
					} else if (action === 'update' && dataForUpdate) {
						updateUserPermissions(
							{
								membershipId: String(membership.affiliation_id),
								userId: userId,
								data: dataForUpdate
							},
							{ onSuccess: () => queryClient.invalidateQueries(queryKeys.users) }
						);
					}
				}
				return response;
			})
			.catch((error) => {
				return error;
			});
	};

	const columns: GridColDef[] = [
		{
			field: 'image',
			headerName: `${t('AVATAR')}`,
			filterable: false,
			width: 65,
			renderCell: ({ value, row }) => {
				return (
					<Avatar
						src={value}
						className={'m-auto cursor-pointer bg-secondary'}
						onClick={() =>
							navigate(`/${currentOrganization.id}/users/${row.id}`, { state: { user: row } })
						}
					/>
				);
			}
		},
		{ field: 'username', headerName: `${t('USERNAME')}`, minWidth: 100, flex: 0.75 },
		{ field: 'first_name', headerName: `${t('FIRST_NAME')}` },
		{ field: 'last_name', headerName: `${t('LAST_NAME')}` },
		{
			field: 'full_name',
			headerName: `${t('FULL_NAME')}`,
			minWidth: 100,
			flex: 0.75,
			valueGetter: (params) => {
				return params.row.first_name + ' ' + params.row.last_name;
			}
		},
		{
			field: 'date_joined',
			headerName: `${t('MEMBER_SINCE')}`,
			flex: 0.55,
			type: 'date',
			valueFormatter: (params) => new Date(params.value as Date).toDateString(),
			minWidth: 100
		},
		{
			field: 'permissions',
			headerName: `${t('PERMISSIONS')}`,
			minWidth: 150,
			flex: 1,
			filterable: false,
			renderCell: (cell) => {
				const organization = cell.row.organizations?.find(
					(org: OrganizationListRetrieve) =>
						org.id === Number(localStorage.getItem('currentOrganizationId'))
				);
				const organizationPermissions = organization ? organization.permissions : null;
				const noPermissions = !Object.entries(organizationPermissions).some(
					(perm) => perm[1] === true && perm[0] !== 'is_unit_member'
				);

				const clickHandler = (e: any) => {
					e.stopPropagation();
					{
						manageUsersPermission &&
							toggleInviteUsersModal({
								inviteUsersModalOpen: true,
								selectedUser: cell.row,
								userPermissions: organizationPermissions
							});
					}
				};
				{
					return (
						<div>
							{organizationPermissions ? (
								<div className={'flex flex-row flex-wrap'}>
									{!noPermissions &&
										Object.entries(organizationPermissions).map((perm: any) => {
											if (perm[1] === true)
												switch (perm[0]) {
													case 'view_users':
														return (
															<div className={'mr-2 mb-2'} key={perm[0]}>
																<IconWithTooltip
																	title={`${t(perm[0].toUpperCase())}`}
																	icon={<GroupIcon onClick={(e) => clickHandler(e)} />}
																/>
															</div>
														);
													case 'export_data':
														return (
															<div className={'mr-2 mb-2'} key={perm[0]}>
																<IconWithTooltip
																	title={`${t(perm[0].toUpperCase())}`}
																	icon={<FileDownloadIcon onClick={(e) => clickHandler(e)} />}
																/>
															</div>
														);
													case 'manage_organization':
														return (
															<div className={'mr-2 mb-2'} key={perm[0]}>
																<IconWithTooltip
																	title={`${t(perm[0].toUpperCase())}`}
																	icon={<DomainIcon onClick={(e) => clickHandler(e)} />}
																/>
															</div>
														);
													case 'manage_users':
														return (
															<div className={'mr-2 mb-2'} key={perm[0]}>
																<IconWithTooltip
																	title={`${t(perm[0].toUpperCase())}`}
																	icon={<GroupAddIcon onClick={(e) => clickHandler(e)} />}
																/>
															</div>
														);
													case 'manage_sites':
														return (
															<div className={'mr-2 mb-2'} key={perm[0]}>
																<IconWithTooltip
																	title={`${t(perm[0].toUpperCase())}`}
																	icon={<EditLocationAltIcon onClick={(e) => clickHandler(e)} />}
																/>
															</div>
														);
													case 'create_measurements':
														return (
															<div className={'mr-2 mb-2'} key={perm[0]}>
																<IconWithTooltip
																	title={`${t(perm[0].toUpperCase())}`}
																	icon={<AssignmentIcon onClick={(e) => clickHandler(e)} />}
																/>
															</div>
														);
													case 'validate_measurements':
														return (
															<div className={'mr-2 mb-2'} key={perm[0]}>
																<IconWithTooltip
																	title={`${t(perm[0].toUpperCase())}`}
																	icon={<AssignmentTurnedInIcon onClick={(e) => clickHandler(e)} />}
																/>
															</div>
														);
												}
										})}
									{noPermissions && (
										<Link
											className="cursor-pointer"
											variant="body2"
											onClick={(e) => clickHandler(e)}>
											{t('NO_PERMISSIONS')}
										</Link>
									)}
								</div>
							) : (
								<div>{t('CANNOT_GET_PERMISSIONS_FOR_USER')}</div>
							)}
						</div>
					);
				}
			}
		},
		{
			field: 'actions',
			headerName: `${t('ACTION')}`,
			filterable: false,
			minWidth: 80,
			disableColumnMenu: true,
			disableExport: true,
			sortable: false,
			editable: false,
			renderCell: (cell) => {
				return (
					<PermissionView
						requiredPermissions={['manage_organization', 'manage_users', 'view_users']}
						showFallbackComponent={false}>
						<div
							className={`${cell.id !== currentUserId ? 'block' : 'hidden'}`}
							onClick={(e) => {
								e.stopPropagation();
								handleUserMemberships(cell.id as string, 'delete');
							}}>
							<div className={'mr-2 mb-2'}>
								<IconWithTooltip
									title={`${t('DELETE')}`}
									icon={
										<DeleteOutlineIcon className={'cursor-pointer text-accent hover:opacity-60'} />
									}
								/>
							</div>
						</div>
					</PermissionView>
				);
			}
		}
	];
	if (usersLoading) {
		return (
			<div className={'app flex h-full flex-col items-center justify-center text-secondary'}>
				<CircularProgress size={75} thickness={5} />
			</div>
		);
	}
	return (
		<PermissionView requiredPermissions={'view_users'} showFallbackComponent={true}>
			<div className=" flex flex-col items-center gap-2">
				<div className={'text-2xl'}>{t('USERS')}</div>
				<div className=" flex flex-row items-center justify-center ">
					<SearchIcon className="mt-4 mr-4" />
					<TextField
						id="standard-basic"
						label={t('FIND_USER')}
						value={filter}
						onChange={filterHandler}
						variant={'standard'}
					/>
				</div>

				<div className={' flex w-full flex-col gap-2'}>
					<PermissionView
						requiredPermissions={['manage_users', 'view_users']}
						showFallbackComponent={false}>
						<div className={' flex justify-end'}>
							<PrimaryButton onClick={toggleInviteUsersModalHandler} className={'flex gap-2'}>
								<PersonAddAltIcon className={'text-white'} />
								<span className={'text-white'}>{t('ADD_USERS')}</span>
							</PrimaryButton>
						</div>
					</PermissionView>
					<StyledDataGrid
						columns={columns}
						disableColumnMenu={true}
						rows={users?.results ?? []}
						hideFooterSelectedRowCount={true}
						rowHeight={60}
						className={'shadow-xl '}
						pagination
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						initialState={{
							columns: {
								columnVisibilityModel: {
									first_name: false,
									last_name: false
								}
							},
							pagination: {
								paginationModel: paginationModel
							}
						}}
						pageSizeOptions={[5, 10, 15, 20]}
						rowCount={users?.count}
						paginationMode="server"
						loading={usersFetching}
						slots={{
							loadingOverlay: LinearProgress
						}}
						autoHeight
						onRowClick={(e) => {
							navigate(`/${currentOrganization.id}/users/${e.row.id}`, { state: { user: e.row } });
						}}
					/>
				</div>

				{inviteUsersModalOpen && (
					<InviteUsersModal
						open={inviteUsersModalOpen}
						handleUpdate={handleUserMemberships}
						handleModalClose={toggleInviteUsersModalHandler}
					/>
				)}
			</div>
		</PermissionView>
	);
};

export default UsersPage;
