import MapTooltip from 'components/Map/MapTooltip';
import { BBox } from 'geojson';
import { useGlobalStore } from 'global-state/useStore';
import { checkAlarmSeverity, checkPermissions } from 'helper/helperFunctions';
import L, { Icon, PointExpression } from 'leaflet';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import { useParams } from 'react-router-dom';
import { useAlarms } from 'rq/hooks/alarmsHook';
import { useGetAllSites } from 'rq/hooks/sitesHook';
import useSupercluster from 'use-supercluster';

const fetchIcon = (count: number, size: number, siteAlarmSeverity: string) => {
	let icon;
	if (siteAlarmSeverity === '')
		icon = L.divIcon({
			html: `<div class="cluster-marker" style="width: ${size}px; height: ${size}px;">
        ${count}
      </div>`
		});
	else if (siteAlarmSeverity === 'HIGH') {
		icon = L.divIcon({
			html: `<div class="cluster-marker-alarm-high" style="width: ${size}px; height: ${size}px;">
				        ${count}
				      </div>`
		});
	} else if (siteAlarmSeverity === 'MEDIUM') {
		icon = L.divIcon({
			html: `<div class="cluster-marker-alarm-medium" style="width: ${size}px; height: ${size}px;">
				        ${count}
				      </div>`
		});
	} else if (siteAlarmSeverity === 'LOW') {
		icon = L.divIcon({
			html: `<div class="cluster-marker-alarm-low" style="width: ${size}px; height: ${size}px;">
				        ${count}
				      </div>`
		});
	}
	return icon;
};

const alarmHighMarker = new Icon({
	iconUrl: '/assets/alarm-high-marker.svg',
	iconSize: [40, 40] as PointExpression
});
const alarmMediumMarker = new Icon({
	iconUrl: '/assets/alarm-medium-marker.svg',
	iconSize: [40, 40] as PointExpression
});
const alarmLowMarker = new Icon({
	iconUrl: '/assets/alarm-low-marker.svg',
	iconSize: [40, 40] as PointExpression
});

const options = {
	radius: 125,
	maxZoom: 20
};

const MapContent = () => {
	const hasNeededPermissions = useMemo(() => {
		return checkPermissions(['manage_organization']);
	}, []);

	const { data: sites } = useGetAllSites();
	const { orgId } = useParams();
	const { data: alarms } = useAlarms(orgId ?? '', {
		enabled: hasNeededPermissions
	});

	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const setSelectedSiteId = useGlobalStore((state) => state.setSelectedSiteId);
	const mapClusterToggled = useGlobalStore((state) => state.mapClusterToggled);
	const renderIcon = (siteId: number) => {
		const markerIcon = new Icon({
			iconUrl: '/assets/Map_marker.svg',
			iconSize:
				selectedSiteId === siteId ? ([60, 60] as PointExpression) : ([40, 40] as PointExpression)
		});
		let alarmSeverity = '';

		if (alarms) {
			alarmSeverity = checkAlarmSeverity(alarms, siteId);
		}
		if (alarmSeverity === 'HIGH') markerIcon.options.iconUrl = alarmHighMarker.options.iconUrl;
		else if (alarmSeverity === 'MEDIUM')
			markerIcon.options.iconUrl = alarmMediumMarker.options.iconUrl;
		else if (alarmSeverity === 'LOW') markerIcon.options.iconUrl = alarmLowMarker.options.iconUrl;

		return markerIcon;
	};

	const getZIndexOffset = (siteId: number) => {
		let zIndexOffset = 0;
		const alarmSeverity = alarms ? checkAlarmSeverity(alarms, siteId) : '';

		if (alarmSeverity === 'HIGH') {
			zIndexOffset = 1000;
		} else if (alarmSeverity === 'MEDIUM') {
			zIndexOffset = 500;
		} else if (alarmSeverity === 'LOW') {
			zIndexOffset = 100;
		}

		return zIndexOffset;
	};

	const maxZoom = 25;
	const [bounds, setBounds] = useState<number[]>([]);
	const [zoom, setZoom] = useState(12);
	const map = useMap();

	const updateMap = () => {
		const b = map.getBounds();
		setBounds([
			b.getSouthWest().lng,
			b.getSouthWest().lat,
			b.getNorthEast().lng,
			b.getNorthEast().lat
		]);
		setZoom(map.getZoom());
	};

	const onMove = useCallback(() => {
		updateMap();
	}, [map]);

	useEffect(() => {
		updateMap();
	}, []);

	useEffect(() => {
		map.on('move', onMove);
		return () => {
			map.off('move', onMove);
		};
	}, [map, onMove]);

	const points = useMemo(() => {
		return sites?.map((site) => ({
			type: 'Feature',
			properties: { cluster: false, site: site, point_count: 0 },
			geometry: {
				type: 'Point',
				coordinates: [site.longitude, site.latitude]
			}
		}));
	}, [sites]);

	const { clusters, supercluster } = useSupercluster({
		// @ts-ignore
		points: points ?? [],
		bounds: bounds as BBox,
		zoom: zoom,
		options: options
	});

	return (
		<>
			{mapClusterToggled &&
				clusters.map((cluster) => {
					const [longitude, latitude] = cluster.geometry.coordinates;

					const { cluster: isCluster, point_count: pointCount } = cluster.properties;

					if (isCluster) {
						let clusterLeaves;
						if (cluster.id) {
							clusterLeaves = supercluster?.getLeaves(cluster.id as number, 1000);
						}
						let alarmSeverity = '';
						clusterLeaves?.forEach((leave: any) => {
							alarms?.forEach((alarm) => {
								if (alarm.site === leave.properties.site.site_id && alarm.state === 'TRIGGERED') {
									if (alarm.severity === 'HIGH') alarmSeverity = 'HIGH';
									else if (alarm.severity === 'MEDIUM' && alarmSeverity !== 'HIGH')
										alarmSeverity = 'MEDIUM';
									else if (
										alarm.severity === 'LOW' &&
										alarmSeverity !== 'HIGH' &&
										alarmSeverity !== 'MEDIUM'
									)
										alarmSeverity = 'LOW';
								}
							});
						});
						return (
							<Marker
								key={`cluster-${cluster.id}`}
								position={[latitude, longitude]}
								icon={
									points &&
									fetchIcon(pointCount, 10 + (pointCount / points?.length) * 40, alarmSeverity)
								}
								eventHandlers={{
									click: () => {
										if (supercluster) {
											const expansionZoom = Math.min(
												supercluster.getClusterExpansionZoom(cluster.id as number),
												maxZoom
											);
											map.setView([latitude, longitude], expansionZoom, {
												animate: true
											});
										}
									}
								}}
							/>
						);
					}

					// it is a single point
					return (
						<Marker
							key={`site-${cluster.properties.site.site_id}`}
							position={[latitude, longitude]}
							icon={renderIcon(cluster.properties.site.site_id)}
							eventHandlers={{ click: () => setSelectedSiteId(cluster.properties.site.site_id) }}>
							<Popup>
								<MapTooltip
									name={cluster.properties.site?.name}
									latitude={cluster.properties.site?.latitude}
									longitude={cluster.properties.site?.longitude}
									siteId={cluster.properties.site.site_id}
								/>
							</Popup>
						</Marker>
					);
				})}
			{!mapClusterToggled &&
				points?.map((point) => {
					return (
						<Marker
							key={`site-${point.properties.site.site_id}`}
							position={[point.properties.site.latitude, point.properties.site.longitude]}
							icon={renderIcon(point.properties.site.site_id)}
							zIndexOffset={getZIndexOffset(point.properties.site.site_id)}
							eventHandlers={{ click: () => setSelectedSiteId(point.properties.site.site_id) }}>
							<Popup>
								<MapTooltip
									name={point.properties.site?.name}
									latitude={point.properties.site?.latitude}
									longitude={point.properties.site?.longitude}
									siteId={point.properties.site?.site_id}
								/>
							</Popup>
						</Marker>
					);
				})}
		</>
	);
};
export default MapContent;
