import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import TextField from '@mui/material/TextField';
import { IExportMeasurementsModalProps } from 'components/Modal/ExportMeasurements/types';
import Modal from 'components/shared/Modal/Modal';
import { useGlobalStore } from 'global-state/useStore';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'rq/hooks/authHook';
import { IExportMeasurementsParams } from 'services/MeasurementsService/interface';
import { MeasurementsService } from 'services/MeasurementsService/MeasurementsService';
import { exportMeasurementsFields, exportMeasurementsTypes } from 'utils/helperData';
import { isEmail } from '../../../helper/helperFunctions';

const ExportMeasurementsModal: FunctionComponent<IExportMeasurementsModalProps> = ({
	date_from,
	date_to,
	site_id,
	station_id
}) => {
	const { open, export_timelapse } = useGlobalStore((state) => state.exportMeasurementsModal);
	const toggleExportMeasurementsModal = useGlobalStore(
		(state) => state.toggleExportMeasurementsModal
	);
	const { t } = useTranslation();

	const { data: user } = useAuth();

	const [exportType, setExportType] = useState(
		export_timelapse
			? exportMeasurementsTypes[exportMeasurementsTypes.findIndex((value) => value === 'mp4')]
			: exportMeasurementsTypes[0]
	);

	const [fieldsForExport] = useState(
		exportMeasurementsFields.reduce((accumulator: Record<string, boolean>, current) => {
			accumulator[current.name] = true;
			return accumulator;
		}, {})
	);
	const [exportMail, setExportMail] = useState(user?.email ?? '');

	const [successMessage, setSuccessMessage] = useState('');
	const [emailError, setEmailError] = useState('');

	const handleSubmit = () => {
		if (!isEmail(exportMail)) {
			setEmailError(t('INVALID_EMAIL'));
			return;
		}

		const fieldsIncluded = Object.keys(fieldsForExport)
			.filter((key) => fieldsForExport[key])
			.join(',');

		const submitData: IExportMeasurementsParams = {
			site_id: Number(site_id),
			station_id: station_id,
			queryParams: {
				export: exportType,
				email: exportMail,
				date_time__gt: date_from,
				date_time__lt: date_to,
				fields: fieldsIncluded,
				ordering: '-date_time'
			}
		};

		MeasurementsService.exportMeasurements(submitData).then((res) => {
			setSuccessMessage(res.data.message);
		});
	};

	return (
		<Modal
			open={open}
			modalTitle={`${t('EXPORT_MEASUREMENTS')} (${date_from} - ${date_to})`}
			closeModal={() => toggleExportMeasurementsModal({ open: false })}>
			<div className={'flex flex-col gap-6 p-4'}>
				{successMessage === '' ? (
					<>
						<div className={'flex flex-col gap-2'}>
							<div>
								<FormControl disabled={export_timelapse}>
									<FormLabel>{t('CHOOSE_FORMAT')}</FormLabel>
									<RadioGroup
										row
										name="row-radio-buttons-group"
										value={exportType}
										onChange={(e) => setExportType(e.target.value)}>
										{exportMeasurementsTypes.map((type) => {
											return (
												<FormControlLabel
													value={type}
													control={<Radio />}
													label={type === 'mp4' ? t('TIMELAPSE') : t(type).toUpperCase()}
													key={type}
												/>
											);
										})}
									</RadioGroup>
								</FormControl>
							</div>
						</div>

						{exportType === 'mp4' && <div>{t('EXPORT_TIMELAPSE_MESSAGE')}</div>}

						<div>
							<FormLabel>{t('EMAIL')}</FormLabel>
							<TextField
								variant={'standard'}
								className={'w-full'}
								error={!!emailError}
								helperText={emailError}
								value={exportMail}
								onChange={(e) => {
									setExportMail(e.target.value);
									if (!isEmail(e.target.value)) {
										setEmailError(t('INVALID_EMAIL'));
										return;
									} else setEmailError('');
								}}></TextField>
						</div>
						<div className={'self-center'}>
							<Button
								variant={'contained'}
								onClick={handleSubmit}
								className={'normal-case disabled:bg-gray-300 disabled:text-white'}
								disabled={!!emailError}>
								{t('EXPORT')}
							</Button>
						</div>
					</>
				) : (
					<div>{successMessage}</div>
				)}
			</div>
		</Modal>
	);
};
export default ExportMeasurementsModal;
