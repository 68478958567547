import { GridToolbarColumnsButton } from '@mui/x-data-grid';
import React from 'react';

export const CustomToolbar = () => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'flex-start',
				width: '100%',
				backgroundColor: 'lightgray'
			}}>
			<GridToolbarColumnsButton />
		</div>
	);
};
