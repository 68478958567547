import SearchIcon from '@mui/icons-material/Search';
import WavesIcon from '@mui/icons-material/Waves';
import { LinearProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { StyledDataGrid } from 'components/shared/DataGrid/StyledDataGrid';
import { ISidebarSiteListProps } from 'components/SidebarList/types';
import { useGlobalStore } from 'global-state/useStore';
import { convertToUsersTimezone } from 'helper/helperFunctions';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLastMeasurements } from 'rq/hooks/sitesHook';
import { humanReadableDateFormat } from 'utils/functions';

//Additional styling to StyledDataGrid for selecting rows
const StyledSidebarList = styled(StyledDataGrid)(() => ({
	'& .MuiDataGrid-row.Mui-selected,.MuiDataGrid-row.Mui-selected:hover, .MuiDataGrid-row:hover, .MuiDataGrid-row:focus-within':
		{
			backgroundColor: '#DC602E',
			color: '#F4F2F3'
		}
}));
const SidebarSitesList: FunctionComponent<ISidebarSiteListProps> = ({
	mapRef,
	handleSiteClick
}) => {
	const { t } = useTranslation();
	const { data: sites, isLoading: lastMeasSitesLoading } = useGetLastMeasurements({
		last_measurements: true,
		sub: false
	});
	const theme = useTheme();

	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const { timezone_display } = useGlobalStore((state) => state.userInfo);
	const apiRef = useGridApiRef();

	const [pageSize, setPageSize] = useState(0);

	const [filter, setFilter] = useState('');
	const [sitesState, setSitesState] = useState(sites);

	useEffect(() => {
		const filteredSites = sites?.filter((site) =>
			site.name.toLowerCase().includes(filter.toLowerCase())
		);
		setSitesState(filteredSites);
	}, [filter, sites]);

	const findSelectedSiteIdIndex = () => {
		const ref = apiRef.current;

		if (ref && ref.getSortedRowIds) {
			const siteIndex = ref.getSortedRowIds().findIndex((value) => value === selectedSiteId);
			const site = sites?.find((site) => site.site_id === selectedSiteId);

			if (siteIndex !== -1) {
				ref.setPage(
					siteIndex > 0
						? (siteIndex / pageSize) % 1 < 0.5
							? Math.round(siteIndex / pageSize)
							: Math.floor(siteIndex / pageSize)
						: siteIndex
				);
				site && ref.selectRow(site.site_id, true, true);
				setTimeout(() => {
					siteIndex > 5 && apiRef.current.scrollToIndexes({ rowIndex: siteIndex });
				}, 0);
			}
		}
	};

	useEffect(() => {
		!!selectedSiteId && findSelectedSiteIdIndex();
	}, [selectedSiteId, pageSize, apiRef.current]);

	const closeSidebarOnClick = useMediaQuery(theme.breakpoints.down(768));

	const columns: GridColDef[] = [
		{
			field: 'site_id'
		},
		{
			field: 'image',
			headerName: `${t('IMAGE')}`,
			flex: 0.5,
			minWidth: 70,
			width: 70,
			filterable: false,
			renderCell: ({ value }) => {
				return (
					<Avatar src={value} className={'m-auto bg-secondary'}>
						<WavesIcon />
					</Avatar>
				);
			}
		},
		{ field: 'name', headerName: `${t('NAME')}`, minWidth: 150, flex: 1.5 },
		{
			field: 'last_measurements',
			headerName: `${t('LAST_MEASUREMENT')}`,
			minWidth: 150,
			type: 'dateTime',
			flex: 1.5,
			valueGetter: (params) => {
				const latestMeasurement = params.value?.sort((a: any, b: any) =>
					a.date_time < b.date_time ? 1 : -1
				)[0]?.date_time;
				return latestMeasurement ? new Date(latestMeasurement) : null;
			},
			renderCell: (params) => {
				if (params.value)
					return (
						<span className={'font-bold'}>
							{humanReadableDateFormat({
								date: convertToUsersTimezone(params.value, {}, timezone_display),
								absoluteTime: false
							}).toLowerCase() + `${t('AGO').toLowerCase()}`}
						</span>
					);
				else return t('NO_MEASUREMENTS');
			}
		}
	];

	return (
		<>
			{lastMeasSitesLoading ? (
				<div className={'flex h-full w-full items-center justify-center align-middle'}>
					<CircularProgress />
				</div>
			) : (
				<>
					<div className="mb-8 mt-4 flex flex-row items-center justify-start">
						<SearchIcon className="mt-4 mr-4" />
						<TextField
							id="standard-basic"
							label={t('FIND_SITE_BY_NAME') + '...'}
							variant={'standard'}
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>
					</div>
					<StyledSidebarList
						columns={columns}
						apiRef={apiRef}
						rows={sitesState ?? []}
						getRowId={(row) => row.site_id}
						autoPageSize
						onPaginationModelChange={(model) => setPageSize(model.pageSize)}
						paginationMode="client"
						className={'overflow-scroll'}
						hideFooterSelectedRowCount
						initialState={{
							columns: {
								columnVisibilityModel: {
									site_id: false
								}
							},
							sorting: {
								sortModel: [{ field: 'last_measurements', sort: 'desc' }]
							}
						}}
						disableColumnMenu
						onRowClick={(row) => {
							handleSiteClick({ siteId: row.row.site_id, closeSidebar: closeSidebarOnClick });
							mapRef.current &&
								!isNaN(row.row.latitude) &&
								!isNaN(row.row.longitude) &&
								mapRef.current.flyTo([row.row.latitude, row.row.longitude], 21, {
									duration: 5
								});
						}}
						slots={{
							loadingOverlay: LinearProgress
						}}
					/>
				</>
			)}
		</>
	);
};
export default SidebarSitesList;
