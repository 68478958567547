import Chip from '@mui/material/Chip';
import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import { parseMeasurementFilters } from 'utils/functions';

const FilterChips = () => {
	const appliedMeasurementFilters = useGlobalStore((state) => state.appliedMeasurementFilters);
	const setAppliedMeasurementFilter = useGlobalStore((state) => state.setAppliedMeasurementFilter);
	const setSelectedMeasurementFilter = useGlobalStore(
		(state) => state.setSelectedMeasurementFilter
	);
	const setParsedFilters = useGlobalStore((state) => state.setParsedFilters);

	const handleRemoveFilterChip = (filterId: number) => {
		const filters = [...appliedMeasurementFilters];
		filters.splice(filterId, 1);
		filters.length === 0 &&
			filters.push({
				filterName: '',
				filterType: '',
				operator: { label: '', operator: '', value: '' },
				filterValue: ''
			});
		setParsedFilters(parseMeasurementFilters(filters));
		setAppliedMeasurementFilter(filters);
		setSelectedMeasurementFilter(filters);
	};

	return (
		<div className={'flex flex-wrap gap-2'}>
			{appliedMeasurementFilters.map((filter, index) => {
				if (filter.filterName !== '')
					return (
						<Chip
							label={`${filter.filterName !== 'username' ? filter.filterName : ''} ${
								filter.operator.operator
							} ${filter.filterValue}`}
							key={`filter-chip-${index}`}
							className={filter.filterType === 'string' ? 'normal-case' : 'capitalize'}
							onDelete={() => handleRemoveFilterChip(index)}
						/>
					);
			})}
		</div>
	);
};
export default FilterChips;
