import CancelButton from 'components/shared/Button/CancelButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import Modal from 'components/shared/Modal/Modal';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import Cropper from 'react-easy-crop';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { queryClient } from 'rq/queryClient';
import getCroppedImg from 'pages/ProfilePage/cropImage';
import UsersService from 'services/UsersService/UsersService';

const ProfileAvatarModal = ({
	open,
	setProfileAvatarModalOpen
}: {
	open: boolean;
	setProfileAvatarModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const { t } = useTranslation();
	const fileInput = useRef<HTMLInputElement | null>(null);
	const { userId } = useParams();
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [preview, setPreview] = useState<string | null>();
	const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
	const [zoom, setZoom] = useState<number>(1);
	const [aspect] = React.useState<number>(1);
	const [croppedImage, setCroppedImage] = useState<string>();
	const [fileBinary, setFileBinary] = useState<File>();
	const [uploading, setUploading] = useState<boolean>(false);

	const closeModal = () => {
		setProfileAvatarModalOpen(false);
	};

	const fileSelectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputFile = event.target as HTMLInputElement;
		if (inputFile) {
			if (!inputFile.files?.length) {
				return;
			}
			setSelectedFile(inputFile?.files[0]);
		}
	};

	useEffect(() => {
		if (selectedFile) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreview(reader.result as string);
			};
			reader.readAsDataURL(selectedFile);
		} else {
			setPreview(null);
		}
	}, [selectedFile]);

	const onCropChange = (crop: { x: number; y: number }) => {
		setCrop(crop);
	};

	const onCropComplete = async (
		croppedArea: { x: number; y: number; width: number; height: number },
		croppedAreaPixels: { width: number; height: number; x: number; y: number }
	) => {
		const { fileBinary, blob } = await getCroppedImg(preview as string, croppedAreaPixels);
		setCroppedImage(blob);
		setFileBinary(fileBinary);
	};

	const onZoomChange = (zoom: number) => {
		setZoom(zoom);
	};

	const uploadImage = () => {
		setUploading(true);
		UsersService.updateUsersImage('' + userId, { image: fileBinary })
			.then(() => {
				queryClient.invalidateQueries(queryKeys.me);
				queryClient.invalidateQueries([queryKeys.user, userId]);
				setUploading(false);
				closeModal();
			})
			.catch((error) => {
				console.log(error);
				setUploading(false);
			});
	};

	return (
		<Modal open={open} closeModal={closeModal} modalTitle={''}>
			<div className="flex flex-col p-6 lg:flex-row">
				<div className="flex flex-col lg:mr-16">
					<input
						style={{ display: 'none' }}
						type="file"
						onChange={fileSelectHandler}
						accept="image/*"
						ref={fileInput}
					/>
					<PrimaryButton
						className="self-center"
						onClick={() => {
							fileInput.current?.click();
						}}
						text={t('SELECT_A_PICTURE_FROM_YOUR_COMPUTER')}
					/>
					<div className="mt-4 flex h-64 w-64 flex-col items-center justify-center self-center bg-gray-200 sm:h-96 sm:w-96 md:mt-8 md:h-[500px] md:w-[500px]">
						{preview && (
							<Cropper
								classes={{
									containerClassName:
										'relative w-full h-full flex flex-col justify-center items-center',
									mediaClassName: `relative self-center`
								}}
								image={preview}
								crop={crop}
								zoom={zoom}
								zoomSpeed={0.3}
								aspect={aspect}
								restrictPosition={false}
								cropShape="round"
								showGrid={false}
								minZoom={0.5}
								onCropChange={onCropChange}
								onCropComplete={onCropComplete}
								onZoomChange={onZoomChange}
								objectFit="auto-cover"
							/>
						)}
						{preview && (
							<div className="w-full">
								<Slider
									value={zoom}
									min={0.5}
									max={3}
									step={0.1}
									aria-labelledby="Zoom"
									onChange={(e, zoom) => onZoomChange(zoom as number)}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-col items-center">
					<div className="mt-4 flex justify-center py-2 lg:mt-0 lg:mb-7">
						<strong>{t('PREVIEW')}:</strong>
					</div>
					<div className="flex h-[500px] w-[300px] flex-col items-center justify-evenly">
						<div className="h-64 w-64 overflow-hidden rounded-full bg-white">
							{croppedImage && (
								<img src={croppedImage} className="h-full w-full object-fill" alt="profile-image" />
							)}
						</div>
						<div className="mt-4 flex w-full flex-row items-center justify-around md:mt-8">
							<div className="h-32 w-32 overflow-hidden rounded-full bg-white">
								{croppedImage && (
									<img src={croppedImage} className="h-32 w-32 object-fill" alt="profile-image" />
								)}
							</div>
							<div className="flex h-full flex-col items-center justify-around">
								<div className="h-16 w-16 overflow-hidden rounded-full bg-white">
									{croppedImage && (
										<img src={croppedImage} className="h-16 w-16 object-fill" alt="profile-image" />
									)}
								</div>
								<div className="h-8 w-8 overflow-hidden rounded-full bg-white">
									{croppedImage && (
										<img src={croppedImage} className="h-8 w-8" alt="profile-image" />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-row items-center justify-end gap-4 p-4 md:p-6">
				<CancelButton text={t('CANCEL')} onClick={closeModal} className={'h-9'} />

				{!uploading ? (
					<PrimaryButton disabled={!croppedImage} onClick={uploadImage} text={t('SAVE')} />
				) : (
					<div className="ml-4 h-9 w-[4.375rem]">
						<CircularProgress className="h-9 w-9" />
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ProfileAvatarModal;
