import { ICrossSectionFreeProfileChartProps } from 'components/CrossSection/types';
import React, { FunctionComponent } from 'react';
import { Chart } from 'react-chartjs-2';
import { calculateGroundGraphData } from 'utils/freeProfileParamsHelper';

const CrossSectionFreeProfileChart: FunctionComponent<ICrossSectionFreeProfileChartProps> = ({
	data
}) => {
	const groundData = calculateGroundGraphData(data);
	const chartData = {
		datasets: [
			{
				type: 'line' as const,
				label: 'Area Dataset',
				backgroundColor: '#bfbdb8',
				data: groundData,
				pointBackgroundColor: 'black',
				pointRadius: 3,
				order: 2,
				fill: '+1'
			},
			{
				type: 'line' as const,
				pointBackgroundColor: 'blue',
				borderColor: 'blue',
				order: 1,
				data: data,
				pointRadius: 1,
				hoverRadius: 5
			}
		]
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: 'nearest' as const,
			intersect: false
		},
		plugins: {
			legend: { display: false },
			tooltip: {
				callbacks: {
					label: function (context: any) {
						return `y: ${context.parsed.x} m, z: ${context.parsed.y} m`;
					}
				}
			}
		},
		scales: {
			x: {
				title: { display: false },
				ticks: { display: false },
				grid: { display: false }
			},
			y: {
				title: { display: false },
				ticks: { display: false },
				grid: { display: false }
			}
		}
	};
	return <Chart type={'scatter'} data={chartData} options={options} />;
};
export default CrossSectionFreeProfileChart;
