import RegisterPage from 'pages/Auth/RegisterPage';
import ResetPassword from 'pages/Auth/ResetPassword';
import ResetPasswordConfirm from 'pages/Auth/ResetPasswordConfirm';
import React from 'react';

import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import AuthPagesTheme from 'pages/Auth/AuthPagesTheme';
import LoginPage from 'pages/Auth/LoginPage';

const PublicRouter = () => {
	return (
		<div className="h-screen w-screen">
			<Routes>
				<Route
					element={
						<AuthPagesTheme>
							<Outlet />
						</AuthPagesTheme>
					}>
					<Route path={'/login'} element={<LoginPage />} />
					<Route path="/password/reset" element={<ResetPassword />} />
					<Route path="/register" element={<RegisterPage />} />
					<Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
					<Route path={'*'} element={<Navigate to="/login" replace />} />
				</Route>
			</Routes>
		</div>
	);
};

export default PublicRouter;
