import { Timeline } from '@mui/icons-material';
import { Autocomplete, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import { capitalizeFirstLetter } from 'helper/helperFunctions';
import { DischargeStationCurve } from 'interfaces/models/DischargeStationCurve';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { useCreateRatingCurve, useUpdateRatingCurve } from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import { handleFileReadAsString } from 'utils/functions';
import { curveTypes } from 'utils/helperData';
import { selectQhRatingCurveFile } from 'utils/hqRatingCurve';
import * as Yup from 'yup';

const formLabels = ['a', 'b', 'c', 'd'];

const RatingCurveForm = () => {
	const { t } = useTranslation();

	const uploadInputRef = useRef<HTMLInputElement | null>(null);

	const { rating_curve } = useGlobalStore((state) => state.ratingCurveFormData);

	const { ratingCurves, site_id, station_id } = useGlobalStore((state) => state.ratingCurveData);
	const setRatingCurveData = useGlobalStore((state) => state.setRatingCurveData);
	const setRatingCurveFormData = useGlobalStore((state) => state.setRatingCurveFormData);
	const toggleEditQHDataModal = useGlobalStore((state) => state.toggleEditQHDataModal);

	const { mutate: createRatingCurve } = useCreateRatingCurve();
	const { mutate: updateRatingCurve } = useUpdateRatingCurve();

	const [pointsDefined, setPointsDefined] = useState(
		rating_curve && rating_curve.parameters && Math.floor(rating_curve.parameters.length / 2)
	);

	const [qhfile, setQhFile] = useState({ content: '' });

	useEffect(() => {
		setPointsDefined(
			rating_curve && rating_curve.parameters && Math.floor(rating_curve.parameters.length / 2)
		);
	}, [rating_curve]);

	useEffect(() => {
		const fileArray = qhfile.content ? qhfile.content.split(',') : [];
		qhfile.content !== '' && handleQHParamsChange(qhfile.content);

		qhfile.content !== '' && setPointsDefined(Math.floor(fileArray.length / 2));
	}, [qhfile]);

	const readUploadedFile = (content: string) => {
		setQhFile({ content: selectQhRatingCurveFile(content)?.toString() ?? '' });
	};

	const validationSchema = Yup.object().shape({
		id: Yup.number().nullable(),
		model: Yup.string().required(),
		type: Yup.string().required(),
		parameters: Yup.array()
			.of(Yup.number().required(t('REQUIRED')))
			.required(t('REQUIRED'))
	});
	const formik = useFormik({
		initialValues: {
			id: rating_curve?.id,
			model: rating_curve?.model ?? '',
			type: rating_curve?.type ?? null,
			parameters: rating_curve?.parameters ?? []
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			if (!data.id)
				createRatingCurve(
					{
						siteId: Number(site_id),
						stationId: Number(station_id),
						data: data as DischargeStationCurve
					},
					{
						onSuccess: () => {
							toast.success(t('RATING_CURVE_CREATED', { curveName: data.model }));
							queryClient.invalidateQueries(queryKeys.rating_curves);
							setRatingCurveFormData({ rating_curve: null, formToggled: false });
						}
					}
				);
			else
				updateRatingCurve(
					{
						siteId: Number(site_id),
						stationId: Number(station_id),
						ratingCurveId: data.id,
						data: data as DischargeStationCurve
					},
					{
						onSuccess: () => {
							toast.success(t('RATING_CURVE_UPDATED', { curveName: data.model }));
							queryClient.invalidateQueries(queryKeys.rating_curves);
							setRatingCurveFormData({ rating_curve: null, formToggled: false });
						}
					}
				);
		},
		enableReinitialize: true
	});

	const handleCurveParamChange = (event: any, paramIndex: number) => {
		// Create a deep copy of the ratingCurves array
		const curvesCopy = ratingCurves?.map((curve) => ({
			...curve,
			parameters: [...curve.parameters]
		}));

		// Modify the copy
		curvesCopy?.forEach((curve) => {
			if (curve.id === rating_curve?.id || !curve.id) {
				curve.parameters[paramIndex] = Number(event.target.value);
			}
		});

		// Update the state with the modified copy
		setRatingCurveData({
			site_id: Number(site_id),
			station_id: Number(station_id),
			ratingCurves: curvesCopy
		});
	};

	const handleQHParamsChange = (data: string) => {
		// Create a deep copy of the ratingCurves array
		const curvesCopy = ratingCurves?.map((curve) => ({
			...curve,
			parameters: [...curve.parameters]
		}));

		const params = data.split(`,`).map((x) => Number(x));

		// Modify the copy
		curvesCopy?.forEach((curve) => {
			if (curve.id === rating_curve?.id || !curve.id) {
				curve.parameters = params;
				setRatingCurveFormData({ formToggled: true, rating_curve: curve });
			}
		});

		// Update the state with the modified copy
		setRatingCurveData({
			site_id: Number(site_id),
			station_id: Number(station_id),
			ratingCurves: curvesCopy
		});
	};
	const handleCurveTypeChange = (value: any) => {
		// Create a deep copy of the ratingCurves array
		const curvesCopy = ratingCurves?.map((curve) => ({
			...curve
		}));

		// Modify the copy
		curvesCopy?.forEach((curve) => {
			if (curve.id === rating_curve?.id || !curve.id) {
				curve.type = value;
			}
		});

		// Update the state with the modified copy
		setRatingCurveData({
			site_id: Number(site_id),
			station_id: Number(station_id),
			ratingCurves: curvesCopy
		});
	};
	const handleCurveNameChange = (event: any) => {
		// Create a deep copy of the ratingCurves array
		const curvesCopy = ratingCurves?.map((curve) => ({
			...curve
		}));

		// Modify the copy
		curvesCopy?.forEach((curve) => {
			if (curve.id === rating_curve?.id || !curve.id) {
				curve.model = event.target.value;
			}
		});

		// Update the state with the modified copy
		setRatingCurveData({
			site_id: Number(site_id),
			station_id: Number(station_id),
			ratingCurves: curvesCopy
		});
	};

	const handleUploadClick = () => {
		uploadInputRef.current?.click();
	};

	return (
		<div className={'flex flex-col gap-8 rounded-md border border-black p-2'}>
			<div className={'text-bold'}>
				{rating_curve ? `${t('EDIT_RATING_CURVE')}: ${rating_curve.model}` : t('NEW_RATING_CURVE')}
			</div>
			<form onSubmit={formik.handleSubmit}>
				<div className={'flex flex-col gap-4 p-4'}>
					<div className={'flex flex-row flex-wrap gap-4'}>
						<TextField
							id="variable_value"
							required
							value={formik.values.model}
							size={'small'}
							className={'w-40'}
							variant={'standard'}
							label={t('NAME')}
							name="model"
							onBlur={formik.handleBlur}
							onChange={(e) => {
								formik.handleChange(e);
								handleCurveNameChange(e);
							}}
							error={formik.touched.model && Boolean(formik.errors.model)}
						/>
						<FormControl required>
							<Autocomplete
								size={'small'}
								options={curveTypes}
								getOptionLabel={(option: string) =>
									capitalizeFirstLetter(option.replaceAll('_', ' '))
								}
								className={'w-40'}
								value={formik.values.type ?? null}
								onBlur={formik.handleBlur}
								onChange={(e, value) => {
									formik.setFieldValue('type', value);
									if (value === 'quadratic' || value === 'exponential')
										formik.setFieldValue('parameters', [0, 0, 0]);
									if (value === 'cubic') formik.setFieldValue('parameters', [0, 0, 0, 0]);

									if (value === 'qh_pairs') formik.setFieldValue('parameters', []);
									handleCurveTypeChange(value);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label={t('CURVE_TYPE')}
										variant={'standard'}
										error={formik.touched.type && Boolean(formik.errors.type)}
									/>
								)}
							/>
						</FormControl>
					</div>
					{formik.values.type !== 'qh_pairs' && (
						<div className={'flex flex-col'}>
							<div>{t('PARAMETERS')}</div>
							<div className={'flex flex-wrap'}>
								<div className={'flex flex-wrap gap-2'}>
									{formik.values.parameters.map((val, index) => {
										return (
											<div className={'flex items-center gap-1 align-middle'} key={index}>
												<InputLabel id="curve-type-required-label">
													{formLabels[index]} =
												</InputLabel>
												<TextField
													id="variable_value"
													type={'number'}
													required
													value={formik.values.parameters[index]}
													className={'w-24'}
													size={'small'}
													name={`parameters[${index}]`}
													onBlur={formik.handleBlur}
													onChange={(event) => {
														handleCurveParamChange(event, index);
														formik.handleChange(event);
													}}
													error={Boolean(
														formik.errors.parameters && formik.errors.parameters[index]
													)}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)}
					<div className={'flex flex-col'}>
						{formik.values.type !== 'qh_pairs' && <div>{t('EQUATION')}</div>}
						<div className={'flex flex-wrap'}>
							{(() => {
								switch (formik.values.type) {
									case 'exponential':
										return (
											<span>
												Q(h) = {formik.values.parameters[0]} * (h{' '}
												{formik.values.parameters[1] >= 0 ? '+' : '-'}
												{Math.abs(Number(formik.values.parameters[1]))})
												<sup>{formik.values.parameters[2]}</sup>
											</span>
										);
									case 'quadratic':
										return (
											<span>
												Q(h) = {formik.values.parameters[0]}h<sup>2</sup> +{' '}
												{formik.values.parameters[1]}h + {formik.values.parameters[2]}
											</span>
										);
									case 'cubic':
										return (
											<span>
												Q(h) = {formik.values.parameters[0]}h<sup>3</sup> +{' '}
												{formik.values.parameters[1]}h<sup>2</sup> + {formik.values.parameters[2]}h
												+ {formik.values.parameters[3]}
											</span>
										);
									case 'qh_pairs':
										return (
											<div className={'flex items-center gap-4 align-middle max-md:flex-col'}>
												<input
													type="file"
													id={`hqpairs-input`}
													className={'hidden'}
													ref={uploadInputRef}
													onChange={(e) => {
														handleFileReadAsString(e, readUploadedFile);
													}}
													accept="*"
												/>
												<div>
													<Timeline></Timeline> {pointsDefined} {t('POINTS_DEFINED')}{' '}
												</div>
												<div className={'flex gap-1'}>
													<Button variant={'text'} onClick={toggleEditQHDataModal}>
														{t('EDIT_DATA')}
													</Button>

													<Button variant={'text'} onClick={handleUploadClick}>
														{t('IMPORT_FROM_FILE')}
													</Button>
												</div>
											</div>
										);
									default:
										return null;
								}
							})()}
						</div>
					</div>
					<div className={' flex w-full flex-wrap justify-center gap-2 disabled:bg-gray-400'}>
						<Button
							type={'submit'}
							variant={'contained'}
							className={'normal-case'}
							disabled={!formik.isValid}>
							{rating_curve?.id ? t('UPDATE') : t('ADD')}
						</Button>{' '}
						<Button
							variant={'contained'}
							className={'bg-accent normal-case'}
							onClick={() => {
								setRatingCurveFormData({ rating_curve: null, formToggled: false });
							}}>
							{t('CANCEL')}
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};
export default RatingCurveForm;
