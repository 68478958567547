import CancelButton from 'components/shared/Button/CancelButton';
import DeleteButton from 'components/shared/Button/DeleteButton';
import Modal from 'components/shared/Modal/Modal';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useAuth, useLogout } from 'rq/hooks/authHook';
import * as Yup from 'yup';
import AuthService from 'services/AuthService/AuthService';
import UsersService from 'services/UsersService/UsersService';

const DeleteAccountModal = ({
	open,
	setDeleteAccountModalOpen
}: {
	open: boolean;
	setDeleteAccountModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const { t } = useTranslation();

	const { mutate: logout } = useLogout();
	const { data: user } = useAuth();
	const userId = user?.id;
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const closeModal = () => {
		setDeleteAccountModalOpen(false);
	};

	const validationSchema = Yup.object().shape({
		password: Yup.string().required(t('REQUIRED'))
	});
	const formik = useFormik({
		initialValues: {
			password: ''
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			AuthService.authPasswordCheckCreate(data).then((response) => {
				if (response.password_valid) {
					setErrorMessage(undefined);
					toast(t('DELETING_USER'));
					UsersService.deleteUser('' + userId).then(() => {
						logout();
					});
				} else {
					setErrorMessage(t('PASSWORDS_DO_NOT_MATCH'));
				}
			});
		}
	});

	return (
		<Modal open={open} closeModal={closeModal} modalTitle={t('DELETE_ACCOUNT')}>
			<div className="m-4 flex flex-col md:m-6">
				<div className="w-[25rem] max-w-[90%] justify-center self-center text-center">
					{t('DELETE_ACCOUNT_WARNING')}
				</div>
				<form className="pt-8" onSubmit={formik.handleSubmit} autoComplete="off">
					<TextField
						className="mb-1 w-full md:mb-1"
						type="password"
						id="password"
						value={formik.values.password}
						label={t('PASSWORD')}
						name="password"
						onChange={formik.handleChange}
						error={formik.touched.password && Boolean(formik.errors.password)}
					/>
					{errorMessage ? (
						<div className="ml-4 text-sm text-red-500">{errorMessage}</div>
					) : (
						<div className="h-[20px]"></div>
					)}
					<div className="my-4 mt-8 flex h-9 flex-row justify-center gap-4 md:justify-end">
						<CancelButton className=" h-9" text={t('CANCEL')} onClick={closeModal} />

						<DeleteButton
							className={'h-9'}
							disabled={!formik.dirty || !formik.isValid}
							text={t('DELETE')}
							type="submit"
						/>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default DeleteAccountModal;
