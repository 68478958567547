const createImage = (url: string) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.src = url;
	});

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export default async function getCroppedImg(
	imageSrc: string,
	pixelCrop: { width: number; height: number; x: number; y: number }
) {
	const image = (await createImage(imageSrc)) as CanvasImageSource;
	const canvas = document.createElement('canvas');
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;
	const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

	ctx.fillStyle = '#ffffff';
	ctx?.fillRect(0, 0, canvas.width, canvas.height);
	ctx?.drawImage(
		image,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		pixelCrop.width,
		pixelCrop.height
	);

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise<{ fileBinary: File; blob: string }>((resolve) => {
		canvas.toBlob((file) => {
			resolve({
				fileBinary: new File([file as BlobPart], 'profile-image.png'),
				blob: URL.createObjectURL(file as Blob)
			});
		});
	});
}
