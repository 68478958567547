import { useGlobalStore } from 'global-state/useStore';
import SitesPage from 'pages/Sites/SitesPage';
import React, { useEffect } from 'react';

const CloudProcessingHome = () => {
	const toggleReprocessingMeasurement = useGlobalStore(
		(state) => state.toggleReprocessingMeasurement
	);
	useEffect(() => {
		toggleReprocessingMeasurement({ reprocessing: false, originalReprocessingData: null });
	});
	return (
		<>
			<SitesPage isCloudProcessing={true}></SitesPage>
		</>
	);
};
export default CloudProcessingHome;
