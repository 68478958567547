export const scrollToSelectedMeasurement = (
	selectedMeasurementId: number | undefined,
	lastScrolledMeasurementRef: any
) => {
	if (
		selectedMeasurementId &&
		(lastScrolledMeasurementRef.current === undefined ||
			selectedMeasurementId.toString() !== lastScrolledMeasurementRef.current)
	) {
		const elements = document.getElementsByClassName('MuiDataGrid-row Mui-selected');
		if (elements.length > 0) {
			const element = elements[0] as HTMLElement;
			element.scrollIntoView({
				behavior: 'smooth'
			});
			lastScrolledMeasurementRef.current = selectedMeasurementId.toString();
		}
	}
};
