import { apiClient } from 'agent/apiClient';
import { IPaginatedResponse } from 'interfaces/interfaces';
import { StationMeasurementsModel } from 'interfaces/models/StationMeasurementsModel';
import { IMeasurementsService } from 'services/MeasurementsService/interface';
import { getQueryParamsString } from 'utils/functions';

export const MeasurementsService: IMeasurementsService = {
	getMeasurementsList: async (data) => {
		const queryString = getQueryParamsString({ ...data.queryParams });
		return await apiClient
			.get(`/sites/${data.site_id}/stations/${data.station_id}/measurements/?${queryString}`)
			.then((res) => res.data.results);
	},
	getMeasurementsPaginatedList: async (data) => {
		const queryString = getQueryParamsString({ ...data.queryParams });
		return await apiClient
			.get(`/sites/${data.site_id}/stations/${data.station_id}/measurements/?${queryString}`)
			.then<IPaginatedResponse<StationMeasurementsModel>>(
				(res): IPaginatedResponse<StationMeasurementsModel> => {
					return res.data as IPaginatedResponse<StationMeasurementsModel>;
				}
			);
	},
	getMeasurementDetails: async (data) => {
		return await apiClient
			.get(
				`/sites/${data.site_id}/stations/${data.station_id}/measurements/${data.measurement_id}/`
			)
			.then((res) => res.data);
	},
	createMeasurement: async (data) => {
		return await apiClient
			.post(`/sites/${data.site_id}/stations/${data.station_id}/measurements/`, data.measurement)
			.then((res) => res.data);
	},
	updateMeasurement: async (data) => {
		return await apiClient
			.put(
				`/sites/${data.site_id}/stations/${data.station_id}/measurements/${data.measurement_id}`,
				data.measurement
			)
			.then((res) => res.data);
	},
	deleteMeasurement: async (data) => {
		return await apiClient
			.delete(
				`/sites/${data.site_id}/stations/${data.station_id}/measurements/${data.measurement_id}`
			)
			.then((res) => res.data);
	},
	exportMeasurements: async (data) => {
		const queryString = getQueryParamsString({ ...data.queryParams });
		return await apiClient
			.get(`/sites/${data.site_id}/stations/${data.station_id}/measurements/?${queryString}`)
			.then((res) => {
				return res;
			});
	}
};
