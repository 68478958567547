import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useLogin } from 'rq/hooks/authHook';
import { useTranslation } from 'react-i18next';

import i18n, { availableTranslations } from '../../res/localization/i18n';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { isSeba } = useGlobalStore((state) => state.hostname);

	const handleChange = (event: SelectChangeEvent) => {
		i18n.changeLanguage(event.target.value);
		localStorage.setItem('lng', event.target.value);
	};

	const { mutate: login } = useLogin();

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		login({
			username: data.get('email') as string,
			password: data.get('password') as string,
			grant_type: 'password'
		});
	};

	return (
		<Grid
			className="flex flex-col justify-center"
			item
			xs={12}
			sm={8}
			md={5}
			component={Paper}
			elevation={6}
			square>
			<Box
				sx={{
					my: 8,
					mx: 4,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}>
				<img
					src={
						isSeba
							? '/assets/seba-icon.png'
							: 'https://discharge.ch/images/discharge_logo_mobile.png'
					}
					className={isSeba ? 'm-4 h-16 w-12' : 'm-4 h-16 w-16'}
					alt={isSeba ? 'Seba logo' : 'Discharge logo'}
				/>
				<Typography component="h1" variant="h5">
					{t('LOGIN')}
				</Typography>
				<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label={t('USERNAME_OR_EMAIL')}
						name="email"
						autoComplete="email"
						autoFocus
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label={t('PASSWORD')}
						type="password"
						id="password"
						autoComplete="current-password"
					/>
					<div>
						<FormControl required sx={{ mt: 2, mb: 1, minWidth: 200 }}>
							<InputLabel id="demo-simple-select-required-label">
								{t('DISPLAY_LANGUAGE')}
							</InputLabel>
							<Select
								labelId="demo-simple-select-required-label"
								id="demo-simple-select-required"
								value={i18n.language}
								label={t('DISPLAY_LANGUAGE')}
								name="language-select"
								onChange={handleChange}>
								{availableTranslations.map((option) => {
									return (
										<MenuItem key={option.language} value={option.short}>
											<div className="mr-2 inline-block">
												<img
													src={`https://flagcdn.com/w20/${option.flag.toLowerCase()}.png`}
													alt={'flag'}
												/>
											</div>
											{t(option.language)}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
					<PrimaryButton type={'submit'} text={t('LOGIN')} className={'mt-3 mb-2'} />
					<Grid container>
						<Grid item xs>
							<Link
								className="cursor-pointer"
								variant="body2"
								onClick={() => {
									navigate('/password/reset');
								}}>
								{t('FORGOT_PASSWORD')}
							</Link>
						</Grid>
						<Grid item>
							<Link
								className="cursor-pointer"
								variant="body2"
								onClick={() => {
									navigate('/register');
								}}>
								{t('REGISTER')}
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Grid>
	);
}
