import CancelButton from 'components/shared/Button/CancelButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import Modal from 'components/shared/Modal/Modal';
import React from 'react';
import TextField from '@mui/material/TextField';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/AuthService/AuthService';

const ChangePasswordModal = ({
	open,
	setChangePasswordModalOpen
}: {
	open: boolean;
	setChangePasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		old_password: Yup.string().required(t('REQUIRED')),
		new_password1: Yup.string()
			.required(t('PASSWORD_REQUIRED'))
			.min(6, t('PASSWORD_MIN_LENGTH_WARNING'))
			.max(50, t('PASSWORD_MAX_LENGTH_WARNING')),
		new_password2: Yup.string()
			.oneOf([Yup.ref('new_password1'), null], t('PASSWORDS_DO_NOT_MATCH'))
			.required(t('REQUIRED'))
	});
	const formik = useFormik({
		initialValues: {
			old_password: '',
			new_password1: '',
			new_password2: ''
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			AuthService.authPasswordChangeCreate({
				...data
			})
				.then(() => {
					setChangePasswordModalOpen(false);
					toast.success(t('PASSWORD_CHANGED_SUCCESSFULLY'));
				})
				.catch((error) => {
					console.log(error);
					toast.error(t('INVALID_INPUT_DATA'));
				});
		}
	});

	const closeModal = () => {
		setChangePasswordModalOpen(false);
	};

	return (
		<Modal closeModal={closeModal} open={open} modalTitle={t('CHANGE_PASSWORD')}>
			<form className="md:pt-8" onSubmit={formik.handleSubmit} autoComplete="off">
				<div className="mx-2 flex flex-col items-center p-8 md:mx-8">
					<TextField
						className="mb-4 w-60 md:mb-8"
						type="password"
						id="old_password"
						value={formik.values.old_password}
						label={t('OLD_PASSWORD')}
						name="old_password"
						onChange={formik.handleChange}
						error={formik.touched.old_password && Boolean(formik.errors.old_password)}
					/>
					<TextField
						type="password"
						className="mb-4 w-60 md:mb-8"
						id="new_password1"
						value={formik.values.new_password1}
						label={t('NEW_PASSWORD')}
						variant="outlined"
						name="new_password1"
						onChange={formik.handleChange}
						error={formik.touched.new_password1 && Boolean(formik.errors.new_password1)}
					/>

					<TextField
						type="password"
						className="mb-4 w-60 md:mb-8"
						id="new_password2"
						label={t('REPEAT_NEW_PASSWORD')}
						variant="outlined"
						name="new_password2"
						value={formik.values.new_password2}
						onChange={formik.handleChange}
						error={formik.touched.new_password2 && Boolean(formik.errors.new_password2)}
					/>
				</div>
				<div className="h-18 my-4 flex flex-col items-center justify-center gap-4 px-2 md:h-9 md:flex-row">
					<CancelButton className="mb-4 h-9 " text={t('CANCEL')} onClick={closeModal} />
					<PrimaryButton
						className={'mb-4 h-9 '}
						disabled={!formik.dirty || !formik.isValid}
						text={t('CHANGE_PASSWORD')}
						type="submit"
					/>
				</div>
			</form>
		</Modal>
	);
};

export default ChangePasswordModal;
