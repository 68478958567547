import { Refresh } from '@mui/icons-material';
import { FormControlLabel, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import { IMeasurementsChartProps } from 'components/Measurements/types';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { format } from 'date-fns';
import { useGlobalStore } from 'global-state/useStore';
import { convertToUsersTimezone } from 'helper/helperFunctions';
import {
	StationMeasurementsDailyModel,
	StationMeasurementsHourlyModel,
	StationMeasurementsModel
} from 'interfaces/models/StationMeasurementsModel';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { useTranslation } from 'react-i18next';
import {
	Chart as ChartJS,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	TimeScale,
	CategoryScale,
	ActiveElement,
	ChartEvent
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';

ChartJS.register(
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	TimeScale,
	CategoryScale,
	zoomPlugin
);
const MeasurementsChart: FunctionComponent<IMeasurementsChartProps> = ({
	data,
	aggregationType,
	anyMeasData,
	anyMeasToggled,
	handleMeasurementsRefresh
}) => {
	const { t } = useTranslation();
	const setSelectedMeasurementId = useGlobalStore((state) => state.setSelectedMeasurementId);
	const SIUnitsToggled = useGlobalStore((state) => state.SIUnitsToggled);
	const toggleSIUnits = useGlobalStore((state) => state.toggleSIUnits);
	const { timezone_display } = useGlobalStore((state) => state.userInfo);

	const chartRef = useRef<ChartJSOrUndefined<'line', number[] | undefined | DataPoint[]>>();

	const [SIUnits, setSIUnits] = useState(SIUnitsToggled);
	const anyParamDatasetsColors = ['orange', 'yellow', 'pink', 'velvet'];

	const resetZoom = () => {
		if (chartRef.current) {
			const chartInstance = chartRef.current;
			if (chartInstance) {
				chartInstance.resetZoom();
			}
		}
	};

	const handleClick = (elems: ActiveElement[]) => {
		if (data && elems && elems.length > 0) {
			const dataIndex = elems[0].index;

			const clickedData = data[dataIndex];

			let clickedId;
			if (aggregationType === 'all_measurements')
				clickedId = clickedData && (clickedData as StationMeasurementsModel).measurement_id;
			if (aggregationType === 'daily' || aggregationType === 'hourly') clickedId = dataIndex;
			if (clickedId) {
				setSelectedMeasurementId(clickedId);
				/*		gridRef && gridRef.current.selectRow(clickedId, true, true);*/
			}
		}
	};

	const parseMeasData = (key: 'level' | 'velocity' | 'discharge') => {
		let measData;
		if (aggregationType === 'all_measurements') {
			measData = (data as StationMeasurementsModel[])?.slice();
			return measData?.map((meas) => meas[key]);
		} else if (aggregationType === 'daily') {
			measData = (data as StationMeasurementsDailyModel[])?.slice();
			return measData?.map((meas) => meas[`${key}_avg`]);
		} else if (aggregationType === 'hourly') {
			measData = (data as StationMeasurementsHourlyModel[])?.slice();
			return measData?.map((meas) => meas[`${key}_avg`]);
		}
	};

	const labels = useMemo(
		() =>
			data?.map(
				(
					meas:
						| StationMeasurementsModel
						| StationMeasurementsDailyModel
						| StationMeasurementsHourlyModel
				) => {
					if (aggregationType === 'all_measurements') {
						return convertToUsersTimezone(
							new Date((meas as StationMeasurementsModel).date_time),
							{},
							timezone_display
						);
					} else if (aggregationType === 'daily') {
						return (meas as StationMeasurementsDailyModel).date;
					} else if (aggregationType === 'hourly') {
						return (meas as StationMeasurementsHourlyModel).hour;
					}
				}
			),
		[data, aggregationType]
	);

	interface DataPoint {
		x: Date | string;
		y: number;
	}

	const parseNewData = () => {
		if (!anyMeasToggled) return [];
		const datasetsByParamName: Record<string, DataPoint[]> = {};

		anyMeasData?.forEach((dataPoint) => {
			const { paramName, date_time, value } = dataPoint;

			if (!datasetsByParamName[paramName]) {
				datasetsByParamName[paramName] = [];
			}

			datasetsByParamName[paramName].push({
				x: convertToUsersTimezone(new Date(date_time), {}, timezone_display),
				y: parseFloat(value)
			});
		});

		return Object.entries(datasetsByParamName).map(([paramName, dataPoints], index) => ({
			label: paramName,
			yAxisID: paramName,
			data: dataPoints,
			borderColor: anyParamDatasetsColors[index],
			backgroundColor: `${anyParamDatasetsColors[index]}4D`
		}));
	};

	const datasets = parseNewData();

	const generateScaleOptions = (datasets: any[]) => {
		const defaultDatasets = ['discharge', 'level', 'velocity'];
		const scales: Record<string, any> = {};

		datasets.forEach((dataset) => {
			const yAxisID = dataset.yAxisID;
			if (!defaultDatasets.includes(yAxisID))
				scales[yAxisID] = {
					type: 'linear',
					display: true,
					position: 'left' /*
					stack: 'demo',
					stackWeight: 1,*/,
					title: {
						display: true,
						text: yAxisID
					},
					grid: {
						drawOnChartArea: false
					} /*
					offset: true*/
				};
			if (yAxisID === 'discharge')
				scales[yAxisID] = {
					id: 'discharge',
					type: 'linear' as const,
					position: 'left' as const /*
					stack: 'demo',
					stackWeight: 2,*/,
					ticks: {
						callback: function (value: any) {
							return SIUnits ? value.toFixed(2) + ' m3/s' : (value * 1000).toFixed() + ' L/s';
						},
						maxRotation: 0,
						autoSkip: true
					},
					title: {
						display: true,
						text: t('DISCHARGE')
					},
					grid: {
						drawOnChartArea: false
					}
					/*
										offset: true*/
				};
			else if (yAxisID === 'level')
				scales[yAxisID] = {
					id: 'level',
					type: 'linear' as const,
					position: 'left' as const /*
					stack: 'demo',
					stackWeight: 3,*/,
					ticks: {
						callback: function (value: any) {
							return SIUnits ? value.toFixed(2) + ' m' : (value * 100).toFixed() + ' cm';
						},
						maxRotation: 0,
						autoSkip: true
					},
					title: {
						display: true,
						text: t('LEVEL')
					},
					grid: {
						drawOnChartArea: false
					} /*
					offset: true*/
				};
			else if (yAxisID === 'velocity')
				scales[yAxisID] = {
					id: 'velocity',
					type: 'linear' as const,
					position: 'left' as const /*
					stack: 'demo',*/,
					ticks: {
						callback: function (value: any) {
							return SIUnits ? value.toFixed(2) + ' m/s' : (value * 100).toFixed() + ' cm/s';
						},
						maxRotation: 0,
						autoSkip: true
					},
					title: {
						display: true,
						text: t('VELOCITY')
					},
					grid: {
						drawOnChartArea: false
					} /*
					offset: true*/
				};
		});
		return scales;
	};

	const chartData = useMemo(
		() => ({
			labels,
			datasets: [
				...datasets,
				{
					label: t('DISCHARGE'),
					data: parseMeasData('discharge'),
					borderColor: 'red',
					backgroundColor: 'red4D',
					yAxisID: 'discharge'
				},
				{
					label: t('LEVEL'),
					data: parseMeasData('level'),
					borderColor: 'blue',
					backgroundColor: 'blue4D',
					yAxisID: 'level'
				},
				{
					label: t('VELOCITY'),
					data: parseMeasData('velocity'),
					borderColor: 'green',
					backgroundColor: 'green4D',
					position: 'right',
					yAxisID: 'velocity'
				}
			]
		}),
		[data, aggregationType, anyMeasData, anyMeasToggled]
	);

	useEffect(() => {
		setSIUnits(SIUnitsToggled);
	}, [SIUnitsToggled]);

	const options = useMemo(
		() => ({
			responsive: true,
			maintainAspectRatio: false,
			animation: false as const,
			onClick: (event: ChartEvent, elements: ActiveElement[]) => handleClick(elements),
			interaction: {
				mode: 'x' as const,
				intersect: false
			},
			plugins: {
				legend: {
					position: 'bottom' as const
				},
				interaction: {
					mode: 'index' as const,
					intersect: false
				},
				zoom: {
					zoom: {
						/*	wheel: {
                enabled: true,
                mode: 'xy' as const
              },*/
						pinch: {
							enabled: true,
							mode: 'y' as const
						},
						drag: {
							enabled: true,
							mode: 'y' as const,
							borderColor: 'rgb(54, 162, 235)',
							borderWidth: 1,
							threshold: 20,
							backgroundColor: 'rgba(54, 162, 235, 0.3)'
						}
					}
				}
			},
			scales: {
				x: {
					type: 'time' as const,
					position: 'bottom' as const,
					ticks: {
						callback: function (value: any) {
							if (aggregationType === 'daily') return format(new Date(value), 'yyyy-MM-dd');
							else return format(new Date(value), 'yyyy-MM-dd HH:mm');
						}
					}
				},
				...generateScaleOptions(chartData.datasets)
			}
		}),
		[data, aggregationType, SIUnits, anyMeasData, anyMeasToggled]
	);

	return (
		<>
			<div className={'mb-4 flex w-full items-center justify-end gap-4 px-8 align-middle'}>
				<IconWithTooltip
					title={t('REFRESH')}
					icon={<Refresh color={'primary'} onClick={handleMeasurementsRefresh} />}
				/>
				<FormControlLabel
					control={<Switch checked={SIUnits} onChange={toggleSIUnits}></Switch>}
					label={t('SI_UNITS')}
				/>
				<Button onClick={resetZoom} variant={'outlined'}>
					{t('RESET_ZOOM')}
				</Button>
			</div>
			<Line ref={chartRef} options={options} data={chartData} />
		</>
	);
};
export default MeasurementsChart;
