import { IPaginatedResponse } from 'interfaces/interfaces';
import {
	StationMeasurementsDailyModel,
	StationMeasurementsHourlyModel,
	StationMeasurementsModel
} from 'interfaces/models/StationMeasurementsModel';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { queryKeys } from 'rq/constants';
import {
	ICreateMeasurementParams,
	IDeleteMeasurementParams,
	IGetMeasurementDetailsParams,
	IGetMeasurementsPaginatedParams,
	IGetMeasurementsParams,
	IUpdateMeasurementParams
} from 'services/MeasurementsService/interface';
import { MeasurementsService } from 'services/MeasurementsService/MeasurementsService';

export const useMeasurementsPaginated = (
	data: IGetMeasurementsPaginatedParams,
	options?: Omit<
		UseQueryOptions<
			IPaginatedResponse<StationMeasurementsModel>,
			unknown,
			IPaginatedResponse<StationMeasurementsModel>,
			[string, string, string, string, string, string, string, { filters: string[] }]
		>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[
			queryKeys.measurements_paginated,
			`site-${data.site_id}`,
			`station-${data.station_id}`,
			`averaging-${data.queryParams.averaging}`,
			`page-${data.queryParams.page}`,
			`pageSize-${data.queryParams.page_size}`,
			`page_for_id-${data.queryParams.page_for_id}`,
			{
				filters: [
					`date-from-${data.queryParams.date_time__ge}`,
					`date-to-${data.queryParams.date_time__le}`,
					`username-${data.queryParams.username}`,
					`discharge-gt-${data.queryParams.discharge__gt}`,
					`discharge-lt-${data.queryParams.discharge__lt}`,
					`discharge-eq-${data.queryParams.discharge__eq}`,
					`discharge-le-${data.queryParams.discharge__le}`,
					`discharge-ge-${data.queryParams.discharge__ge}`,
					`velocity-gt-${data.queryParams.velocity__gt}`,
					`velocity-lt-${data.queryParams.velocity__lt}`,
					`velocity-eq-${data.queryParams.velocity__eq}`,
					`velocity-le-${data.queryParams.velocity__le}`,
					`velocity-ge-${data.queryParams.velocity__ge}`,
					`displacement-gt-${data.queryParams.displacement__gt}`,
					`displacement-lt-${data.queryParams.displacement__lt}`,
					`displacement-eq-${data.queryParams.displacement__eq}`,
					`displacement-le-${data.queryParams.displacement__le}`,
					`displacement-ge-${data.queryParams.displacement__ge}`,
					`level-gt-${data.queryParams.level__gt}`,
					`level-lt-${data.queryParams.level__lt}`,
					`level-eq-${data.queryParams.level__eq}`,
					`level-le-${data.queryParams.level__le}`,
					`level-ge-${data.queryParams.level__ge}`,
					`mean_surface_velocity-gt-${data.queryParams.mean_surface_velocity__gt}`,
					`mean_surface_velocity-lt-${data.queryParams.mean_surface_velocity__lt}`,
					`mean_surface_velocity-eq-${data.queryParams.mean_surface_velocity__eq}`,
					`mean_surface_velocity-le-${data.queryParams.mean_surface_velocity__le}`,
					`mean_surface_velocity-ge-${data.queryParams.mean_surface_velocity__ge}`,
					`bulk_velocity-gt-${data.queryParams.bulk_velocity__gt}`,
					`bulk_velocity-lt-${data.queryParams.bulk_velocity__lt}`,
					`bulk_velocity-eq-${data.queryParams.bulk_velocity__eq}`,
					`bulk_velocity-le-${data.queryParams.bulk_velocity__le}`,
					`bulk_velocity-ge-${data.queryParams.bulk_velocity__ge}`,
					`wet_cross_sectional_area-gt-${data.queryParams.wet_cross_sectional_area__gt}`,
					`wet_cross_sectional_area-lt-${data.queryParams.wet_cross_sectional_area__lt}`,
					`wet_cross_sectional_area-eq-${data.queryParams.wet_cross_sectional_area__eq}`,
					`wet_cross_sectional_area-le-${data.queryParams.wet_cross_sectional_area__le}`,
					`wet_cross_sectional_area-ge-${data.queryParams.wet_cross_sectional_area__ge}`
				]
			}
		],
		() => MeasurementsService.getMeasurementsPaginatedList(data),
		{
			...options
		}
	);
};
export const useMeasurements = (
	data: IGetMeasurementsParams,
	options?: Omit<
		UseQueryOptions<
			| StationMeasurementsModel[]
			| StationMeasurementsHourlyModel[]
			| StationMeasurementsDailyModel[],
			unknown,
			| StationMeasurementsModel[]
			| StationMeasurementsHourlyModel[]
			| StationMeasurementsDailyModel[],
			[string, string, string, string, string, string, string, { filters: string[] }]
		>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[
			queryKeys.measurements,
			`site-${data.site_id}`,
			`station-${data.station_id}`,
			`averaging-${data.queryParams.averaging}`,
			`return-all-${data.queryParams.return_all}`,
			`group-${data.queryParams.group}`,
			`group-limit-${data.queryParams.group_limit}`,
			{
				filters: [
					`date-from-${data.queryParams.date_time__ge}`,
					`date-to-${data.queryParams.date_time__le}`,
					`username-${data.queryParams.username}`,
					`discharge-gt-${data.queryParams.discharge__gt}`,
					`discharge-lt-${data.queryParams.discharge__lt}`,
					`discharge-eq-${data.queryParams.discharge__eq}`,
					`discharge-le-${data.queryParams.discharge__le}`,
					`discharge-ge-${data.queryParams.discharge__ge}`,
					`velocity-gt-${data.queryParams.velocity__gt}`,
					`velocity-lt-${data.queryParams.velocity__lt}`,
					`velocity-eq-${data.queryParams.velocity__eq}`,
					`velocity-le-${data.queryParams.velocity__le}`,
					`velocity-ge-${data.queryParams.velocity__ge}`,
					`displacement-gt-${data.queryParams.displacement__gt}`,
					`displacement-lt-${data.queryParams.displacement__lt}`,
					`displacement-eq-${data.queryParams.displacement__eq}`,
					`displacement-le-${data.queryParams.displacement__le}`,
					`displacement-ge-${data.queryParams.displacement__ge}`,
					`level-gt-${data.queryParams.level__gt}`,
					`level-lt-${data.queryParams.level__lt}`,
					`level-eq-${data.queryParams.level__eq}`,
					`level-le-${data.queryParams.level__le}`,
					`level-ge-${data.queryParams.level__ge}`,
					`mean_surface_velocity-gt-${data.queryParams.mean_surface_velocity__gt}`,
					`mean_surface_velocity-lt-${data.queryParams.mean_surface_velocity__lt}`,
					`mean_surface_velocity-eq-${data.queryParams.mean_surface_velocity__eq}`,
					`mean_surface_velocity-le-${data.queryParams.mean_surface_velocity__le}`,
					`mean_surface_velocity-ge-${data.queryParams.mean_surface_velocity__ge}`,
					`bulk_velocity-gt-${data.queryParams.bulk_velocity__gt}`,
					`bulk_velocity-lt-${data.queryParams.bulk_velocity__lt}`,
					`bulk_velocity-eq-${data.queryParams.bulk_velocity__eq}`,
					`bulk_velocity-le-${data.queryParams.bulk_velocity__le}`,
					`bulk_velocity-ge-${data.queryParams.bulk_velocity__ge}`,
					`wet_cross_sectional_area-gt-${data.queryParams.wet_cross_sectional_area__gt}`,
					`wet_cross_sectional_area-lt-${data.queryParams.wet_cross_sectional_area__lt}`,
					`wet_cross_sectional_area-eq-${data.queryParams.wet_cross_sectional_area__eq}`,
					`wet_cross_sectional_area-le-${data.queryParams.wet_cross_sectional_area__le}`,
					`wet_cross_sectional_area-ge-${data.queryParams.wet_cross_sectional_area__ge}`
				]
			}
		],
		() => MeasurementsService.getMeasurementsList(data),
		{
			...options
		}
	);
};
export const useRatingCurveMeasurements = (
	data: IGetMeasurementsParams,
	options?: Omit<
		UseQueryOptions<
			| StationMeasurementsModel[]
			| StationMeasurementsHourlyModel[]
			| StationMeasurementsDailyModel[],
			unknown,
			| StationMeasurementsModel[]
			| StationMeasurementsHourlyModel[]
			| StationMeasurementsDailyModel[],
			[string, string, string, string, string, string]
		>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[
			queryKeys.rating_curve_measurements,
			`site-${data.site_id}`,
			`station-${data.station_id}`,
			`ordering-${data.queryParams.ordering}`,
			`return-all-${data.queryParams.return_all}`,
			`group-${data.queryParams.group}`
		],
		() => MeasurementsService.getMeasurementsList(data),
		{
			...options
		}
	);
};
export const useMeasurementDetails = (
	data: IGetMeasurementDetailsParams,
	options?: Omit<
		UseQueryOptions<
			StationMeasurementsModel,
			unknown,
			StationMeasurementsModel,
			[string, string, string]
		>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[queryKeys.measurements, `site-id-${data.site_id}`, `station-id-${data.station_id}`],
		() => MeasurementsService.getMeasurementDetails(data),
		{
			...options
		}
	);
};

export function useCreateMeasurement(
	options?: Omit<
		UseMutationOptions<StationMeasurementsModel, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation(
		(data: ICreateMeasurementParams) => MeasurementsService.createMeasurement(data),
		{
			...options
		}
	);
}

export function useUpdateMeasurement(
	options?: Omit<
		UseMutationOptions<StationMeasurementsModel, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation(
		(data: IUpdateMeasurementParams) => MeasurementsService.updateMeasurement(data),
		{
			...options
		}
	);
}

export function useDeleteMeasurement(
	options?: Omit<UseMutationOptions<void, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation(
		(data: IDeleteMeasurementParams) => MeasurementsService.deleteMeasurement(data),
		{
			...options
		}
	);
}
