import ModalContainer from 'components/Modal/ModalContainer';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useMemo } from 'react';
import { Toaster } from 'react-hot-toast';
import 'styles/app.scss';
import PrivateRouter from 'routing/PrivateRouter';
import PublicRouter from 'routing/PublicRouter';
import { useAuth } from 'rq/hooks/authHook';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'components/shared/ConfirmationDialog/Dialog';
import AuthService from 'services/AuthService/AuthService';

const App = () => {
	const { data: isAuth, isLoading: appLoading } = useAuth();

	const confirmationDialog = useGlobalStore((state) => state.confirmationDialog);
	const setHostname = useGlobalStore((state) => state.setHostname);

	useEffect(() => {
		AuthService.checkToken();
		setHostname(window.location.hostname);
	}, []);

	const memorizedRoutes = useMemo(
		() => (isAuth ? <PrivateRouter /> : <PublicRouter />),
		[isAuth, !!isAuth?.organizations]
	);

	const memorizedConfirmationDialog = useMemo(() => <Dialog />, [confirmationDialog]);

	if (appLoading) {
		return (
			<div
				className={'app'}
				style={{
					margin: '0',
					height: '100vh',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					color: '#00adee'
				}}>
				<CircularProgress size={75} thickness={5} />
			</div>
		);
	} else
		return (
			<>
				<Toaster
					position="top-center"
					containerClassName="text-center"
					toastOptions={{
						className: 'bg-primary border border-gray-500 rounded-md',
						duration: 4000,
						style: {
							paddingRight: '16px',
							paddingLeft: '16px'
						},
						success: {
							duration: 3000
						}
					}}
				/>{' '}
				{memorizedRoutes}
				{memorizedConfirmationDialog}
				<ModalContainer></ModalContainer>
			</>
		);
};

export default App;
