import { useGlobalStore } from 'global-state/useStore';
import { isoDateWithoutTimeZone } from 'helper/helperFunctions';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import IconWithTooltip from '../../shared/Tooltip/IconWithTooltip';
import { Button } from '@mui/material';
import {
	useBaseCloudProcessingConfig,
	useRotateImg,
	useStartDisplacementProcessing
} from 'rq/hooks/cloudProcessingHook';
import { PROCESSING_STEPS } from 'pages/CloudProcessing/ProcessingSteps';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Rotation } from './types';
import { toast } from 'react-hot-toast';
import Processing from '../Processing/Processing';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const ImageRotation = () => {
	const { t } = useTranslation();
	const imgRef = useRef<HTMLImageElement>(null);

	const { originalReprocessingData } = useGlobalStore((state) => state.reprocessingMeasurement);
	const displayStep = useGlobalStore((state) => state.displayStep);
	const currentStep = useGlobalStore((state) => state.currentStep);
	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);
	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const displacementProcessing = useGlobalStore((state) => state.displacementProcessing);
	const setVideoBeingProcessed = useGlobalStore((state) => state.setVideoBeingProcessed);
	const videoBeingProcessed = useGlobalStore((state) => state.videoBeingProcessed);
	const cloudProcessing = useGlobalStore((state) => state.cloudProcessing);
	const setCloudProcessingRotatedImage = useGlobalStore(
		(state) => state.setCloudProcessingRotatedImage
	);

	const setInitialRotation = () => {
		let rotation = 0;

		if (originalReprocessingData)
			switch (originalReprocessingData?.measurement_orginal.screen_orientation) {
				case 0:
					rotation = 0;
					break;
				case 90:
					rotation = -1;
					break;
				case 180:
					rotation = 2;
					break;
				case 270:
					rotation = 1;
					break;
				default:
					rotation = 0;
			}
		return rotation as Rotation;
	};

	const [rotation, setRotation] = useState<Rotation>(setInitialRotation());

	const { data: config } = useBaseCloudProcessingConfig();
	const { mutate: rotateImageRequest } = useRotateImg();
	const { mutate: useStartDisplacementRequest } = useStartDisplacementProcessing();

	const rotate = (direction: 1 | -1) => {
		setRotation((prevRotation) => {
			let newRotation = prevRotation + direction;
			if (newRotation === 3) newRotation = -1;
			if (newRotation === -2) newRotation = 2;
			return newRotation as Rotation;
		});
	};

	const saveRotation = () => {
		if (currentStep.stepNumber >= displayStep.stepNumber) {
			setCurrentStep(displayStep);
		}

		rotateImageRequest(
			{
				processing_id: `${cloudProcessing.video?.processing_id}`,
				body: {
					rotation_value: rotation
				}
			},
			{
				onSuccess: (data) => {
					const image = new Image();
					const uniqueParam = new Date().getTime();
					image.src = `${data.image ?? ''}?${uniqueParam}`;

					image.onload = () => {
						setCloudProcessingRotatedImage({
							imgSrc: data.image,
							rotation: rotation,
							imgOrientation: image.width > image.height ? 'horizontal' : 'vertical',
							imgWidth: image.width,
							imgHeight: image.height
						});
					};
					if (image.complete) {
						setCloudProcessingRotatedImage({
							imgSrc: data.image,
							rotation: rotation,
							imgOrientation: image.width > image.height ? 'horizontal' : 'vertical',
							imgWidth: image.width,
							imgHeight: image.height
						});
					}
					const nextStep = PROCESSING_STEPS[displayStep.stepNumber + 1];
					nextStep && setCurrentStep(nextStep);
					nextStep && setDisplayStep(nextStep);
				},
				onError: (err: any) => {
					toast.error(err.message);
				}
			}
		);
	};

	const validationSchema = Yup.object().shape({
		created: Yup.string().required()
	});

	// formik is used only in displacement processing
	const formik = useFormik({
		initialValues: {
			created: ''
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			if (currentStep.stepNumber >= displayStep.stepNumber) {
				setCurrentStep(displayStep);
			}

			const configWithRotation = [...config];
			configWithRotation[1].rotate = rotation;
			rotateImageRequest(
				{
					processing_id: `${cloudProcessing.video?.processing_id}`,
					body: {
						rotation_value: rotation
					}
				},
				{
					onSuccess: (rotateData) => {
						setCloudProcessingRotatedImage({ imgSrc: rotateData.image, rotation: rotation });

						//request
						useStartDisplacementRequest(
							{
								processing_id: `${cloudProcessing.video?.processing_id}`,
								body: {
									parameters: {
										config: configWithRotation,
										created: isoDateWithoutTimeZone(data.created) ?? '',
										utc_offset: '+00:00'
									}
								}
							},
							{
								/*				onSuccess: () => {
									const nextStep =
										!!displayStep.nextDisplacementStep &&
										PROCESSING_STEPS[displayStep.nextDisplacementStep];

									!videoBeingProcessed && nextStep && setCurrentStep(nextStep);
									!videoBeingProcessed && nextStep && setDisplayStep(nextStep);
								}*/
							}
						);
						setVideoBeingProcessed(true);
					}
				}
			);
		}
	});

	return (
		<div className="mx-2  text-lg ">
			<div className="inline-flex flex-col">
				<div className="mb-4 text-black">{t('IMAGE_ROTATION')}</div>
				<div className="flex flex-col items-center text-black">
					<div className="flex w-full flex-row justify-around">
						<IconWithTooltip
							icon={<RotateLeftIcon onClick={rotate.bind(null, 1)} className="" fontSize="large" />}
							title={t('ROTATE_LEFT')}
						/>
						<IconWithTooltip
							icon={
								<RotateRightIcon onClick={rotate.bind(null, -1)} fontSize="large" className="" />
							}
							title={t('ROTATE_RIGHT')}
						/>
					</div>
					<div className="h-[30rem] max-h-[30rem] w-[30rem] max-w-[30rem] bg-black bg-opacity-100">
						<img
							ref={imgRef}
							style={{
								transform: `rotate(${rotation * -90}deg)`
							}}
							alt={t('VIDEO_FIRST_FRAME')}
							className="h-full max-h-full w-full max-w-full object-contain"
							src={`${cloudProcessing.video?.first_frame}`}
						/>
					</div>
				</div>
				{!displacementProcessing ? (
					<div className="mx-auto my-2">
						<Button
							className="block normal-case"
							variant="contained"
							color="info"
							onClick={saveRotation}>
							{t('NEXT')}
						</Button>
					</div>
				) : videoBeingProcessed ? (
					<div className={'mx-auto pb-2'}>
						<Processing></Processing>
					</div>
				) : (
					<div className="mx-auto mt-6  text-black">
						<div className="mb-2 font-bold">{t('DATE_AND_TIME_UTC')}:</div>
						<DateTimePicker
							disableFuture
							ampm={false}
							value={new Date(formik.values.created)}
							onChange={(value: Date | null) => formik.setFieldValue('created', value)}
						/>
						<Button
							className="mx-auto mt-6 block normal-case"
							variant="contained"
							color="info"
							onClick={formik.submitForm}
							disabled={!formik.isValid}>
							{t('PROCESS_VIDEO')}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default ImageRotation;
