import { Button, CircularProgress, MenuItem, Select, TextField } from '@mui/material';
import classNames from 'classnames';
import ConfigFineTune from 'components/CloudProcessing/StationConfigFineTune/ConfigFineTune';
import { useGlobalStore } from 'global-state/useStore';
import { useCanvasDrawing } from 'hooks/useCanvasDrawing';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useProcessVideo,
	useWaterLineFromCoords,
	useWaterLineFromWCol
} from 'rq/hooks/cloudProcessingHook';
import { BaseCloudProcessingConfig } from 'services/CloudProcessingService/types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PROCESSING_STEPS } from 'pages/CloudProcessing/ProcessingSteps';
import './styles.scss';
import Processing from '../Processing/Processing';
import { isoDateWithoutTimeZone } from 'helper/helperFunctions';
import { DateTimePicker } from '@mui/x-date-pickers';

const WaterLevel = () => {
	const { t } = useTranslation();
	const imgRef = useRef<HTMLImageElement>(null);
	const imageDivContainerRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const formContainerRef = useRef<HTMLDivElement>(null);
	const resultsContainerRef = useRef<HTMLDivElement>(null);
	const waterlineClicked = useRef<boolean>(false);
	const preventOnClick = useRef<boolean>(false);

	const displayStep = useGlobalStore((state) => state.displayStep);
	const currentStep = useGlobalStore((state) => state.currentStep);
	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);
	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const videoBeingProcessed = useGlobalStore((state) => state.videoBeingProcessed);
	const setVideoBeingProcessed = useGlobalStore((state) => state.setVideoBeingProcessed);

	const cloudProcessing = useGlobalStore((state) => state.cloudProcessing);
	const { reprocessing, originalReprocessingData } = useGlobalStore(
		(state) => state.reprocessingMeasurement
	);
	const setCPStivProcessing = useGlobalStore((state) => state.setCPStivProcessing);

	const [waterColumnClickPixel, setWaterColumnClickPixel] = useState<{ x: number; y: number }>();

	// using this value in the input component to display the value user currently is typing even when there is an
	// existing value from the response that should be in that field. Therefore, we need to clear this value on every response
	// that gives us w_col in order to display the responses w_col in the input field
	const [manualWaterColumnValue, setManualWaterColumnValue] = useState<string | null>(
		originalReprocessingData?.measurement_orginal.level
			? originalReprocessingData?.measurement_orginal.level.toString()
			: null
	);

	// using this state to set a value that is forwarded to the waterlinefromWCol request. This state also tells us whether
	// the latest response was from a manual entry (then this value will be a number) or from a imgClick (this value is then undefined)
	const [waterColManualRequestField, setWaterColManualRequestField] = useState<number | undefined>(
		Number(originalReprocessingData?.measurement_orginal.level)
	);

	//Get scaling coefficients for image in case that is rendered smaller than original image
	const [imgScale, setImgScale] = useState({
		imgScaleY: Number(cloudProcessing.rotatedImage?.scaledY),
		imgScaleX: Number(cloudProcessing.rotatedImage?.scaledX)
	});

	useEffect(() => {
		setImgScale({
			imgScaleY: Number(cloudProcessing.rotatedImage?.scaledY),
			imgScaleX: Number(cloudProcessing.rotatedImage?.scaledX)
		});
	}, [cloudProcessing.rotatedImage]);

	const { data: waterLineDataFromCoords } = useWaterLineFromCoords(
		{
			config: cloudProcessing.config as BaseCloudProcessingConfig,
			processing_id: `${cloudProcessing.video?.processing_id}`,
			j_click: Number(waterColumnClickPixel?.x),
			i_click: Number(waterColumnClickPixel?.y)
		},
		{
			enabled: !!waterColumnClickPixel
		}
	);
	const { data: waterLineDataFromWaterCol } = useWaterLineFromWCol(
		{
			config: cloudProcessing.config as BaseCloudProcessingConfig,
			processing_id: `${cloudProcessing.video?.processing_id}`,
			w_col: Number(waterColManualRequestField)
		},
		{
			enabled: !!waterColManualRequestField && Boolean(manualWaterColumnValue)
		}
	);
	const { mutate: processVideo, isLoading: startingProcessing } = useProcessVideo();

	const waterLineData = waterColManualRequestField
		? waterLineDataFromWaterCol
		: waterLineDataFromCoords;

	const validationSchema = Yup.object().shape({
		created: Yup.string().required(),

		w_col: Yup.number()
	});
	const formik = useFormik({
		initialValues: {
			created: reprocessing ? originalReprocessingData?.measurement_orginal.date_time ?? '' : '',
			w_col: waterLineData?.w_col as number,
			config: cloudProcessing.config as BaseCloudProcessingConfig,
			processingType: 'ssiv'
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			if (currentStep.stepNumber > displayStep.stepNumber) {
				setCurrentStep(displayStep);
			}
			setVideoBeingProcessed(true);
			setCPStivProcessing(data.processingType === 'stiv');
			processVideo(
				{
					body: {
						parameters: {
							config: data.config,
							created: isoDateWithoutTimeZone(data.created) ?? '',
							utc_offset: '+00:00',
							w_col: data.w_col,
							use_stiv_processing: data.processingType === 'stiv',
							is_direct_processing: cloudProcessing.configManuallyUploaded ?? false
						}
					},
					processing_id: `${cloudProcessing.video?.processing_id}`
				},
				{
					/*onSuccess: () => {
						const nextStep = PROCESSING_STEPS[displayStep.stepNumber + 1];
						if (nextStep) {
							if (currentStep.stepNumber <= displayStep.stepNumber) {
								setCurrentStep(nextStep);
							}
							setDisplayStep(nextStep);
						}
					},*/
				}
			);
		}
	});

	/*	const { data: siteData } = useSite({ site_id: Number(siteId) });*/

	const getRealImageCoordinates = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (waterlineClicked.current || !imgRef.current) return;

		const imgPosition = imgRef.current.getBoundingClientRect();
		return {
			x: event.clientX - imgPosition.x,
			y: event.clientY - imgPosition.y
		};
	};

	const getClickCoordinatesAndAddWaterLine = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		if (preventOnClick.current) {
			preventOnClick.current = false;
			return;
		}
		const realImageClickCoordinates = getRealImageCoordinates(event);

		setWaterColumnClickPixel(realImageClickCoordinates);
		setWaterColManualRequestField(undefined);
	};

	useEffect(() => {
		if (
			cloudProcessing.rotatedImage &&
			cloudProcessing.rotatedImage.scaledY &&
			cloudProcessing.rotatedImage.scaledX
		)
			setImgScale({
				imgScaleY: cloudProcessing.rotatedImage.scaledY,
				imgScaleX: cloudProcessing.rotatedImage.scaledX
			});
	}, []);

	useCanvasDrawing(imgRef, canvasRef, imgScale, waterLineData);
	useEffect(() => {
		formik.setFieldValue('w_col', waterLineData?.w_col);
	}, [waterLineData]);

	const mouseDownHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!imgRef.current || !waterLineData) return;
		const xCoord = Math.round((event.clientX - imgRef.current.x) * imgScale.imgScaleX);
		const yCoord = Math.round((event.clientY - imgRef.current.y) * imgScale.imgScaleY);

		if (Math.abs(yCoord - waterLineData.i[xCoord]) <= 10) {
			waterlineClicked.current = true;
		}
	};

	const mouseUpHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!waterlineClicked.current) {
			return;
		}
		preventOnClick.current = true;
		waterlineClicked.current = false;
		const imageCoords = getRealImageCoordinates(event);
		setWaterColumnClickPixel(imageCoords);
		setManualWaterColumnValue(null);
	};

	const moveWaterLine = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!waterlineClicked.current || !imgRef.current || !waterLineData || !canvasRef.current)
			return;

		const xCoord = event.clientX - imgRef.current.x;

		const clickOffset =
			event.clientY - imgRef.current.y - waterLineData.i[xCoord] / imgScale.imgScaleX;

		canvasRef.current.style.top = `${clickOffset}px`;
	};

	const waterColumnHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (isNaN(Number(event.target.value)) && event.target.value !== '') {
			return;
		}
		setManualWaterColumnValue(event.target.value !== '' ? event.target.value : '');
	};

	const [containerWidthClass, setContainerWidthClass] = useState('w-[25rem]');

	const onImageLoad = () => {
		if (imgRef.current && canvasRef.current) {
			if (imgRef.current.naturalWidth > imgRef.current.naturalHeight) {
				setContainerWidthClass('w-full max-w-full xl:w-[50rem]');
			} else {
				setContainerWidthClass('w-full max-w-full xs:w-[30rem] md:w-[25rem]');
			}
		}
	};

	const canvasWidthAndHeight = useMemo(() => {
		return {
			width:
				Number(cloudProcessing.rotatedImage?.imgWidth) /
				Number(cloudProcessing.rotatedImage?.scaledX),
			height:
				Number(cloudProcessing.rotatedImage?.imgHeight) /
				Number(cloudProcessing.rotatedImage?.scaledY)
		};
	}, [canvasRef]);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	};

	return (
		<div className="text-lg">
			<form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown} autoComplete="off">
				<div className="flex flex-col">
					<div className="w-full">
						<div className="mb-2 text-base font-bold text-black">{t('CLICK_TO_ADD_WATERLINE')}</div>
						<div
							ref={formContainerRef}
							className={classNames({ 'flex flex-row flex-wrap gap-4': true })}>
							<div
								onMouseDown={(event) => mouseDownHandler(event)}
								onMouseMove={(event) => {
									moveWaterLine(event);
								}}
								onMouseUp={(event) => mouseUpHandler(event)}
								className="relative  mr-4 max-w-full cursor-pointer overflow-x-auto overflow-y-hidden"
								onClick={(event) => getClickCoordinatesAndAddWaterLine(event)}
								ref={imageDivContainerRef}>
								<div className="relative">
									<img
										id={'measImg'}
										ref={imgRef}
										height={canvasWidthAndHeight.height}
										width={canvasWidthAndHeight.width}
										onLoad={() => {
											if (!imgRef.current) {
												return;
											}

											onImageLoad();
										}}
										src={`${cloudProcessing.rotatedImage?.imgSrc}?${cloudProcessing.rotatedImage?.rotation}`}
										className={`rotated-first-frame  max-w-[none] select-none ${containerWidthClass}`}
										alt={t('MEASUREMENT_IMAGE')}
									/>
									<canvas
										height={canvasWidthAndHeight.height}
										width={canvasWidthAndHeight.width}
										className={`absolute left-0 top-0 h-full ${containerWidthClass} `}
										ref={canvasRef}
									/>
								</div>
							</div>
							{!videoBeingProcessed ? (
								<div ref={resultsContainerRef} className="flex flex-auto flex-col gap-4 ">
									<div className={'flex grow flex-col gap-4'}>
										<div className={'flex items-center gap-2 align-middle'}>
											<div className={'w-40'}>{t('WATER_COLUMN')}:</div>
											<div className="flex items-end gap-1 text-black">
												<TextField
													className="  inline-block w-24"
													size="small"
													value={
														manualWaterColumnValue || manualWaterColumnValue === ''
															? manualWaterColumnValue
															: waterLineData?.w_col ?? ''
													}
													onChange={waterColumnHandler}
												/>
												<span>m</span>
											</div>
											{manualWaterColumnValue && (
												<Button
													onClick={() =>
														setWaterColManualRequestField(Number(manualWaterColumnValue))
													}
													variant="contained"
													color="info"
													size="small">
													{t('REDRAW')}
												</Button>
											)}
										</div>
										<div className={'flex items-center gap-2 align-middle'}>
											<div className={'w-40'}>{t('CREATED_AT_UTC')}:</div>
											<DateTimePicker
												disableFuture
												disabled={reprocessing}
												ampm={false}
												value={new Date(formik.values.created)}
												onChange={(value: Date | null) => formik.setFieldValue('created', value)}
											/>
										</div>
										<div className={'flex flex-row items-center gap-2 text-black'}>
											<div className="  w-40">{t('PROCESSING_TYPE')}</div>
											<Select
												onChange={formik.handleChange}
												name={'processingType'}
												value={formik.values.processingType}>
												<MenuItem value={'ssiv'}>{t('SSIV')}</MenuItem>
												<MenuItem value={'stiv'}>{t('STIV')}</MenuItem>
											</Select>{' '}
										</div>
										<div className={'flex flex-col'}>
											<div>
												<Button
													className="mr-4 normal-case"
													variant="contained"
													color="error"
													onClick={() => {
														setDisplayStep(
															PROCESSING_STEPS[displayStep.stepNumber - 1] ?? displayStep
														);
													}}>
													{t('BACK')}
												</Button>
												{!startingProcessing ? (
													<Button
														className="ml-4 normal-case"
														disabled={
															!waterLineData ||
															waterLineData.w_col === undefined ||
															waterLineData.w_col === null ||
															!formik.isValid
														}
														variant="contained"
														color="info"
														type="submit">
														{t('PROCESS_VIDEO')}
													</Button>
												) : (
													<div className="ml-4 flex w-32 flex-row items-center justify-center">
														<CircularProgress className="h-6 w-6" />
													</div>
												)}
											</div>
										</div>
									</div>
									<div>
										<div className={'flex w-full flex-1 flex-wrap self-start'}>
											<ConfigFineTune></ConfigFineTune>
										</div>
									</div>
								</div>
							) : (
								<Processing />
							)}
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default WaterLevel;
