import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import DisplacementResults from './DisplacementResults';
import NormalProcessingResults from './NormalProcessingResults';

const Results = () => {
	const displacementProcessing = useGlobalStore((state) => state.displacementProcessing);
	return displacementProcessing ? <DisplacementResults /> : <NormalProcessingResults />;
};

export default Results;
