import CircularProgress from '@mui/material/CircularProgress';
import { TabPanel } from 'components/shared/TabPanel/TabPanel';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useAuth } from 'rq/hooks/authHook';
import { useGetUserDetails } from 'rq/hooks/userHook';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProfileInfoCard from './ProfileInfoCard';
import ProfileOrganizationCard from './ProfileOrganizationCard';
import ProfileAdvancedCard from './ProfileAdvancedCard';
import PersonIcon from '@mui/icons-material/Person';
import ProfileAvatarModal from 'components/Modal/Profile/ProfileAvatarModal';

const ProfilePage = () => {
	const { t } = useTranslation();
	const [applicationToken, setApplicationToken] = useState<string | undefined>();
	const { userId } = useParams();
	const { data: user, isLoading: userLoading } = useGetUserDetails(String(userId));
	const { data: me, isLoading: profileLoading } = useAuth();
	const [tabValue, setTabValue] = useState<number>(0);
	const [avatarHover, setAvatarHover] = useState<boolean>(false);
	const [profileAvatarModalOpen, setProfileAvatarModalOpen] = useState<boolean>(false);
	const allowedToEditAndUpdate = me?.id === user?.id;
	const openProfileAvatarModal = () => {
		allowedToEditAndUpdate && setProfileAvatarModalOpen(true);
	};

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	if (userLoading || profileLoading) {
		return (
			<div className={'flex h-full flex-col items-center justify-center gap-2 align-middle'}>
				<CircularProgress size={75} thickness={5} />
			</div>
		);
	}

	return (
		<div className="flex h-full flex-col items-center justify-items-center">
			<div className="mx-auto flex w-11/12 max-w-[95%] flex-col items-center">
				<div className="mt-6 flex w-full max-w-3xl flex-row px-4 md:mt-12 md:px-20">
					<div
						onMouseEnter={() => {
							setAvatarHover(true);
						}}
						onMouseLeave={() => {
							setAvatarHover(false);
						}}
						onClick={openProfileAvatarModal}
						className="relative h-16 w-16  overflow-hidden rounded-full md:h-32 md:w-32">
						<Avatar
							className={
								(avatarHover && allowedToEditAndUpdate ? ' cursor-pointer opacity-40 ' : '') +
								'h-16 w-16 md:h-32 md:w-32'
							}
							alt="user"
							src={user?.image}
							// sx={{ width: 128, height: 128 }}
						/>
						{avatarHover && allowedToEditAndUpdate && (
							<div
								className={
									'absolute top-3/4 left-1/2 h-[40%] w-full -translate-x-1/2 transform cursor-pointer bg-gray-200 text-center text-secondary'
								}>
								{t('CHANGE')}
							</div>
						)}
					</div>
					<div className="ml-4 flex flex-col items-center justify-center md:ml-8 ">
						<div className="mb-2 text-xl">{user?.username}</div>
						<div className="mt-2 flex flex-row">
							<PersonIcon className="h-5 w-5 text-secondary" />
							<div className="ml-2 text-secondary">
								{user?.super_admin ? t('YOU_ARE_SUPER_ADMIN') : t('USER')}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mx-auto mt-4 flex w-11/12 max-w-[95%] flex-col items-center md:mt-12">
				<Tabs
					className="w-full max-w-3xl"
					variant="scrollable"
					allowScrollButtonsMobile
					value={tabValue}
					onChange={handleTabChange}>
					<Tab
						className="border border-solid border-[#bfbfbf] border-opacity-100 bg-white hover:bg-[#bfbfbf]"
						label={t('INFO')}
					/>

					{allowedToEditAndUpdate && (
						<Tab
							className="border border-solid border-[#bfbfbf] border-opacity-100 bg-white hover:bg-[#bfbfbf]"
							label={t('ORGANIZATION')}
						/>
					)}
					{allowedToEditAndUpdate && (
						<Tab
							className="border border-solid border-[#bfbfbf] border-opacity-100 bg-white hover:bg-[#bfbfbf]"
							label={t('ADVANCED')}
						/>
					)}
				</Tabs>
				<div className="w-full max-w-3xl  border border-solid border-[#bfbfbf] bg-white">
					<TabPanel value={tabValue} index={0}>
						<div className="p-4 pt-8">
							<ProfileInfoCard user={user} />
						</div>
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<div className="p-4 pt-8">
							<ProfileOrganizationCard user={user} />
						</div>
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						<div className="p-4 pt-8">
							<ProfileAdvancedCard
								applicationToken={applicationToken}
								setApplicationToken={setApplicationToken}
							/>
						</div>
					</TabPanel>
				</div>
			</div>
			{profileAvatarModalOpen && (
				<ProfileAvatarModal
					open={profileAvatarModalOpen}
					setProfileAvatarModalOpen={setProfileAvatarModalOpen}
				/>
			)}
		</div>
	);
};

export default ProfilePage;
