import { CancelOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const ImgMarker = ({
	marker,
	imgRef,
	imageDivContainerRef,
	changeMarkerCoords,
	removeMarker
}: {
	marker: {
		id: number;
		clientImgX: number;
		clientImgY: number;
	};
	imgRef: React.RefObject<HTMLImageElement>;
	imageDivContainerRef: React.RefObject<HTMLDivElement>;
	changeMarkerCoords: (markerId: number, clientImgX: number, clientImgY: number) => void;
	removeMarker: (markerId: number) => void;
}) => {
	const [hover, setHover] = useState(false);
	const cloudProcessing = useGlobalStore((state) => state.cloudProcessing);

	useEffect(() => {
		if (!imageDivContainerRef.current) return;
		const mouseMove = (event: MouseEvent) => {
			if (!markerClickOffset.current) return;
			moveMarker(event);
		};

		imageDivContainerRef.current.addEventListener('mousemove', mouseMove);

		return () => {
			imageDivContainerRef.current?.removeEventListener('mousemove', mouseMove);
		};
	}, []);
	const markerXcoord = useMemo<number>(
		() => marker.clientImgX,
		[marker.clientImgX, cloudProcessing.rotatedImage?.scaledX]
	);
	const markerYcoord = useMemo<number>(
		() => marker.clientImgY,
		[marker.clientImgY, cloudProcessing.rotatedImage?.scaledY]
	);

	const markerRef = useRef<HTMLDivElement>(null);
	const [markerClicked, setMarkerClicked] = useState<boolean>(false);
	const markerClickOffset = useRef<{ x: number; y: number } | null>(null);

	const moveMarker = (event: MouseEvent) => {
		if (!markerRef.current || !imgRef.current || !markerClickOffset.current) return;
		const newMarkerTopPosition = event.clientY - imgRef.current.y - markerClickOffset.current.y;
		const newMarkerLeftPosition = event.clientX - imgRef.current.x - markerClickOffset.current.x;
		markerRef.current.style.top = `${
			newMarkerTopPosition >= 0
				? newMarkerTopPosition <= imgRef.current.height
					? newMarkerTopPosition
					: imgRef.current.height
				: 0
		}px`;
		markerRef.current.style.left = `${
			newMarkerLeftPosition >= 0
				? newMarkerLeftPosition <= imgRef.current.width
					? newMarkerLeftPosition
					: imgRef.current.width
				: 0
		}px`;
	};

	const markerMouseEnter = () => {
		!markerClicked && setHover(true);
	};

	const markerMouseLeave = () => {
		!markerClickOffset.current && setHover(false);
	};

	return (
		<div
			ref={markerRef}
			onMouseEnter={markerMouseEnter}
			onMouseLeave={markerMouseLeave}
			onMouseDown={(event) => {
				markerClickOffset.current = {
					x: event.clientX - Number(imgRef.current?.x) - Number(markerRef.current?.offsetLeft),
					y: event.clientY - Number(imgRef.current?.y) - Number(markerRef.current?.offsetTop)
				};
				setMarkerClicked(true);
			}}
			onMouseUp={() => {
				markerClickOffset.current = null;
				setMarkerClicked(false);
				changeMarkerCoords(
					marker.id,
					Number(markerRef.current?.offsetLeft),
					Number(markerRef.current?.offsetTop)
				);
			}}
			style={{
				position: 'absolute',
				top: markerYcoord,
				left: markerXcoord
			}}
			className={classNames(
				{
					'flex h-12 w-12 cursor-move select-none items-center justify-center rounded-full bg-blue-400 bg-opacity-60 text-black text-opacity-90':
						true
				},
				{ '-translate-x-1/2 -translate-y-1/2 transform': true },
				{ 'bg-opacity-20': hover }
			)}>
			<div className="relative h-full w-full">
				<div className="absolute top-1/2 left-0 h-0.5 w-[40%] -translate-y-1/2 transform bg-white bg-opacity-90"></div>
				<div className="absolute top-1/2 right-0 h-0.5 w-[40%] -translate-y-1/2 transform bg-white"></div>
				<div className="absolute left-1/2 top-0 h-[40%] w-0.5 -translate-x-1/2 transform bg-white"></div>
				<div className="absolute left-1/2 bottom-0 h-[40%] w-0.5 -translate-x-1/2 transform bg-white"></div>
				{hover && (
					<div className="absolute left-1/2 top-1/2 h-0.5 w-0.5 -translate-y-1/2 -translate-x-1/2 transform bg-white"></div>
				)}
				{hover && !markerClicked && (
					<div
						onMouseDown={(event) => event.stopPropagation()}
						className="absolute left-full top-0 -translate-x-[10px] -translate-y-1/2 transform cursor-pointer">
						<CancelOutlined
							onClick={(event) => {
								event.stopPropagation();
								removeMarker(marker.id);
							}}
							fontSize="inherit"
							className="h-7 w-7"
						/>
					</div>
				)}
				<div
					className={classNames({
						'z-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xl font-bold':
							true,
						hidden: hover
					})}>
					{marker.id}
				</div>
			</div>
		</div>
	);
};

export default ImgMarker;
