import { DischargeStationCurve } from 'interfaces/models/DischargeStationCurve';
import { StationMeasurementsModel } from 'interfaces/models/StationMeasurementsModel';

export type MinMaxDataType = {
	maxLevel: number;
	minLevel: number;
	maxDischarge: number;
	minDischarge: number;
	minMaxLevelDifference: number;
	minMaxDischargeDifference: number;
	maxLevelWithMargin: number;
	minLevelWithMargin: number;
	maxDischargeWithMargin: number;
	minDischargeWithMargin: number;
	lowestDischarge: number;
	highestDischarge: number;
};
export const getMeasurementsSortedByProperty = (
	siteMeasurements: StationMeasurementsModel[],
	propName: string
) => {
	const compare = (a: StationMeasurementsModel, b: StationMeasurementsModel) => {
		if (
			a[propName as keyof StationMeasurementsModel] < b[propName as keyof StationMeasurementsModel]
		) {
			return -1;
		}
		if (
			a[propName as keyof StationMeasurementsModel] > b[propName as keyof StationMeasurementsModel]
		) {
			return 1;
		}
		return 0;
	};

	return siteMeasurements.sort(compare);
};
const groupQHPairs = (parameters: number[]) => {
	const curve: [number[]] = [[]];
	let x: number, y: number;
	parameters.forEach((parameter: number, index: number) => {
		if (index % 2 === 0) {
			x = parameter;
		} else {
			y = parameter;
			curve.push([x, y]);
		}
	});

	return curve;
};

const calculateQuadraticCurve = (
	parameters: number[],
	measurements: StationMeasurementsModel[]
) => {
	const a = parameters[0] || 0,
		b = parameters[1] || 0,
		c = parameters[2] || 0,
		curve = [],
		maxLevel = getMaxLevel(measurements);

	let x,
		y,
		i = 0;

	const ratingCurveLimit = maxLevel * 1.2,
		step = ratingCurveLimit / 100;

	for (i; i < ratingCurveLimit; i = i + step) {
		x = i;

		y = a * (x * x) + b * x + c;

		if (x > -b / (2 * a)) {
			curve.push([parseFloat(y.toFixed(6)), parseFloat(x.toFixed(6))]);
		}
	}

	return curve;
};

const calculateCubicCurve = (parameters: number[], measurements: StationMeasurementsModel[]) => {
	const a = parameters[0] || 0,
		b = parameters[1] || 0,
		c = parameters[2] || 0,
		d = parameters[3] || 0,
		curve = [],
		maxLevel = getMaxLevel(measurements);

	let x,
		y,
		i = 0;

	const ratingCurveLimit = maxLevel * 1.2,
		step = ratingCurveLimit / 100;

	for (i; i < ratingCurveLimit; i = i + step) {
		x = i;

		y = a * (x * x * x) + b * (x * x) + c * x + d;

		curve.push([parseFloat(y.toFixed(6)), parseFloat(x.toFixed(6))]);
	}

	return curve;
};

const calculateExponentialCurve = (parameters: number[], minMaxData: MinMaxDataType) => {
	const a = parameters[0] || 0,
		b = parameters[1] || 0,
		c = parameters[2] || 0,
		curve = [];

	let x,
		y,
		i = minMaxData.minLevelWithMargin;

	const step = parseFloat(
		((minMaxData.maxLevelWithMargin - minMaxData.minLevelWithMargin) / 100).toFixed(6)
	);

	for (i; i < minMaxData.maxLevelWithMargin; i += step) {
		x = i;
		y = a * Math.pow(x + b, c);

		if (i > -b) {
			curve.push([parseFloat(y.toFixed(6)), parseFloat(x.toFixed(6))]);
		}
	}

	return curve;
};

export const calculateCurveData = (
	curve: DischargeStationCurve,
	measurements: StationMeasurementsModel[],
	minMaxDataProps: MinMaxDataType
) => {
	const minMaxData = minMaxDataProps;
	let ratingCurveData;

	if (curve.type) {
		switch (curve.type.toUpperCase()) {
			case 'QUADRATIC':
				ratingCurveData = calculateQuadraticCurve(curve.parameters, measurements);
				break;
			case 'CUBIC':
				ratingCurveData = calculateCubicCurve(curve.parameters, measurements);
				break;
			case 'QH_PAIRS':
				ratingCurveData = groupQHPairs(curve.parameters);
				break;
			case 'EXPONENTIAL':
				ratingCurveData = calculateExponentialCurve(curve.parameters, minMaxData);
				break;
		}
	}

	return ratingCurveData;
};

const getMaxLevel = (measurements: StationMeasurementsModel[]) => {
	const defaultMaxLevel = 4;
	let maxLevel = 0;

	if (!measurements || !measurements.length) {
		return defaultMaxLevel;
	}

	measurements.forEach((measurement) => {
		if (measurement.level && measurement.discharge && measurement.level > maxLevel) {
			maxLevel = measurement.level;
		}
	});

	return maxLevel || defaultMaxLevel;
};

export const getMinLevel = (measurements: StationMeasurementsModel[]) => {
	const defaultMinLevel = 0;
	let minLevel = getMaxLevel(measurements);

	if (!measurements || !measurements.length) {
		return defaultMinLevel;
	}

	measurements.forEach((measurement) => {
		if (measurement.level && measurement.discharge && measurement.level < minLevel) {
			minLevel = measurement.level;
		}
	});

	// when measurements are truthy but all discharge values are null
	if (minLevel === getMaxLevel(measurements)) {
		minLevel = defaultMinLevel;
	}

	return minLevel;
};

function getMaxDischarge(measurements: StationMeasurementsModel[]) {
	const defaultMaxDischarge = 4;
	let maxDischarge = 0;

	if (!measurements || !measurements.length) {
		return defaultMaxDischarge;
	}

	measurements.forEach((measurement) => {
		if (measurement.level && measurement.discharge && measurement.discharge > maxDischarge) {
			maxDischarge = measurement.discharge;
		}
	});

	return maxDischarge || defaultMaxDischarge;
}

function getMinDischarge(measurements: StationMeasurementsModel[]) {
	const defaultMinDischarge = 0;
	let minDischarge = getMaxDischarge(measurements);

	if (!measurements || !measurements.length) {
		return defaultMinDischarge;
	}

	measurements.forEach((measurement) => {
		if (measurement.level && measurement.discharge && measurement.discharge < minDischarge) {
			minDischarge = measurement.discharge;
		}
	});

	// when measurements are truthy but all discharge values are null
	if (minDischarge === getMaxDischarge(measurements)) {
		minDischarge = defaultMinDischarge;
	}

	return minDischarge;
}

export const calculateMinMaxData = (measurements: StationMeasurementsModel[]): MinMaxDataType => {
	//chartMargin is used in old app, i don't see need here
	/*	const chartMargin = 1.1;*/
	const maxLevel = getMaxLevel(measurements);
	const minLevel = getMinLevel(measurements);
	const maxDischarge = getMaxDischarge(measurements);
	const minDischarge = getMinDischarge(measurements);

	const minMaxLevelDifference = maxLevel - minLevel;
	const minMaxDischargeDifference = maxDischarge - minDischarge;

	const maxLevelWithMargin = maxLevel + minMaxLevelDifference;
	const minLevelWithMargin = minLevel - minMaxLevelDifference;
	const maxDischargeWithMargin = maxDischarge + minMaxDischargeDifference;
	const minDischargeWithMargin = minDischarge - minMaxDischargeDifference;

	let lowestDischarge = 0;
	let highestDischarge = 0;

	if (measurements && measurements.length) {
		const siteMeasurementsSortedByDischarge = getMeasurementsSortedByProperty(
			measurements,
			'discharge'
		);

		lowestDischarge = siteMeasurementsSortedByDischarge[0].discharge;
		highestDischarge =
			siteMeasurementsSortedByDischarge[siteMeasurementsSortedByDischarge.length - 1].discharge;
	}

	return {
		maxLevel,
		minLevel,
		maxDischarge,
		minDischarge,
		minMaxLevelDifference,
		minMaxDischargeDifference,
		maxLevelWithMargin,
		minLevelWithMargin,
		maxDischargeWithMargin,
		minDischargeWithMargin,
		lowestDischarge,
		highestDischarge
	};
};

export const createMeasurementData = (measurements: StationMeasurementsModel[]) => {
	const dischargeMeasurementsApproved: { x: number; y: number }[] = [];
	const dischargeMeasurementsPending: { x: number; y: number }[] = [];

	measurements.forEach((measurement) => {
		if (measurement.discharge && measurement.level) {
			if (!measurement.validation_status || measurement.validation_status === 'APPROVED') {
				dischargeMeasurementsApproved.push({ x: measurement.discharge, y: measurement.level });
			} else if (measurement.validation_status === 'PENDING') {
				dischargeMeasurementsPending.push({ x: measurement.discharge, y: measurement.level });
			}
		}
	});

	return { dischargeMeasurementsApproved, dischargeMeasurementsPending };
};
